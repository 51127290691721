<template>
    <svg
        v-if="isSolid"
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.60002 4.80003C8.32698 4.80003 7.10609 5.30574 6.20591 6.20592C5.30574 7.10609 4.80002 8.32699 4.80002 9.60003C4.80002 10.8731 5.30574 12.094 6.20591 12.9941C7.10609 13.8943 8.32698 14.4 9.60002 14.4C10.8731 14.4 12.094 13.8943 12.9941 12.9941C13.8943 12.094 14.4 10.8731 14.4 9.60003C14.4 8.32699 13.8943 7.10609 12.9941 6.20592C12.094 5.30574 10.8731 4.80003 9.60002 4.80003ZM2.40002 9.60003C2.39988 8.46687 2.6672 7.34968 3.18023 6.33932C3.69326 5.32895 4.43753 4.45395 5.3525 3.78546C6.26747 3.11697 7.3273 2.67388 8.4458 2.49223C9.5643 2.31057 10.7099 2.39548 11.7894 2.74005C12.8689 3.08462 13.8518 3.67911 14.6582 4.47518C15.4646 5.27126 16.0718 6.24643 16.4302 7.32139C16.7887 8.39635 16.8884 9.54074 16.7212 10.6615C16.554 11.7822 16.1246 12.8477 15.468 13.7712L21.2484 19.5516C21.467 19.778 21.588 20.0811 21.5852 20.3957C21.5825 20.7103 21.4563 21.0113 21.2338 21.2338C21.0113 21.4563 20.7103 21.5825 20.3957 21.5852C20.0811 21.588 19.7779 21.467 19.5516 21.2484L13.7724 15.4692C12.6953 16.2352 11.428 16.6898 10.1096 16.7834C8.79123 16.8769 7.47251 16.6058 6.29798 15.9996C5.12346 15.3934 4.13845 14.4757 3.45089 13.3469C2.76334 12.218 2.39977 10.9218 2.40002 9.60003Z"
        />
    </svg>
    <svg
        v-else
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 4C8.4087 4 6.88258 4.63214 5.75736 5.75736C4.63214 6.88258 4 8.4087 4 10C4 10.7879 4.15519 11.5681 4.45672 12.2961C4.75825 13.0241 5.20021 13.6855 5.75736 14.2426C6.31451 14.7998 6.97595 15.2417 7.7039 15.5433C8.43185 15.8448 9.21207 16 10 16C10.7879 16 11.5681 15.8448 12.2961 15.5433C13.0241 15.2417 13.6855 14.7998 14.2426 14.2426C14.7998 13.6855 15.2417 13.0241 15.5433 12.2961C15.8448 11.5681 16 10.7879 16 10C16 8.4087 15.3679 6.88258 14.2426 5.75736C13.1174 4.63214 11.5913 4 10 4ZM4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 11.0506 17.7931 12.0909 17.391 13.0615C17.1172 13.7226 16.7565 14.3425 16.3196 14.9054L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L14.9054 16.3196C14.3425 16.7565 13.7226 17.1172 13.0615 17.391C12.0909 17.7931 11.0506 18 10 18C8.94943 18 7.90914 17.7931 6.93853 17.391C5.96793 16.989 5.08601 16.3997 4.34315 15.6569C3.60028 14.914 3.011 14.0321 2.60896 13.0615C2.20693 12.0909 2 11.0506 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconSearch",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
