<template>
    <svg
        v-if="isSolid"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.95169 11.6484C3.72672 11.4234 3.60034 11.1182 3.60034 10.8C3.60034 10.4818 3.72672 10.1766 3.95169 9.95161L11.1517 2.75161C11.3767 2.52665 11.6819 2.40027 12.0001 2.40027C12.3183 2.40027 12.6235 2.52665 12.8485 2.75161L20.0485 9.95161C20.2671 10.1779 20.388 10.4811 20.3853 10.7957C20.3826 11.1103 20.2564 11.4113 20.0339 11.6338C19.8114 11.8563 19.5104 11.9825 19.1958 11.9852C18.8811 11.988 18.578 11.867 18.3517 11.6484L13.2001 6.49681V20.4C13.2001 20.7183 13.0737 21.0235 12.8486 21.2485C12.6236 21.4736 12.3183 21.6 12.0001 21.6C11.6818 21.6 11.3766 21.4736 11.1516 21.2485C10.9265 21.0235 10.8001 20.7183 10.8001 20.4V6.49681L5.64849 11.6484C5.42345 11.8734 5.11828 11.9998 4.80009 11.9998C4.48189 11.9998 4.17672 11.8734 3.95169 11.6484Z"
        />
    </svg>

    <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L19.7071 9.29289C20.0976 9.68342 20.0976 10.3166 19.7071 10.7071C19.3166 11.0976 18.6834 11.0976 18.2929 10.7071L13 5.41421V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V5.41421L5.70711 10.7071C5.31658 11.0976 4.68342 11.0976 4.29289 10.7071C3.90237 10.3166 3.90237 9.68342 4.29289 9.29289L11.2929 2.29289Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconArrowUp",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
