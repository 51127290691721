import type { Feedback } from '#cmui/types'
import { FilterFeedbackType } from '~/interfaces/feedback'
import type { FiltersEngagement } from '~/interfaces/filters'
import type { PeriodObjectString } from '~/interfaces/newDashboard'
import { hasFilter } from '~/store/utils/filtersUtils'

export default (
    currentFeedback: Ref<Feedback | undefined>,
    loadingEngagements: Ref<boolean>,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    fetchFeedbacks: Function
) => {
    const cachedFeedbacks = ref<Feedback[]>([])
    const initialCachedFeedbacks = ref<Feedback[]>([])
    const cachedFilters = ref<FiltersEngagement>()
    const cacheTotalCount = ref(0)
    const cacheCurrentPeriod = ref<PeriodObjectString>()
    const currentFeedbackIndex = ref(0)
    const minIndexOffset = ref(0)

    // This is a helper function to calculate the offset of the current feedback
    // It'll use filters that can be affected by user actions to be able to calculate a accurate offset for the backend request
    const calculateOffset = () => {
        const filters = cachedFilters.value

        if (cachedFeedbacks.value.length === 0 || !filters) {
            return
        }

        let offsetRequest = 0

        cachedFeedbacks.value.forEach((feedback) => {
            const originalFeedbackValue = initialCachedFeedbacks.value.find(
                (initialFeedback) => initialFeedback.id === feedback.id
            )

            if (!originalFeedbackValue) return

            const hasUnreadFilter = hasFilter(
                filters.engagementFilters,
                FilterFeedbackType.UNREAD
            )

            const hasArchivedFilter = hasFilter(
                filters.engagementFilters,
                FilterFeedbackType.ARCHIVED
            )

            if (hasArchivedFilter || hasUnreadFilter) {
                if (originalFeedbackValue.isArchived !== feedback.isArchived) {
                    // If the feedback got archived the user has the archived filter enabled
                    if (hasArchivedFilter && !hasUnreadFilter) {
                        offsetRequest = feedback.isArchived
                            ? offsetRequest + 1
                            : offsetRequest - 1
                    }

                    // If the feedback got archived and the user has the unread filter enabled
                    if (!hasArchivedFilter && hasUnreadFilter) {
                        offsetRequest = feedback.isArchived
                            ? offsetRequest - 1
                            : offsetRequest + 1
                    }
                }
            }

            if (
                hasFilter(
                    filters.engagementFilters,
                    FilterFeedbackType.ANSWERED
                )
            ) {
                // this is purely future proof as we cannot delete responses yet
                // if the response changes status
                if (
                    (!originalFeedbackValue.partnerResponse &&
                        feedback.partnerResponse) ||
                    (originalFeedbackValue.partnerResponse &&
                        !feedback.partnerResponse)
                ) {
                    offsetRequest = feedback.partnerResponse
                        ? offsetRequest + 1
                        : offsetRequest - 1
                }
            }
        })

        let offset = minIndexOffset.value

        if (currentFeedbackIndex.value < minIndexOffset.value) {
            offset -= filters.pages.perPage
        } else {
            offset += cachedFeedbacks.value.length + offsetRequest
        }

        return Math.max(offset, 0)
    }

    const handleIndexChange = (index: number) => {
        if (
            index < cachedFeedbacks.value.length + minIndexOffset.value &&
            index >= minIndexOffset.value
        ) {
            // If the feedback is already cached we can just set it
            currentFeedback.value =
                cachedFeedbacks.value[index - minIndexOffset.value]
        } else if (cachedFilters.value) {
            // If the feedback is not cached we need to fetch the new feedbacks
            loadingEngagements.value = true
            fetchFeedbacks(cachedFilters.value, false, calculateOffset())
                .then(() => {
                    currentFeedback.value =
                        cachedFeedbacks.value[index - minIndexOffset.value]
                })
                .finally(() => {
                    loadingEngagements.value = false
                })
        }
    }

    watch(currentFeedbackIndex, (index) => {
        handleIndexChange(index)
    })

    return {
        cachedFeedbacks,
        handleIndexChange,
        calculateOffset,
        initialCachedFeedbacks,
        cachedFilters,
        cacheTotalCount,
        cacheCurrentPeriod,
        currentFeedbackIndex,
        minIndexOffset
    }
}
