<template>
    <svg
        v-if="isSolid"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        class="fill-current"
    >
        <path
            d="M21.75 7.125C20.9216 7.125 20.25 7.79657 20.25 8.625V13.875H1.125V19.5C1.125 20.3284 1.79657 21 2.625 21C3.45343 21 4.125 20.3284 4.125 19.5V16.875H20.25V19.5C20.25 20.3284 20.9216 21 21.75 21C22.5784 21 23.25 20.3284 23.25 19.5V8.625C23.25 7.79657 22.5784 7.125 21.75 7.125Z"
        />
        <path
            d="M13.875 13.125H1.1404C1.33096 10.8152 3.266 9 5.625 9H13.875V13.125Z"
        />
        <path
            d="M19.5 11.0625C19.5 12.4087 18.4087 13.5 17.0625 13.5C15.7163 13.5 14.625 12.4087 14.625 11.0625C14.625 9.71631 15.7163 8.625 17.0625 8.625C18.4087 8.625 19.5 9.71631 19.5 11.0625Z"
        />
    </svg>
    <svg
        v-else
        viewBox="0 0 20 20"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19 0C19.5523 0 20 0.447715 20 1V13C20 13.5523 19.5523 14 19 14C18.4477 14 18 13.5523 18 13V10H2V13C2 13.5523 1.55228 14 1 14C0.447715 14 0 13.5523 0 13V9C0 6.23855 2.23869 4 5.00009 4H11.5V8H18V1C18 0.447715 18.4477 0 19 0ZM9.5 8V6H5.00009C3.69385 6 2.58257 6.83482 2.17072 8H9.5Z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.7461 6C15.2984 6 15.7461 5.55228 15.7461 5C15.7461 4.44772 15.2984 4 14.7461 4C14.1938 4 13.7461 4.44772 13.7461 5C13.7461 5.55228 14.1938 6 14.7461 6ZM14.7461 8C16.4029 8 17.7461 6.65685 17.7461 5C17.7461 3.34315 16.4029 2 14.7461 2C13.0892 2 11.7461 3.34315 11.7461 5C11.7461 6.65685 13.0892 8 14.7461 8Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconLodging",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
