<template>
    <nuxt-link
        :to="link"
        class="flex justify-center items-center chargemap-logo"
    >
        <svg
            width="190"
            height="44"
            viewBox="0 0 190 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M65.1419 17.3964C65.1419 19.845 62.7881 21.0692 58.0807 21.0692C53.3732 21.0692 51.0179 19.845 51.0146 17.3964V3.83318C51.0146 1.38466 53.37 0.1604 58.0807 0.1604C62.7913 0.1604 65.1451 1.38466 65.1419 3.83318V6.08408H60.9051V5.27434C60.9051 4.14648 59.9636 3.58255 58.0807 3.58255C56.1977 3.58255 55.2562 4.14648 55.2562 5.27434V16.2348C55.2562 17.1988 56.1961 17.6471 58.0807 17.6471C59.9652 17.6471 60.9051 17.1651 60.9051 16.2348V15.1311H65.1419V17.3964Z"
                fill="white"
            />
            <path
                d="M66.9832 0.425415H71.2199V6.74915C72.4441 5.65503 72.8105 5.78517 75.1096 5.78517C78.2859 5.78517 79.9825 7.02389 79.9825 9.47241V20.7751H75.4614V10.6051C75.4614 9.64111 74.8011 9.19285 73.4804 9.19285C71.9718 9.19285 71.2199 9.67484 71.2199 10.6051V20.7751H66.9832V0.425415Z"
                fill="white"
            />
            <path
                d="M88.2486 5.80969C84.5517 5.80969 82.4165 6.94719 82.2622 9.21738L86.499 10.432C86.5857 9.61261 87.2701 9.1981 88.4269 9.1981C89.6994 9.1981 90.5718 9.68009 90.5718 10.6103C90.5718 11.4345 89.2222 11.6852 87.9931 11.6852C84.0408 11.6852 81.814 12.9432 81.814 15.4688V17.3968C81.814 19.8453 83.3901 20.925 86.5134 20.978C89.1692 21.0262 89.4054 21.0455 90.5718 20.0429C90.5718 20.443 90.9477 20.7852 91.6996 20.7852H94.7989V9.48247C94.8278 7.03395 92.0563 5.80969 88.2486 5.80969ZM90.6007 16.4135C90.6007 17.4353 89.4536 17.9222 88.1474 17.9222C86.8412 17.9222 86.0796 17.6956 86.0796 16.4521V16.1388C86.0796 15.0157 87.0098 14.6494 88.1377 14.5C88.9667 14.3506 89.3283 14.5 90.6007 14.2301V16.4135Z"
                fill="white"
            />
            <path
                d="M167.748 5.80969C164.047 5.80969 161.916 6.94719 161.762 9.21738L165.994 10.432C166.081 9.61261 166.765 9.1981 167.922 9.1981C169.194 9.1981 170.072 9.68009 170.072 10.6103C170.072 11.4345 168.722 11.6852 167.493 11.6852C163.536 11.6852 161.309 12.9432 161.309 15.4688V17.3968C161.309 19.8453 162.89 20.925 166.013 20.978C168.664 21.0262 168.905 21.0455 170.072 20.0429C170.072 20.443 170.448 20.7852 171.2 20.7852H174.308V9.48247C174.308 7.03395 171.551 5.80969 167.748 5.80969ZM170.101 16.4135C170.101 17.4353 168.949 17.9222 167.647 17.9222C166.346 17.9222 165.58 17.6956 165.58 16.4521V16.1388C165.58 15.0157 166.505 14.6494 167.638 14.5C168.467 14.3506 168.828 14.5 170.101 14.2301V16.4135Z"
                fill="white"
            />
            <path
                d="M118.224 6.04083V6.77346C117.081 5.88659 117.26 5.742 113.852 5.80947C110.926 5.88659 109.466 7.03374 109.466 9.48226V17.3966C109.466 19.8451 110.912 21.0115 113.852 21.0693C116.884 21.1272 117.023 21.1561 118.224 20.1295V20.7175V21.3682C118.224 22.2165 117.621 22.7708 115.963 22.7708C114.338 22.8207 112.711 22.7207 111.105 22.4719V25.7399C111.105 25.7399 111.905 26.058 115.688 26.058C120.764 26.058 122.436 24.3855 122.436 22.3852V5.99745L118.224 6.04083ZM118.224 16.288C118.224 17.252 117.472 17.7002 115.963 17.7002C114.454 17.7002 113.703 17.2182 113.703 16.288V10.639C113.703 9.67505 114.454 9.2268 115.963 9.2268C117.472 9.2268 118.224 9.70879 118.224 10.639V16.288Z"
                fill="white"
            />
            <path
                d="M139.759 6.0264H144.001V6.75421C145.047 5.91072 145.114 5.79022 147.312 5.79022C149.51 5.79022 150.257 5.87698 151.289 6.75421C152.604 5.67455 152.604 5.79022 155.843 5.79022C158.383 5.79022 159.651 7.01448 159.651 9.463V20.7657H155.414V10.8849C155.414 9.75059 154.812 9.18506 153.607 9.18827C152.402 9.19148 151.799 9.75702 151.799 10.8849V20.7705H147.674V10.8849C147.674 9.75059 147.061 9.18506 145.837 9.18827C144.613 9.19148 144.001 9.75702 144.001 10.8849V20.7705H139.759V6.0264Z"
                fill="white"
            />
            <path
                d="M180.844 5.96392V6.73993C182.194 5.65545 182.343 5.80005 185.226 5.82415C188.108 5.84825 189.602 7.02913 189.602 9.47765V17.4257C189.602 19.8742 188.156 21.0455 185.226 21.0985C182.16 21.1515 182.011 21.166 180.844 20.1345V26.0871H176.603V5.96392H180.844ZM183.105 17.7053C184.609 17.7053 185.365 17.1413 185.365 16.0135V10.8899C185.365 9.7572 184.609 9.19327 183.105 9.19327C181.601 9.19327 180.844 9.7572 180.844 10.8899V16.0135C180.844 17.1413 181.598 17.7053 183.105 17.7053Z"
                fill="white"
            />
            <path
                d="M133.161 16.4328C133.074 17.2618 132.52 17.6812 131.102 17.6812C129.599 17.6812 128.842 17.1992 128.842 16.2689V14.8567H137.605V9.48247C137.605 7.03395 135.437 5.80969 131.102 5.80969C126.768 5.80969 124.602 7.03395 124.605 9.48247V17.3968C124.605 19.8453 126.771 21.0696 131.102 21.0696C135.281 21.0696 137.253 19.9272 137.402 17.6474L133.161 16.4328ZM128.823 10.6152C128.823 9.65117 129.579 9.20292 131.083 9.20292C132.587 9.20292 133.344 9.68491 133.344 10.6152V12.0274H128.823V10.6152Z"
                fill="white"
            />
            <path
                d="M104.26 10.9954L108.497 9.78074V9.48191C108.497 7.03339 107.292 5.85251 104.882 5.79467C102.472 5.73683 102.323 5.85733 101.262 6.75865V6.04048H97.0256V20.7846H101.262V10.6146C101.262 9.6506 101.744 9.20235 102.761 9.20235C103.778 9.20235 104.26 9.68434 104.26 10.6146V10.9954Z"
                fill="white"
            />
            <path
                d="M0 8.76743C0 5.70678 5.7458e-07 4.17887 0.588032 3.00763C1.10267 1.9746 1.9311 1.13099 2.95462 0.597669C4.1114 -1.03424e-06 5.62968 0 8.66141 0H33.8166C36.8484 0 38.3618 -1.03424e-06 39.5186 0.597669C40.5441 1.12827 41.3733 1.97264 41.8852 3.00763C42.478 4.17887 42.478 5.70678 42.478 8.76743V32.867C42.478 35.9277 42.478 37.4556 41.8852 38.6268C41.3733 39.6618 40.5441 40.5062 39.5186 41.0368C38.3618 41.6297 36.8484 41.6297 33.8166 41.6297H8.66141C5.62968 41.6297 4.1114 41.6297 2.95462 41.0368C1.9311 40.5035 1.10267 39.6599 0.588032 38.6268C5.7458e-07 37.4556 0 35.9277 0 32.867V8.76743Z"
                fill="#895AF6"
            />
            <path
                d="M25.4734 10.4887V5.29761H22.6489V10.4887H19.8196V5.29761H16.9951V10.4887H14.1562V13.1252C14.1562 16.7883 15.7371 20.866 19.8293 21.6275V31.1999C19.8293 32.1108 19.8292 32.7904 19.5497 33.07C19.3473 33.2773 18.7399 33.6243 16.9759 33.6243C15.5974 33.6243 14.7153 33.3977 14.4116 32.9832C14.108 32.5687 14.1562 31.9518 14.1562 31.1999V26.0329C14.1562 23.541 13.7176 20.8756 8.50242 20.8756H0.00488281V23.729H8.45903C11.351 23.729 11.351 24.2688 11.351 26.0377V31.1999C11.351 32.2554 11.351 33.6098 12.1656 34.7136C13.0524 35.9137 14.5755 36.4729 16.9855 36.4729C19.1545 36.4729 20.5956 36.0391 21.521 35.104C22.6296 33.981 22.6682 32.3663 22.6634 31.1999V21.6275C26.7507 20.866 28.3364 16.8076 28.3364 13.1252V10.4887H25.4734Z"
                fill="white"
            />
            <path
                d="M50.9172 41.3058H53.7568V35.564H56.7216C57.7795 35.564 58.7121 35.3761 59.5194 35.0003C60.3268 34.6105 60.9601 34.0468 61.4194 33.3091C61.8927 32.5714 62.1293 31.6875 62.1293 30.6574C62.1293 29.6274 61.8996 28.7087 61.4403 27.9849C60.981 27.2611 60.3407 26.7113 59.5194 26.3355C58.7121 25.9457 57.7795 25.7509 56.7216 25.7509H50.9172V41.3058ZM53.7568 33.0585V28.2563H56.7634C57.529 28.2563 58.1414 28.4721 58.6007 28.9036C59.0601 29.3351 59.2898 29.9197 59.2898 30.6574C59.2898 31.3952 59.0601 31.9659 58.6007 32.4113C58.1414 32.8428 57.529 33.0585 56.7634 33.0585H53.7568Z"
                fill="#895AF6"
            />
            <path
                d="M66.6671 41.5562C68.1425 41.5562 69.3257 41.069 70.2165 40.0947V41.3057H72.8056V33.7474C72.8056 32.954 72.6037 32.2511 72.2001 31.6387C71.7964 31.0262 71.2396 30.5529 70.5297 30.2189C69.8338 29.8709 69.0195 29.6969 68.0869 29.6969C66.9455 29.6969 65.9294 29.9683 65.0385 30.5112C64.1477 31.054 63.5352 31.7709 63.2012 32.6617L65.4352 33.7474C65.644 33.2324 65.9711 32.8218 66.4165 32.5156C66.862 32.1954 67.3839 32.0354 67.9825 32.0354C68.581 32.0354 69.1099 32.2024 69.4858 32.5365C69.8755 32.8566 70.0704 33.2603 70.0704 33.7474V34.1233L66.688 34.6661C65.3517 34.8888 64.3634 35.3134 63.7231 35.9397C63.0828 36.5661 62.7627 37.3526 62.7627 38.2991C62.7627 39.3013 63.1176 40.0947 63.8275 40.6793C64.5374 41.2639 65.4839 41.5562 66.6671 41.5562ZM65.6023 38.1947C65.6023 37.4013 66.1382 36.9002 67.2099 36.6914L70.0704 36.2112V36.6705C70.0704 37.45 69.799 38.1112 69.2561 38.654C68.7272 39.1969 68.0312 39.4683 67.1682 39.4683C66.7089 39.4683 66.333 39.357 66.0407 39.1342C65.7484 38.8976 65.6023 38.5844 65.6023 38.1947Z"
                fill="#895AF6"
            />
            <path
                d="M75.0188 41.3058H77.754V34.9794C77.754 34.1164 77.9906 33.4483 78.4639 32.975C78.951 32.4878 79.5774 32.2442 80.343 32.2442H81.3243V29.8223H80.6562C79.9602 29.8223 79.3477 29.9475 78.8188 30.1981C78.2899 30.4486 77.8792 30.901 77.5869 31.5552V29.9475H75.0188V41.3058Z"
                fill="#895AF6"
            />
            <path
                d="M87.9984 41.431C88.4578 41.431 88.9101 41.3962 89.3556 41.3266V38.9673C89.0911 39.0091 88.8475 39.0299 88.6248 39.0299C87.9566 39.0299 87.4695 38.8768 87.1632 38.5706C86.857 38.2644 86.7039 37.8259 86.7039 37.2552V32.3904H89.3138V29.9475H86.7039V27.3585H83.9687V28.2354C83.9687 28.7922 83.8156 29.2168 83.5094 29.5091C83.2032 29.8014 82.7717 29.9475 82.2149 29.9475H82.0061V32.3904H83.9687V37.4014C83.9687 38.668 84.3167 39.6563 85.0127 40.3662C85.7226 41.0761 86.7178 41.431 87.9984 41.431Z"
                fill="#895AF6"
            />
            <path
                d="M91.1304 41.3057H93.8655V34.6452C93.8655 33.9075 94.0743 33.3159 94.4919 32.8705C94.9234 32.4251 95.4802 32.2024 96.1622 32.2024C96.8443 32.2024 97.3941 32.4251 97.8117 32.8705C98.2432 33.302 98.4589 33.8936 98.4589 34.6452V41.3057H101.194V33.998C101.194 33.1489 101.013 32.4042 100.651 31.7639C100.289 31.1097 99.7813 30.6017 99.1271 30.2398C98.4868 29.8779 97.749 29.6969 96.9139 29.6969C96.204 29.6969 95.5637 29.8361 94.993 30.1145C94.4362 30.3929 94.0047 30.8035 93.6985 31.3463V29.9474H91.1304V41.3057Z"
                fill="#895AF6"
            />
            <path
                d="M108.578 41.5562C109.734 41.5562 110.743 41.2987 111.606 40.7837C112.483 40.2548 113.095 39.5866 113.443 38.7793L111.23 37.6936C110.979 38.1251 110.631 38.48 110.186 38.7584C109.755 39.0229 109.226 39.1551 108.599 39.1551C107.778 39.1551 107.089 38.9046 106.532 38.4035C105.975 37.9024 105.655 37.2342 105.572 36.3991H113.756C113.812 36.246 113.847 36.072 113.861 35.8771C113.889 35.6683 113.903 35.4595 113.903 35.2507C113.903 34.2346 113.687 33.309 113.255 32.4738C112.824 31.6387 112.197 30.9705 111.376 30.4694C110.555 29.9544 109.553 29.6969 108.37 29.6969C107.312 29.6969 106.351 29.9544 105.488 30.4694C104.639 30.9844 103.964 31.6874 103.463 32.5782C102.976 33.4551 102.732 34.4643 102.732 35.6057C102.732 36.7471 102.976 37.7075 103.463 38.6123C103.95 39.517 104.632 40.2339 105.509 40.7628C106.386 41.2918 107.409 41.5562 108.578 41.5562ZM108.37 31.9518C109.093 31.9518 109.692 32.1746 110.165 32.62C110.652 33.0515 110.924 33.6152 110.979 34.3112H105.634C105.787 33.5595 106.108 32.9819 106.595 32.5782C107.096 32.1606 107.687 31.9518 108.37 31.9518Z"
                fill="#895AF6"
            />
            <path
                d="M115.663 41.3058H118.398V34.9794C118.398 34.1164 118.635 33.4483 119.108 32.975C119.596 32.4878 120.222 32.2442 120.987 32.2442H121.969V29.8223H121.301C120.605 29.8223 119.992 29.9475 119.463 30.1981C118.934 30.4486 118.524 30.901 118.231 31.5552V29.9475H115.663V41.3058Z"
                fill="#895AF6"
            />
            <path
                d="M127.488 41.5562C128.351 41.5562 129.102 41.4101 129.743 41.1178C130.383 40.8254 130.884 40.4218 131.246 39.9068C131.608 39.3778 131.789 38.7723 131.789 38.0903C131.789 37.2969 131.538 36.6148 131.037 36.0441C130.536 35.4734 129.812 35.0559 128.866 34.7914L127.049 34.2485C126.66 34.1372 126.339 33.991 126.089 33.8101C125.838 33.6291 125.713 33.3786 125.713 33.0584C125.713 32.7383 125.845 32.4529 126.11 32.2441C126.374 32.0354 126.736 31.931 127.195 31.931C127.766 31.931 128.281 32.0841 128.741 32.3903C129.214 32.6965 129.541 33.1071 129.722 33.6222L131.81 32.6408C131.462 31.7222 130.877 31.0053 130.056 30.4903C129.235 29.9614 128.288 29.6969 127.216 29.6969C126.395 29.6969 125.664 29.8431 125.024 30.1354C124.398 30.4277 123.904 30.8383 123.542 31.3672C123.194 31.8822 123.02 32.4877 123.02 33.1837C123.02 33.9632 123.263 34.6383 123.75 35.209C124.252 35.7797 124.982 36.2042 125.943 36.4826L127.801 37.0046C128.163 37.102 128.469 37.2482 128.72 37.443C128.984 37.624 129.116 37.8745 129.116 38.1947C129.116 38.5705 128.963 38.8698 128.657 39.0925C128.365 39.3013 127.975 39.4057 127.488 39.4057C126.848 39.4057 126.27 39.2247 125.755 38.8628C125.24 38.5009 124.829 37.9998 124.523 37.3595L122.477 38.3408C122.811 39.3152 123.423 40.0947 124.314 40.6793C125.219 41.2639 126.277 41.5562 127.488 41.5562Z"
                fill="#895AF6"
            />
        </svg>
    </nuxt-link>
</template>

<script>
export default {
  name: "LogoPartners",
  props: {
    link: {
      type: String,
      default: "/"
    }
  }
};
</script>

<!-- <style>
.chargemap-logo{animation:appear 1s}@keyframes appear{0%{opacity:0}}
</style> -->
