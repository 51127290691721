<template>
    <div class="flex flex-row gap-4 items-center" data-testid="checkin-header">
        <IconBolt is-solid class="w-[18px] text-green-bolt" />
        <div
            v-if="isAnonymous"
            class="text-gray-800 font-medium text-fifteen-exploitation leading-4"
        >
            {{ $t('anonymous.charge.successful') }}
            <span class="text-gray-500 font-normal">
                {{ displayDate }}
            </span>
        </div>
        <div
            v-else
            class="text-gray-800 font-medium text-fifteen-exploitation leading-4"
        >
            {{ user.username }}
            <span class="text-gray-500 font-normal">
                {{ $t('charge.successful') }} {{ displayDate }}
            </span>
        </div>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import IconBolt from "../../../icons/IconBolt.vue";
export default {
  name: "CheckinHeader",
  components: {
    IconBolt
  },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      }
    },
    isAnonymous: {
      type: Boolean,
      required: true
    },
    endDate: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: true
    }
  },
  computed: {
    displayDate() {
      const now = DateTime.now();
      if (now.diff(this.endDate, "hours").hours >= 24) {
        return this.endDate.toRelativeCalendar({ locale: this.locale });
      }
      return this.endDate.toRelative({ locale: this.locale });
    }
  }
};
</script>
