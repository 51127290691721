<template>
    <div
        class="flex flex-row gap-4 items-center"
        data-testid="checkin-in-progress-header"
    >
        <div
            v-if="isAnonymous"
            class="text-gray-500 font-normal text-fifteen-exploitation leading-4"
        >
            {{ $t('anonymous.charge.in_progress') }}
        </div>
        <div
            v-else
            class="text-gray-800 font-medium text-fifteen-exploitation leading-4"
        >
            {{ getUsername }}
            <span class="text-gray-500 font-normal">
                {{ $t('charge.in_progress') }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
  name: "CheckinInProgressHeader",
  props: {
    user: {
      type: Object,
      default() {
        return {};
      }
    },
    isAnonymous: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    getUsername() {
      return this.user?.username;
    }
  }
};
</script>
