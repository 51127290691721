<template>
    <div v-if="visible" class="relative" data-testid="chargemap-message">
        <IconPolygone
            v-if="hasPolygone"
            class="absolute -top-2.5 left-4"
            :class="`text-${mainColor}-500`"
        />
        <div
            class="flex gap-5 rounded-lg text-white px-4 py-3 justify-between"
            :class="[
                inline ? 'items-center' : 'flex-col',
                `bg-${mainColor}-500`
            ]"
        >
            <div
                :class="[
                    !inline && displayCloseX && 'w-95',
                    !inline && !displayCloseX && 'w-full'
                ]"
            >
                <slot />
            </div>

            <div
                class="flex gap-5"
                :class="
                    inline
                        ? 'flex-row gap-1'
                        : 'w-full items-center justify-between'
                "
            >
                <div
                    v-if="$slots.btnClose"
                    class="flex-1 flex items-center justify-center cursor-pointer"
                    @click.prevent="dismiss"
                >
                    <slot name="btnClose" />
                </div>
                <div
                    v-if="$slots.btnAction"
                    class="flex-1 flex items-center justify-center cursor-pointer"
                >
                    <slot name="btnAction" />
                </div>

                <div
                    v-if="displayCloseX"
                    :class="
                        inline ? 'ml-auto pl-3 ' : 'absolute right-4 top-1.5'
                    "
                >
                    <button
                        class="inline-flex rounded-md p-1.5 focus:outline-none text-white"
                        @click.prevent="dismiss"
                    >
                        <span class="sr-only">Close</span>
                        <IconX class="w-6 h-6" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconPolygone from "../../icons/IconPolygone.vue";
import IconX from "../../icons/IconX.vue";
export default {
  name: "ChargemapMessage",
  components: { IconPolygone, IconX },
  props: {
    identifier: {
      type: String,
      required: true
    },
    hasPolygone: {
      type: Boolean,
      default: false
    },
    displayCloseX: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "info",
      validator(value) {
        return ["info", "partner", "error"].includes(value);
      }
    }
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    mainColor() {
      let color = "blue";
      if (this.type === "partner") {
        color = "violet";
      } else if (this.type === "error") {
        color = "red";
      }
      return color;
    }
  },
  mounted() {
    if (process.client) {
      if (!localStorage.getItem(this.identifier)) {
        localStorage.setItem(this.identifier, "true");
      }
      this.visible = localStorage.getItem(this.identifier) === "true" || false;
    } else {
      this.visible = true;
    }
  },
  methods: {
    dismiss() {
      if (this.identifier) {
        this.stockVisit();
      }
      this.visible = false;
    },
    stockVisit() {
      if (this.identifier && process.client) {
        localStorage.setItem(this.identifier, "false");
      }
    }
  }
};
</script>
