<template>
    <svg
        v-if="isSolid"
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.44845 2.75165C4.22213 2.53306 3.919 2.41211 3.60437 2.41484C3.28973 2.41757 2.98876 2.54378 2.76627 2.76627C2.54378 2.98876 2.41757 3.28973 2.41484 3.60437C2.41211 3.919 2.53306 4.22213 2.75165 4.44845L19.5516 21.2484C19.778 21.467 20.0811 21.588 20.3957 21.5853C20.7104 21.5825 21.0113 21.4563 21.2338 21.2338C21.4563 21.0113 21.5825 20.7104 21.5853 20.3957C21.588 20.0811 21.467 19.778 21.2484 19.5516L19.4808 17.784C21.3499 16.2938 22.732 14.28 23.4504 12C21.9216 7.13165 17.3736 3.60005 12 3.60005C10.119 3.5975 8.26385 4.03907 6.58565 4.88885L4.44965 2.75165H4.44845ZM9.56165 7.86365L11.3784 9.68165C11.7854 9.57359 12.2135 9.57431 12.6201 9.68372C13.0266 9.79314 13.3973 10.0074 13.695 10.3051C13.9927 10.6028 14.207 10.9735 14.3164 11.38C14.4258 11.7866 14.4265 12.2147 14.3184 12.6216L16.1352 14.4384C16.6764 13.5217 16.8975 12.451 16.7636 11.3949C16.6298 10.3387 16.1486 9.35704 15.3958 8.60426C14.6431 7.85148 13.6614 7.37032 12.6052 7.23647C11.5491 7.10261 10.4784 7.32367 9.56165 7.86485V7.86365Z"
        />
        <path
            d="M14.9448 20.0364L11.7 16.7904C10.5332 16.7173 9.43319 16.2209 8.60643 15.3943C7.77967 14.5678 7.28297 13.4679 7.20956 12.3012L2.80196 7.89355C1.78821 9.10077 1.02278 10.4963 0.549561 12C2.07836 16.8684 6.62756 20.4 12 20.4C13.0164 20.4 14.0028 20.274 14.9448 20.0364Z"
        />
    </svg>
    <svg
        v-else
        viewBox="0 0 24 24"
        fill="none"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.29292 2.29289C2.68345 1.90237 3.31661 1.90237 3.70714 2.29289L6.74813 5.33389C8.35578 4.45837 10.1615 3.99763 12.0007 4C16.9277 4.0003 21.0949 7.23878 22.497 11.7002C22.5585 11.8958 22.5584 12.1055 22.4967 12.301C21.8462 14.362 20.6074 16.1775 18.9454 17.5311L21.7071 20.2929C22.0977 20.6834 22.0977 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L16.7058 18.1199C16.7055 18.1197 16.7053 18.1195 16.705 18.1192L13.414 14.8292L9.17101 10.5862C9.16202 10.5772 9.15316 10.568 9.14459 10.5588L2.29292 3.70711C1.9024 3.31658 1.9024 2.68342 2.29292 2.29289ZM8.23715 6.82291L9.96846 8.55422C10.5787 8.19458 11.2791 8.00025 11.9995 8.00025C13.0605 8.00025 14.0779 8.4217 14.8281 9.17189C15.5783 9.92209 15.9998 10.9396 15.9998 12.0005C15.9998 12.7209 15.8055 13.4213 15.4458 14.0316L17.5221 16.1079C18.8804 15.052 19.9115 13.6271 20.4888 11.9994C19.2522 8.50317 15.9174 6 12 6L11.9987 6C10.695 5.99824 9.41194 6.28113 8.23715 6.82291ZM13.9318 12.5176C13.9766 12.3503 13.9998 12.1766 13.9998 12.0005C13.9998 11.47 13.789 10.9612 13.4139 10.5861C13.0388 10.211 12.53 10.0002 11.9995 10.0002C11.8234 10.0002 11.6497 10.0235 11.4825 10.0682L13.9318 12.5176ZM4.62375 8.1738C5.06403 8.50723 5.15065 9.13444 4.81723 9.57472C4.2592 10.3116 3.81881 11.1298 3.51112 12C4.74755 15.4966 8.08246 18 12 18C12.5665 18.0004 13.1326 17.9477 13.6892 17.8424C14.2319 17.7398 14.755 18.0965 14.8576 18.6392C14.9602 19.1819 14.6035 19.705 14.0608 19.8076C13.3812 19.9361 12.691 20.0005 11.9993 20C11.999 20 11.9995 20 11.9993 20C7.07227 19.9997 2.90512 16.7612 1.50303 12.2998C1.44169 12.1046 1.4417 11.8953 1.50306 11.7001C1.88076 10.4987 2.46252 9.37127 3.22283 8.36728C3.55626 7.927 4.18347 7.84038 4.62375 8.1738Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconEyeClosed",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
