<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 53 32"
        class="fill-current"
    >
        <path d="M34.344 12.164h-15.344l7.672 7.672 7.672-7.672z"></path>
    </svg>
</template>

<script>
export default {
  name: "IconDropdown"
};
</script>
