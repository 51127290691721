<template>
    <div
        class="flex flex-col w-10 h-20 bg-white m-2 rounded-lg shadow border border-slate-300 overflow-hidden"
        data-testid="map-zoom"
    >
        <button
            class="flex-grow hover:bg-slate-100"
            data-testid="zoom-in-button"
            @click="zoomIn"
            aria-label="Zoom in"
        >
            <IconPlus class="m-auto w-5 h-5 text-slate-500" />
        </button>
        <button
            class="flex-grow hover:bg-slate-100"
            data-testid="zoom-out-button"
            @click="zoomOut"
            aria-label="Zoom out"
        >
            <IconMinus class="m-auto w-5 h-5 text-slate-500" />
        </button>
    </div>
</template>

<script>
import IconPlus from "../../icons/IconPlus.vue";
import IconMinus from "../../icons/IconMinus.vue";
export default {
  name: "MapZoom",
  components: {
    IconPlus,
    IconMinus
  },
  emits: ["zoomIn", "zoomOut"],
  methods: {
    zoomIn() {
      this.$emit("zoomIn");
    },
    zoomOut() {
      this.$emit("zoomOut");
    }
  }
};
</script>
