<template>
    <transition name="drop-fade">
        <div
            v-if="visible"
            class="p-4"
            :class="[
                { 'rounded-xl': rounded },
                { 'shadow-sm': shadowed },
                bordered && 'border border-slate-300'
            ]"
            data-testid="chargemap-alert"
        >
            <div class="flex">
                <div class="flex-shrink-0">
                    <IconNotificationInfoCircle
                        v-if="type === 'info' || type === 'neutral'"
                        :type="type"
                    />
                    <IconNotificationCheckCircle v-if="type === 'validate'" />
                    <IconNotificationExclamationCircle
                        v-if="type === 'warning' || type === 'error'"
                        :type="type"
                        class="text-yellow-500"
                    />
                </div>
                <div class="ml-3">
                    <h3 v-if="$slots.title" class="text-sm font-semibold">
                        <slot name="title" />
                    </h3>
                    <div
                        v-if="$slots.default"
                        class="text-sm"
                        :class="[`text-slate-500`, { 'mt-2': $slots.title }]"
                    >
                        <slot />
                    </div>
                    <div v-if="$slots.confirm || $slots.dismiss" class="mt-4">
                        <div class="-mx-2 -my-1.5 flex">
                            <button
                                v-if="$slots.confirm"
                                class="mr-3 px-2 py-1.5 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
                                :class="`bg-${color}-50 text-${color}-800 hover:bg-${color}-100 focus:ring-offset-${color}-50 focus:ring-${color}-600`"
                                @click.prevent="callConfirm"
                            >
                                <slot name="confirm" />
                            </button>
                            <button
                                v-if="$slots.dismiss"
                                class="px-2 py-1.5 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
                                :class="`bg-${color}-50 text-${color}-800 hover:bg-${color}-100 focus:ring-offset-${color}-50 focus:ring-${color}-600`"
                                @click.prevent="dismiss"
                            >
                                <slot name="dismiss" />
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    v-if="showClose || $slots.action"
                    class="ml-auto pl-3 -mx-1 -my-1"
                >
                    <button
                        v-if="$slots.action && !showClose"
                        class="text-sm ml-6 whitespace-nowrap font-medium text-slate-500 hover:text-slate-700"
                        @click.prevent="callAction"
                    >
                        <slot name="action" />
                        <span aria-hidden="true">&rarr;</span>
                    </button>
                    <button
                        v-if="showClose"
                        class="inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer text-slate-500"
                        @click.prevent="dismiss"
                    >
                        <span class="sr-only">Close</span>
                        <IconClose />
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import IconClose from "../../icons/IconClose.vue";
import IconError from "../../icons/IconError.vue";
import IconInfo from "../../icons/IconInfo.vue";
import IconNotificationCheckCircle from "../../icons/IconNotificationCheckCircle.vue";
import IconNotificationExclamationCircle from "../../icons/IconNotificationExclamationCircle.vue";
import IconNotificationInfoCircle from "../../icons/IconNotificationInfoCircle.vue";
import IconSuccess from "../../icons/IconSuccess.vue";
import IconWarning from "../../icons/IconWarning.vue";
export default {
  name: "ChargemapAlert",
  components: {
    IconError,
    IconWarning,
    IconInfo,
    IconSuccess,
    IconClose,
    IconNotificationCheckCircle,
    IconNotificationExclamationCircle,
    IconNotificationInfoCircle
  },
  props: {
    type: {
      type: String,
      default: "info",
      validator(value) {
        return [
          "info",
          "warning",
          "error",
          "validate",
          "neutral"
        ].includes(value);
      }
    },
    identifier: {
      type: String,
      default() {
        return null;
      }
    },
    showClose: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },
    shadowed: {
      type: Boolean,
      default: true
    },
    bordered: {
      type: Boolean,
      default: true
    }
  },
  emits: ["callConfirm", "callAction", "close"],
  data: () => ({ visible: false }),
  computed: {
    color() {
      switch (this.type) {
        case "error":
          return "red";
        case "warning":
          return "yellow";
        case "validate":
          return "green";
        case "info":
        default:
          return "primary";
      }
    }
  },
  mounted() {
    if (!this.identifier) {
      this.visible = true;
    } else if (process.client) {
      if (!localStorage.getItem(this.identifier)) {
        localStorage.setItem(this.identifier, "false");
      }
      this.visible = !!localStorage.getItem(this.identifier);
    } else {
      this.visible = true;
    }
  },
  methods: {
    callConfirm() {
      if (this.identifier) {
        this.stockVisit();
      }
      this.$emit("callConfirm");
    },
    callAction() {
      this.$emit("callAction");
    },
    dismiss() {
      if (this.identifier) {
        this.stockVisit();
      }
      this.visible = false;
      this.$emit("close");
    },
    stockVisit() {
      if (this.identifier && process.client) {
        localStorage.setItem(this.identifier, "true");
      }
    }
  }
};
</script>
