<template>
    <div
        v-if="loadingInfo"
        class="flex gap-1 flex-wrap"
        data-testid="drawer-badges"
    >
        <ChargemapSkeletonLoader
            v-for="index in 3"
            :key="index"
            class="w-28 h-7 rounded-full border border-zinc-300"
        />
    </div>

    <div v-else class="flex gap-1 flex-wrap" data-testid="drawer-badges">
        <ChargemapBadge
            v-for="badge in badges"
            :key="badge.icon"
            type="neutral"
            :with-border="false"
        >
            <div class="flex gap-1 justify-center items-center">
                <component :is="badge.icon" class="text-gray-500 w-5 h-5" />
                <span>{{ $t(`drawer.badges.${badge.text}`) }}</span>
            </div>
        </ChargemapBadge>
    </div>
</template>

<script>
import {
  AuthenticationMethod,
  OwnerType
} from "../../../../interfaces/poolDetail";
import IconChargingBadge from "../../../icons/IconChargingBadge.vue";
import IconClock from "../../../icons/IconClock.vue";
import IconCreditCard from "../../../icons/IconCreditCard.vue";
import IconDeviceMobile from "../../../icons/IconDeviceMobile.vue";
import IconEuro from "../../../icons/IconEuro.vue";
import IconHome from "../../../icons/IconHome.vue";
import ChargemapBadge from "../../global/ChargemapBadge.vue";
import ChargemapSkeletonLoader from "../../global/ChargemapSkeletonLoader.vue";
export default {
  name: "DrawerBadges",
  components: {
    ChargemapBadge,
    IconClock,
    IconEuro,
    IconChargingBadge,
    IconDeviceMobile,
    IconCreditCard,
    IconHome,
    ChargemapSkeletonLoader
  },
  props: {
    pool: {
      type: Object,
      default: void 0
    },
    loadingInfo: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    badges() {
      const badges = [];
      if (this.isAlwaysOpen) {
        badges.push({
          icon: "IconClock",
          text: "open_24"
        });
      }
      if (this.isFree) {
        badges.push({
          icon: "IconEuro",
          text: "free_charge"
        });
      }
      if (this.canChargeWithChargemap) {
        badges.push({
          icon: "IconChargingBadge",
          text: "chargemap_pass"
        });
      }
      if (this.canRemoteStartCharge) {
        badges.push({
          icon: "IconDeviceMobile",
          text: "remote_charge"
        });
      }
      if (this.isDomestic) {
        badges.push({
          icon: "IconCreditCard",
          text: "credit_card"
        });
      }
      if (this.canPayWithCreditCard) {
        badges.push({
          icon: "IconHome",
          text: "individual"
        });
      }
      return badges;
    },
    isAlwaysOpen() {
      return this.pool?.alwaysOpen || false;
    },
    isFree() {
      return this.pool?.isFree || false;
    },
    canChargeWithChargemap() {
      return this.pool?.chargemapPassCompatible || false;
    },
    canRemoteStartCharge() {
      return this.pool?.canRemoteStartCharge || false;
    },
    isDomestic() {
      return this.pool?.owner?.type === OwnerType.THIRD_PARTY || false;
    },
    canPayWithCreditCard() {
      return this.pool?.stations?.some(
        (station) => station.authenticationMethods.includes(
          AuthenticationMethod.CREDIT_CARD
        )
      ) || false;
    }
  }
};
</script>
