<template>
    <div>
        <div
            class="transition-transform ease-in-out duration-700 flex"
            :style="{
                height: `${fakeDivHeight}px !important`,
                width: `${fakeDivWidth}px !important`
            }"
        />
        <div
            ref="drawer"
            class="mobile-drawer absolute flex flex-col gap-2 ease-in-out bottom-0 duration-700 z-50 bg-white overflow-hidden"
            :class="[
                drawerVisible ? 'pointer-events-auto' : 'pointer-events-none',
                isResizing ? 'resizing transition-none' : 'transition-all',
                isFullSize ? 'h-full' : 'h-auto',
                animateSize ? 'transition-all' : 'transition-none',
                !isFullSize && !isDesktopDrawer
                    ? 'rounded-t-xl border-t border-slate-300'
                    : '!rounded-t-none',
                isDesktopDrawer ? '!absolute !border-r' : ''
            ]"
            :style="{
                width: isDesktopDrawer ? `${drawerWidth}px` : '100%',
                height:
                    isDesktopDrawer || (isFullSize && visible)
                        ? '100% !important'
                        : drawerHeight + 'px !important',
                ...drawerPositionStyle
            }"
            data-testid="chargemap-drawer"
            @transitionstart="animationStart"
        >
            <!-- RESIZE HANDLE -->
            <div
                class="resize-handle justify-center"
                :class="[
                    {
                        hidden: isDesktopDrawer
                    },
                    isFullSize ? 'hidden' : 'flex'
                ]"
                @mousedown="startResize"
                @dblclick="closeDrawer"
                @touchstart="startResize"
            ></div>

            <!-- PILL RESIZE HANDLE FOR MOBILE -->
            <div
                class="sticky top-0 w-12 ml-auto pointer-events-none flex-shrink-0 transition-all ease-in-out duration-500 mr-auto bg-black bg-opacity-10 rounded-2xl mt-2"
                :class="[
                    isFullSize ? 'h-0 -pb-2' : 'h-1.5',
                    { hidden: isDesktopDrawer }
                ]"
            />

            <!-- CONTAINER -->
            <div
                class="w-full flex-col inline-flex relative overflow-hidden h-full"
                :class="[isResizing ? 'resizing' : '']"
            >
                <DrawerTitleBar
                    v-if="title"
                    :show-close-button="drawCloseButton"
                    :title="title"
                    @close-drawer="closeDrawer"
                />
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import DrawerTitleBar from "../drawer/DrawerTitleBar.vue";
const defaultDrawerHeight = 400;
export default {
  name: "ChargemapDrawer",
  components: { DrawerTitleBar },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    canClose: {
      type: Boolean,
      default: true
    },
    closeTrigger: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: "left",
      validator: (value) => ["left", "right"].includes(value)
    },
    forcedMobile: {
      type: Boolean,
      default: false
    },
    noScroll: {
      type: Boolean,
      default: false
    },
    closeHeight: {
      type: Number,
      default: 75
    },
    drawerWidth: {
      type: Number,
      default: 488
    },
    forceFullSize: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: void 0
    }
  },
  emits: ["updateVisible", "showCloseButton"],
  data() {
    return {
      drawerVisible: this.visible,
      drawerHeight: 0,
      isResizing: false,
      initialY: 0,
      initialHeight: 0,
      isFullSize: this.forceFullSize,
      drawCloseButton: this.showCloseButton,
      windowWidth: 0,
      animateSize: false,
      fakeDivHeight: 0,
      fakeDivWidth: 0,
      gotOpened: false,
      resizeObserver: null,
      timeout: null,
      interval: null
    };
  },
  computed: {
    isDesktopDrawer() {
      return this.windowWidth > 768 && !this.forcedMobile;
    },
    drawerPositionStyle() {
      if (this.position === "left") {
        return {
          left: this.drawerVisible || !this.isDesktopDrawer ? 0 : `-${this.drawerWidth}px`
        };
      }
      return {
        right: this.drawerVisible || !this.isDesktopDrawer ? 0 : `-${this.drawerWidth}px`
      };
    }
  },
  watch: {
    closeTrigger(val) {
      if (val) {
        if (this.canClose) {
          this.close();
        } else {
          this.softCloseDrawer();
        }
      }
    },
    visible: {
      handler(val) {
        this.drawerVisible = val;
        if (val) {
          this.drawerHeight = defaultDrawerHeight;
        } else {
          this.drawerHeight = 0;
        }
        this.gotOpened = true;
        this.timeout = setTimeout(() => {
          this.gotOpened = false;
        }, 100);
      },
      immediate: true
    },
    isFullSize: {
      handler() {
        this.updateShouldDrawButton();
      },
      immediate: true
    },
    isDesktopDrawer: {
      handler() {
        this.updateShouldDrawButton();
      },
      immediate: true
    },
    drawCloseButton(val) {
      this.$emit("showCloseButton", val);
    }
  },
  mounted() {
    this.windowWidth = window.outerWidth;
    window.addEventListener("resize", this.onResize);
    this.animateSize = false;
    this.timeout = setTimeout(() => {
      this.animateSize = true;
    }, 100);
    this.resizeObserver = new ResizeObserver(this.onResizeDrawer);
    this.resizeObserver.observe(this.$refs.drawer);
    this.fakeDivWidth = this.drawerWidth + (this.$refs.drawer?.offsetLeft || 0);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
    this.resizeObserver?.unobserve(this.$refs.drawer);
  },
  methods: {
    updateShouldDrawButton() {
      this.drawCloseButton = !this.isDesktopDrawer && this.isFullSize || this.isDesktopDrawer && this.showCloseButton;
    },
    onResize() {
      this.windowWidth = window.outerWidth;
      this.animateSize = this.drawerVisible;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.animateSize = true;
      }, 100);
    },
    onResizeDrawer() {
      this.fakeDivHeight = Math.max(
        this.$refs.drawer.clientHeight - 10,
        0
      );
    },
    close() {
      this.$emit("updateVisible", false);
    },
    startResize(e) {
      e.preventDefault();
      this.isResizing = true;
      this.initialY = e.clientY || e.touches?.[0]?.clientY || 0;
      this.initialHeight = this.drawerHeight;
      document.body.style.overflow = "hidden";
      document.addEventListener("mousemove", this.handleResize);
      document.addEventListener("touchmove", this.handleResize, {
        passive: false
      });
      document.addEventListener("mouseup", this.stopResize);
      document.addEventListener("touchend", this.stopResize);
    },
    handleResize(e) {
      e.preventDefault();
      if (!this.isResizing) return;
      const currentY = e.clientY || e.touches?.[0]?.clientY || 0;
      const deltaY = currentY - this.initialY;
      const newHeight = this.initialHeight - deltaY;
      const windowHeight = window.innerHeight - 20;
      const maxDrawerHeight = Math.min(windowHeight, newHeight);
      this.drawerHeight = maxDrawerHeight;
      if (newHeight < this.closeHeight) {
        if (this.canClose) {
          this.closeDrawer();
          this.drawerHeight = 0;
        } else {
          this.drawerHeight = this.closeHeight;
        }
      }
      if (newHeight > windowHeight - 75) {
        this.isFullSize = true;
        this.stopResize();
      }
    },
    closeDrawer() {
      if (this.isFullSize && !this.forceFullSize) {
        this.softCloseDrawer();
      } else {
        this.close();
      }
      this.stopResize();
    },
    // This allows to remove the full screen mode when the button is clicked
    softCloseDrawer() {
      this.isFullSize = false;
      this.drawerHeight = defaultDrawerHeight;
    },
    stopResize() {
      this.isResizing = false;
      document.body.style.overflow = "auto";
      document.removeEventListener("mousemove", this.handleResize);
      document.removeEventListener("touchmove", this.handleResize);
      document.removeEventListener("mouseup", this.stopResize);
      document.removeEventListener("touchend", this.stopResize);
    },
    animationStart() {
      if (this.isResizing || !this.gotOpened) return;
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.interval = setInterval(() => {
        if (this.position === "left") {
          this.fakeDivWidth = this.drawerWidth + (this.$refs.drawer?.offsetLeft || 0);
        } else if (this.position === "right") {
          this.fakeDivWidth = window.outerWidth - (this.$refs.drawer?.offsetLeft || 0) + (window.outerWidth - window.innerWidth);
        }
      }, 1);
      setTimeout(() => {
        this.animationEnd();
      }, 700);
    },
    animationEnd() {
      clearInterval(this.interval);
    }
  }
};
</script>

<style scoped>
.mobile-drawer {
  @apply w-full z-50;
  box-shadow: 0px -4px 8px 0px rgba(19, 47, 68, 0.2), 0px 0px 1px 0px rgba(19, 47, 68, 0.1);
}

.resize-handle {
  @apply absolute top-0 left-0 w-full h-12 cursor-ns-resize;
}

.resizing {
  user-select: none;
}
</style>
