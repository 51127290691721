<template>
    <div
        class="p-4 w-full rounded-lg flex justify-center items-start"
        :class="[
            `bg-${color}-100`,
            shadowed ? 'shadow-sm' : '',
            isLarge ? 'flex-col max-h-full gap-4' : ' max-h-14 gap-2'
        ]"
        data-testid="drawer-message"
    >
        <div class="gap-2 flex w-full overflow-hidden">
            <div class="flex-shrink-0">
                <IconSuccess
                    v-if="type === MessageTypes.POSITIVE"
                    class="w-6 h-6"
                    :class="`text-${color}-500`"
                />
                <IconWarning
                    v-if="
                        type === MessageTypes.NEGATIVE ||
                        type === MessageTypes.WARNING
                    "
                    :class="`text-${color}-500`"
                    class="w-6 h-6"
                />
                <IconInfoCircle
                    v-if="
                        type === MessageTypes.INFO ||
                        type === MessageTypes.NEUTRAL
                    "
                    class="w-6 h-6"
                    :class="`text-${color}-500`"
                    is-solid
                />
            </div>
            <div
                :class="[
                    `text-${color}-800`,
                    isLarge
                        ? ''
                        : 'text-ellipsis whitespace-nowrap overflow-hidden'
                ]"
            >
                {{ messageText }}
            </div>
        </div>
        <div
            class="flex flex-row justify-between flex-shrink-0"
            :class="isLarge ? 'w-full' : ''"
        >
            <div
                v-if="isLarge"
                class="flex w-fit px-2.5 py-2 rounded-md border border-green-700 items-center gap-2"
                :class="[
                    `text-${color}-700`,
                    `hover:text-${color}-600`,
                    `border-${color}-700`
                ]"
            >
                <button
                    class="text-center text-xs font-semibold"
                    @click="messageActionClick"
                >
                    {{ buttonText }}
                </button>
            </div>
            <div
                class="flex flex-shrink-0"
                :class="[`text-${color}-700`, `hover:text-${color}-600`]"
            >
                <button
                    class="text-center font-semibold"
                    @click="() => (isLarge = !isLarge)"
                >
                    {{
                        isLarge ? $t('show.less_short') : $t('show.more_short')
                    }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { MessageTypes } from "../../../../interfaces/drawer";
import IconInfoCircle from "../../../icons/IconInfoCircle.vue";
import IconSuccess from "../../../icons/IconSuccess.vue";
import IconWarning from "../../../icons/IconWarning.vue";
export default {
  name: "DrawerMessage",
  components: { IconInfoCircle, IconSuccess, IconWarning },
  props: {
    type: {
      type: String,
      default: MessageTypes.NEUTRAL
    },
    shadowed: {
      type: Boolean,
      default: false
    },
    messageText: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    }
  },
  emits: ["messageActionClick"],
  data() {
    return {
      isLarge: false,
      MessageTypes
    };
  },
  computed: {
    color() {
      switch (this.type) {
        case MessageTypes.NEGATIVE:
          return "red";
        case MessageTypes.WARNING:
          return "amber";
        case MessageTypes.POSITIVE:
          return "green";
        case MessageTypes.INFO:
          return "sky";
        default:
          return "zinc";
      }
    }
  },
  methods: {
    messageActionClick() {
      this.$emit("messageActionClick");
    }
  }
};
</script>
