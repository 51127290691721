<template>
    <svg
        v-if="isSolid"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.5131 2.78524C15.0459 2.93056 15.3601 3.4803 15.2148 4.01312L10.7148 20.5131C10.5694 21.0459 10.0197 21.3601 9.48688 21.2148C8.95406 21.0695 8.63992 20.5197 8.78524 19.9869L13.2852 3.48689C13.4306 2.95406 13.9803 2.63993 14.5131 2.78524ZM16.5429 6.0429C16.9334 5.65238 17.5666 5.65238 17.9571 6.0429L23.2071 11.2929C23.5976 11.6834 23.5976 12.3166 23.2071 12.7071L17.9571 17.9571C17.5666 18.3476 16.9334 18.3476 16.5429 17.9571C16.1524 17.5666 16.1524 16.9334 16.5429 16.5429L21.0858 12L16.5429 7.45711C16.1524 7.06659 16.1524 6.43342 16.5429 6.0429ZM7.45711 6.0429C7.84763 6.43342 7.84763 7.06659 7.45711 7.45711L2.91421 12L7.45711 16.5429C7.84763 16.9334 7.84763 17.5666 7.45711 17.9571C7.06658 18.3476 6.43342 18.3476 6.04289 17.9571L0.792893 12.7071C0.402369 12.3166 0.402369 11.6834 0.792893 11.2929L6.04289 6.0429C6.43342 5.65238 7.06658 5.65238 7.45711 6.0429Z"
        />
    </svg>

    <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.4473 3.02637C14.847 3.13536 15.0826 3.54766 14.9736 3.94728L10.4736 20.4473C10.3646 20.8469 9.95228 21.0825 9.55266 20.9735C9.15304 20.8645 8.91744 20.4522 9.02643 20.0526L13.5264 3.55261C13.6354 3.15299 14.0477 2.91738 14.4473 3.02637ZM16.7197 6.21961C17.0126 5.92672 17.4874 5.92672 17.7803 6.21961L23.0303 11.4696C23.3232 11.7625 23.3232 12.2374 23.0303 12.5303L17.7803 17.7803C17.4874 18.0732 17.0126 18.0732 16.7197 17.7803C16.4268 17.4874 16.4268 17.0125 16.7197 16.7196L21.4393 11.9999L16.7197 7.28027C16.4268 6.98738 16.4268 6.51251 16.7197 6.21961ZM7.28033 6.21961C7.57322 6.51251 7.57322 6.98738 7.28033 7.28027L2.56066 11.9999L7.28033 16.7196C7.57322 17.0125 7.57322 17.4874 7.28033 17.7803C6.98744 18.0732 6.51256 18.0732 6.21967 17.7803L0.96967 12.5303C0.676777 12.2374 0.676777 11.7625 0.96967 11.4696L6.21967 6.21961C6.51256 5.92672 6.98744 5.92672 7.28033 6.21961Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconCodeBracket",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
