<template>
    <svg
        width="24"
        height="24"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        class="fill-current"
    >
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            :stroke-width="strokeWidth"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
    </svg>
</template>

<script>
export default {
  name: "IconPlus",
  props: {
    strokeWidth: {
      type: Number,
      default: 2
    }
  }
};
</script>
