<template>
    <svg
        v-if="isSolid"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.79999 1.29999C2.79999 1.29999 2.39999 2.09999 2.39999 3.69999V13.3H1.59999C1.15816 13.3 0.799988 13.6582 0.799988 14.1C0.799988 14.5418 1.15816 14.9 1.59999 14.9H12C12.4418 14.9 12.8 14.5418 12.8 14.1C12.8 13.6582 12.4418 13.3 12 13.3H11.2V11.9982C11.7784 11.7435 12.3061 11.3982 12.7598 10.9769C13.2798 10.4941 13.6923 9.92083 13.9737 9.28994C14.2551 8.65905 14.4 7.98286 14.4 7.29999C14.4 6.61711 14.2551 5.94093 13.9737 5.31003C13.6923 4.67914 13.2798 4.1059 12.7598 3.62303C12.2753 3.17318 11.7064 2.80998 11.0815 2.55111C10.854 1.71703 10.244 1.29999 8.79999 1.29999H4.79999ZM11.2 4.32856V10.2714C11.3511 10.1662 11.4943 10.0509 11.6284 9.92638C11.9998 9.58148 12.2945 9.17202 12.4955 8.72138C12.6965 8.27074 12.8 7.78775 12.8 7.29999C12.8 6.81222 12.6965 6.32923 12.4955 5.87859C12.2945 5.42795 11.9998 5.01849 11.6284 4.67359C11.4943 4.54903 11.3511 4.43378 11.2 4.32856ZM7.91553 4.05793C8.11312 3.66275 7.95294 3.18221 7.55776 2.98462C7.16258 2.78703 6.68204 2.94721 6.48445 3.34239L4.88445 6.54239C4.76045 6.79038 4.7737 7.08489 4.91947 7.32075C5.06523 7.5566 5.32273 7.70016 5.59999 7.70016H6.70556L5.68445 9.74239C5.48686 10.1376 5.64703 10.6181 6.04222 10.8157C6.4374 11.0133 6.91794 10.8531 7.11553 10.4579L8.71553 7.25793C8.83953 7.00994 8.82627 6.71543 8.68051 6.47958C8.53474 6.24372 8.27725 6.10016 7.99999 6.10016H6.89442L7.91553 4.05793Z"
        />
    </svg>
    <svg
        v-else
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14 4H9C7.89543 4 7 4.89543 7 6V20H16V6C16 4.89543 15.1046 4 14 4ZM9 2C6.79086 2 5 3.79086 5 6V22H18V6C18 3.79086 16.2091 2 14 2H9Z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.4472 6.10557C12.9412 6.35256 13.1414 6.95324 12.8944 7.44721L11.618 10H13C13.3466 10 13.6684 10.1795 13.8507 10.4743C14.0329 10.7691 14.0494 11.1372 13.8944 11.4472L11.8944 15.4472C11.6474 15.9412 11.0468 16.1414 10.5528 15.8944C10.0588 15.6474 9.85858 15.0468 10.1056 14.5528L11.382 12H10C9.65342 12 9.33156 11.8205 9.14935 11.5257C8.96714 11.2309 8.95058 10.8628 9.10557 10.5528L11.1056 6.55279C11.3526 6.05881 11.9532 5.85858 12.4472 6.10557Z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 21C3 20.4477 3.44772 20 4 20H19C19.5523 20 20 20.4477 20 21C20 21.5523 19.5523 22 19 22H4C3.44772 22 3 21.5523 3 21Z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.4672 9.32122C22.8189 10.1705 23 11.0807 23 12C23 12.9193 22.8189 13.8295 22.4672 14.6788C22.1154 15.5281 21.5998 16.2997 20.9497 16.9497C20.2997 17.5998 19.5281 18.1154 18.6788 18.4672C17.8295 18.8189 16.9193 19 16 19V17C16.6566 17 17.3068 16.8707 17.9134 16.6194C18.52 16.3681 19.0712 15.9998 19.5355 15.5355C19.9998 15.0712 20.3681 14.52 20.6194 13.9134C20.8707 13.3068 21 12.6566 21 12C21 11.3434 20.8707 10.6932 20.6194 10.0866C20.3681 9.47995 19.9998 8.92876 19.5355 8.46447C19.0712 8.00017 18.52 7.63188 17.9134 7.3806C17.3068 7.12933 16.6566 7 16 7V5C16.9193 5 17.8295 5.18106 18.6788 5.53284C19.5281 5.88463 20.2997 6.40024 20.9497 7.05025C21.5998 7.70026 22.1154 8.47194 22.4672 9.32122Z"
        />
    </svg>
</template>
<script>
export default {
    name: 'IconChargingStation',
    props: {
        isSolid: {
            type: Boolean,
            default: false
        }
    }
}
</script>
