<template>
    <div class="flex flex-col gap-6 items-center" data-testid="connectors-tab">
        <div
            v-if="!isPartner"
            class="p-0.5 rounded-full hover:cursor-pointer text-sky-700 hover:text-sky-500 justify-center items-center gap-1.5 inline-flex"
            @click="findConnectorIdClicked"
        >
            <IconInfoCircle class="w-6 h-6 relative" is-solid />
            <div class="text-center text-base font-semibold leading-tight">
                {{ $t('connector-tab.find_connector_id') }}
            </div>
        </div>

        <ChargemapSkeletonLoader
            v-if="loadingStations"
            class="rounded-lg w-full h-48 border border-zinc-300"
        />
        <div
            v-else
            class="w-full bg-white rounded-lg border border-zinc-300 flex flex-col divide-y"
        >
            <PoolConnectorDetails
                v-for="poolConnector of orderedConnectors"
                :key="`${poolConnector.id}`"
                :connector="poolConnector"
                :total-connectors-count="getConnectorsCount(poolConnector)"
                :available-connectors-count="
                    getAvailableConnectorsCount(poolConnector)
                "
                :is-all-static="isAllStatic(poolConnector)"
                :is-all-unknown="isAllUnknown(poolConnector)"
                :is-all-faulty="isAllFaulty(poolConnector)"
                :power-connector="getPower(poolConnector)"
                :connector-type="getConnectorType(poolConnector)"
                :is-open="
                    getOpenedPoolConnectorId === poolConnector.id || undefined
                "
                :legacy-api-url="legacyApiUrl"
            />
        </div>
    </div>
</template>

<script>
import { PoolRealtimeState } from "../../../../interfaces/pool";
import IconInfoCircle from "../../../icons/IconInfoCircle.vue";
import ChargemapSkeletonLoader from "../../global/ChargemapSkeletonLoader.vue";
import PoolConnectorDetails from "../../pool/PoolConnectorDetails.vue";
export default {
  name: "ConnectorsTab",
  components: {
    PoolConnectorDetails,
    IconInfoCircle,
    ChargemapSkeletonLoader
  },
  props: {
    poolConnectors: {
      type: Array,
      required: true
    },
    openedPoolConnector: {
      type: Object,
      default: void 0
    },
    legacyApiUrl: {
      type: String,
      required: true
    },
    isPartner: {
      type: Boolean,
      required: true
    },
    loadingStations: {
      type: Boolean,
      required: true
    }
  },
  emits: ["connectorClicked", "findConnectorIdClicked"],
  data() {
    return {
      loader: false,
      connectorsList: this.$refs.connectorsList,
      timeout: null
    };
  },
  computed: {
    orderedConnectors() {
      const connectors = this.poolConnectors.map((el) => el);
      return connectors.sort((a, b) => a.power < b.power ? 1 : -1);
    },
    getOpenedPoolConnectorId() {
      return this.openedPoolConnector?.id;
    }
  },
  methods: {
    getConnectorsCount(poolConnector) {
      return poolConnector.realtimeStatusCount?.reduce(
        (total, current) => total + current.count,
        0
      ) || 0;
    },
    getAvailableConnectorsCount(poolConnector) {
      return poolConnector.realtimeStatusCount?.filter(
        (realtimeStatusCount) => realtimeStatusCount?.realtimeStatus === PoolRealtimeState.AVAILABLE
      )[0]?.count || 0;
    },
    isAllStatic(poolConnector) {
      return !poolConnector.realtimeStatusCount.some(
        (realtimeStatus) => realtimeStatus.realtimeStatus
      );
    },
    isAllFaulty(poolConnector) {
      return poolConnector.realtimeStatusCount?.filter(
        (realtimeStatusCount) => realtimeStatusCount?.realtimeStatus === PoolRealtimeState.FAULTED || realtimeStatusCount?.realtimeStatus === PoolRealtimeState.OUT_OF_ORDER
      ).reduce((total, current) => total + current.count, 0) === this.getConnectorsCount(poolConnector);
    },
    isAllUnknown(poolConnector) {
      return poolConnector.realtimeStatusCount?.filter(
        (realtimeStatusCount) => realtimeStatusCount?.realtimeStatus === PoolRealtimeState.UNKNOWN
      ).reduce((total, current) => total + current.count, 0) === this.getConnectorsCount(poolConnector);
    },
    getPower(poolConnector) {
      return poolConnector.power || 0;
    },
    getConnectorType(poolConnector) {
      return poolConnector.connectorType;
    },
    findConnectorIdClicked() {
      this.$emit("findConnectorIdClicked");
    }
  }
};
</script>
