<template>
    <div
        data-testid="checkin-content"
        class="flex flex-col ml-8.5 gap-1 font-normal leading-4 text-fifteen-exploitation"
    >
        <div v-if="hasVehicle">
            {{ getVehicleBrandName }} {{ getVehicleModelName }}
        </div>
        <div v-if="hasConnectorMaxPower">
            {{ connectorTypeTranslation }}
            <span class="text-gray-500">
                {{ getConnectorMaxPower }} {{ $t('unit.kw') }}
            </span>
        </div>
        <div v-if="hasChargedWithPass">
            {{ $t('with_chargemap_pass') }}
        </div>
    </div>
</template>

<script>
export default {
  name: "CheckinContent",
  props: {
    vehicle: {
      type: Object,
      default() {
        return {};
      }
    },
    connector: {
      type: Object,
      default() {
        return {};
      }
    },
    hasChargedWithPass: {
      type: Boolean,
      default: false
    },
    connectorTypeTranslation: {
      type: String,
      default: ""
    }
  },
  computed: {
    hasConnectorMaxPower() {
      return this.connector && this.connector.maxPower;
    },
    hasVehicle() {
      return this.vehicle && this.vehicle.brandName && this.vehicle.modelName;
    },
    getVehicleModelName() {
      return this.vehicle.modelName;
    },
    getVehicleBrandName() {
      return this.vehicle.brandName;
    },
    getConnectorMaxPower() {
      return this.connector.maxPower;
    }
  }
};
</script>
