<template>
    <svg
        v-if="isSolid"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.4 1.9032L9.6 6.7032V22.0968L14.4 17.2968V1.9032ZM4.4484 3.9516C4.28058 3.78383 4.06678 3.66958 3.83404 3.6233C3.6013 3.57702 3.36006 3.60078 3.14082 3.69158C2.92158 3.78239 2.73418 3.93616 2.60232 4.13345C2.47046 4.33074 2.40005 4.5627 2.4 4.8V16.8C2.40007 17.1182 2.52654 17.4234 2.7516 17.6484L7.2 22.0968V6.7032L4.4484 3.9516ZM21.2484 6.3516L16.8 1.9032V17.2968L19.5516 20.0484C19.7194 20.2162 19.9332 20.3304 20.166 20.3767C20.3987 20.423 20.6399 20.3992 20.8592 20.3084C21.0784 20.2176 21.2658 20.0638 21.3977 19.8665C21.5295 19.6693 21.6 19.4373 21.6 19.2V7.2C21.5999 6.88176 21.4735 6.57659 21.2484 6.3516Z"
        />
    </svg>

    <svg
        v-else
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.5528 3.10557C14.8343 2.9648 15.1657 2.96481 15.4473 3.10561L20.894 5.82947C21.2262 5.99545 21.5056 6.25062 21.7009 6.56641C21.8962 6.88221 21.9998 7.24615 22 7.61747V18.382C21.9998 18.7228 21.9126 19.0584 21.7465 19.356C21.5805 19.6536 21.3411 19.9038 21.0512 20.0829C20.7613 20.262 20.4304 20.3641 20.09 20.3794C19.7495 20.3948 19.4108 20.3229 19.106 20.1705L15 18.118L9.44721 20.8944C9.16566 21.0352 8.83426 21.0352 8.55272 20.8944L3.106 18.1705C2.77384 18.0046 2.49444 17.7494 2.2991 17.4336C2.10377 17.1178 2.0002 16.7539 2 16.3825L2 5.618C2.00018 5.27722 2.08744 4.9416 2.25349 4.64401C2.41954 4.34642 2.65888 4.09619 2.94879 3.91708C3.2387 3.73796 3.56957 3.63589 3.91001 3.62056C4.25045 3.60523 4.58916 3.67714 4.894 3.82947L8.99995 5.88199L14.5528 3.10557ZM8 7.61809L4 5.61853V16.3815L8 18.3818V7.61809ZM10 18.382L14 16.382V5.61803L10 7.61803V18.382ZM16 5.61817V16.3819L20 18.3815V7.61853L16 5.61817Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconMap",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
