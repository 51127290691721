<template>
    <div
        ref="drawerPictureContainerWrapper"
        data-testid="drawer-pictures-container"
        class="w-full h-32 relative overflow-scroll scrollbar"
        :class="{
            'cursor-grabbing': isMousePressed,
            'cursor-pointer': !isMousePressed
        }"
        @mousedown="clickStart"
        @mouseup="clickStop"
        @mouseleave="mouseLeave"
        @mousemove="isDragging"
        @touchstart="clickStart"
        @touchmove="isDragging"
        @touchend="clickStop"
    >
        <div
            v-if="loadingPictures"
            class="flex select-none absolute gap-2 top-0 ml-4 md:ml-6 pr-4 md:pr-6"
        >
            <ChargemapSkeletonLoader
                v-for="index in 4"
                :key="index"
                class="h-32 w-32 flex items-stretch justify-center rounded-lg"
            />
        </div>
        <div
            v-else
            class="flex select-none absolute gap-2 top-0 ml-4 md:ml-6 pr-4 md:pr-6"
        >
            <div
                v-for="(picture, index) in pictures"
                :key="picture.id"
                class="h-32 w-32 flex items-stretch justify-center"
            >
                <img
                    :src="getPictureUrl(picture, '160x160')"
                    :alt="picture.comment"
                    class="rounded-lg"
                    draggable="false"
                    @touchend="clickImg(index)"
                    @click="clickImg(index)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ChargemapSkeletonLoader from "../../global/ChargemapSkeletonLoader.vue";
export default {
  name: "DrawerPicturesContainer",
  components: { ChargemapSkeletonLoader },
  props: {
    pictures: {
      type: Array,
      required: true
    },
    s3Url: {
      type: String,
      required: true
    },
    loadingPictures: {
      type: Boolean,
      required: true
    }
  },
  emits: ["openPicture"],
  data() {
    return {
      isMousePressed: false,
      position: { left: 0, x: 0 },
      isMouseDragging: false,
      timeout: null
    };
  },
  methods: {
    getPictureUrl(picture, size) {
      return `${this.s3Url}/charging_pool_photos/url/${size}/${picture.id}/${picture.url}`;
    },
    clickStart(e) {
      this.isMousePressed = true;
      const drawerPictureContainerWrapper = this.$refs.drawerPictureContainerWrapper;
      const x = e.clientX || e.touches?.[0]?.clientX;
      this.position = {
        left: drawerPictureContainerWrapper.scrollLeft,
        // Get the current mouse position
        x
      };
    },
    clickStop() {
      this.isMousePressed = false;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.isMouseDragging = false;
      }, 1);
    },
    clickImg(index) {
      if (!this.isMouseDragging) {
        this.$emit("openPicture", index);
      }
    },
    mouseLeave() {
      this.isMousePressed = false;
      this.isMouseDragging = false;
    },
    isDragging(e) {
      const drawerPictureContainerWrapper = this.$refs.drawerPictureContainerWrapper;
      if (!this.isMousePressed) {
        return;
      }
      const x = e.clientX || e.touches?.[0].clientX;
      const dx = x - this.position.x;
      if (x !== this.position.x) {
        this.isMouseDragging = true;
      } else {
        return;
      }
      drawerPictureContainerWrapper.scrollLeft = this.position.left - dx;
    }
  }
};
</script>

<style>
.scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0px;
  display: none;
}
</style>
