<template>
    <svg
        v-if="isSolid"
        viewBox="0 0 20 18"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.3515 0.951563C10.5765 0.726598 10.8817 0.60022 11.1999 0.60022C11.5181 0.60022 11.8233 0.726598 12.0483 0.951563L19.2483 8.15156C19.4733 8.3766 19.5996 8.68177 19.5996 8.99996C19.5996 9.31816 19.4733 9.62333 19.2483 9.84836L12.0483 17.0484C11.822 17.267 11.5189 17.3879 11.2042 17.3852C10.8896 17.3824 10.5886 17.2562 10.3661 17.0337C10.1436 16.8113 10.0174 16.5103 10.0147 16.1956C10.012 15.881 10.1329 15.5779 10.3515 15.3516L15.5031 10.2H1.5999C1.28164 10.2 0.976418 10.0735 0.751374 9.84849C0.52633 9.62345 0.399902 9.31822 0.399902 8.99996C0.399902 8.6817 0.52633 8.37648 0.751374 8.15144C0.976418 7.92639 1.28164 7.79996 1.5999 7.79996H15.5031L10.3515 2.64836C10.1265 2.42333 10.0002 2.11816 10.0002 1.79996C10.0002 1.48177 10.1265 1.1766 10.3515 0.951563V0.951563Z"
        />
    </svg>
    <svg
        v-else
        viewBox="0 0 20 16"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.2929 0.292893C11.6834 -0.0976311 12.3166 -0.0976311 12.7071 0.292893L19.7071 7.29289C20.0976 7.68342 20.0976 8.31658 19.7071 8.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071C10.9024 15.3166 10.9024 14.6834 11.2929 14.2929L16.5858 9H1C0.447715 9 0 8.55228 0 8C0 7.44772 0.447715 7 1 7H16.5858L11.2929 1.70711C10.9024 1.31658 10.9024 0.683417 11.2929 0.292893Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconArrowRight",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
