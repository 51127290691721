<template>
    <div data-testid="drawer-tab-info-access" class="flex flex-col gap-4">
        <DrawerInfoCard>
            <template #title>{{ $t('access') }}</template>
            <ChargemapDetailInfoLine v-if="isValidAccessType">
                <template #label>
                    {{ $t('access') }}
                </template>
                {{ $t('access_type.' + accessType?.toLowerCase()) }}
            </ChargemapDetailInfoLine>
            <ChargemapDetailInfoLine v-if="isParkingFree">
                <template #label>
                    {{ $t('parking') }}
                </template>
                {{ isParkingFree ? $t('free') : $t('not_free') }}
            </ChargemapDetailInfoLine>
            <ChargemapDetailInfoLine v-if="floorNumber">
                <template #label>
                    {{ $t('floor') }}
                </template>
                {{ floorNumber }}
            </ChargemapDetailInfoLine>
            <ChargemapDetailInfoLine v-if="isValidLocationType">
                <template #label>
                    {{ $t('location_type') }}
                </template>
                {{ $t('location_type_' + locationType?.toLowerCase()) }}
            </ChargemapDetailInfoLine>
            <ChargemapDetailInfoLine v-if="hasShelter">
                <template #label>
                    {{ $t('shelter') }}
                </template>
                {{ $t('sheltered_station') }}
            </ChargemapDetailInfoLine>
        </DrawerInfoCard>
        <DrawerInfoCard v-if="authenticationMethodsNames">
            <ChargemapDetailInfoLine>
                <template #label>
                    {{ $t('authentication_by') }}
                </template>
                {{ authenticationMethodsNames }}
            </ChargemapDetailInfoLine>
        </DrawerInfoCard>
    </div>
</template>

<script>
import {
  AccessType,
  AuthenticationMethod,
  LocationType
} from "../../../../../interfaces/poolDetail";
import ChargemapDetailInfoLine from "../../../global/ChargemapDetailInfoLine.vue";
import DrawerInfoCard from "./DrawerInfoCard.vue";
export default {
  name: "DrawerInfoAccess",
  components: { DrawerInfoCard, ChargemapDetailInfoLine },
  props: {
    accessType: {
      type: String,
      default: void 0
    },
    isParkingFree: {
      type: Boolean,
      default: void 0
    },
    floorNumber: {
      type: Number,
      default: void 0
    },
    locationType: {
      type: String,
      default: void 0
    },
    hasShelter: {
      type: Boolean,
      default: void 0
    },
    authenticationMethods: {
      type: Array,
      default: void 0
    }
  },
  computed: {
    isValidLocationType() {
      return this.locationType ? Object.values(LocationType).includes(
        this.locationType.toUpperCase()
      ) : false;
    },
    isValidAccessType() {
      return this.accessType ? Object.values(AccessType).includes(
        this.accessType.toUpperCase()
      ) : false;
    },
    authenticationMethodsNames() {
      if (this.authenticationMethods?.length === 0) {
        return "";
      }
      return this.authenticationMethods?.filter(
        (type) => Object.values(AuthenticationMethod).includes(
          type.toUpperCase()
        )
      )?.map(
        (type) => this.$t(`authentication_method.${type.toLowerCase()}`)
      ).join(", ");
    }
  }
};
</script>
