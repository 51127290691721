<template>
    <svg
        v-if="isSolid"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.80002 4.79999C4.1635 4.79999 3.55306 5.05284 3.10297 5.50293C2.65288 5.95302 2.40002 6.56347 2.40002 7.19999V8.39999H21.6V7.19999C21.6 6.56347 21.3472 5.95302 20.8971 5.50293C20.447 5.05284 19.8365 4.79999 19.2 4.79999H4.80002Z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.6 10.8H2.40002V16.8C2.40002 17.4365 2.65288 18.047 3.10297 18.497C3.55306 18.9471 4.1635 19.2 4.80002 19.2H19.2C19.8365 19.2 20.447 18.9471 20.8971 18.497C21.3472 18.047 21.6 17.4365 21.6 16.8V10.8ZM4.80002 15.6C4.80002 15.2817 4.92645 14.9765 5.1515 14.7515C5.37654 14.5264 5.68176 14.4 6.00002 14.4H7.20002C7.51828 14.4 7.82351 14.5264 8.04855 14.7515C8.2736 14.9765 8.40002 15.2817 8.40002 15.6C8.40002 15.9182 8.2736 16.2235 8.04855 16.4485C7.82351 16.6736 7.51828 16.8 7.20002 16.8H6.00002C5.68176 16.8 5.37654 16.6736 5.1515 16.4485C4.92645 16.2235 4.80002 15.9182 4.80002 15.6ZM10.8 14.4C10.4818 14.4 10.1765 14.5264 9.9515 14.7515C9.72645 14.9765 9.60002 15.2817 9.60002 15.6C9.60002 15.9182 9.72645 16.2235 9.9515 16.4485C10.1765 16.6736 10.4818 16.8 10.8 16.8H12C12.3183 16.8 12.6235 16.6736 12.8486 16.4485C13.0736 16.2235 13.2 15.9182 13.2 15.6C13.2 15.2817 13.0736 14.9765 12.8486 14.7515C12.6235 14.5264 12.3183 14.4 12 14.4H10.8Z"
        />
    </svg>

    <svg
        v-else
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.17157 5.17157C3.92172 4.42143 4.93913 4 6 4H18C19.0609 4 20.0783 4.42143 20.8284 5.17157C21.5786 5.92172 22 6.93913 22 8V16C22 17.0609 21.5786 18.0783 20.8284 18.8284C20.0783 19.5786 19.0609 20 18 20H6C4.93913 20 3.92172 19.5786 3.17157 18.8284C2.42143 18.0783 2 17.0609 2 16V8C2 6.93913 2.42143 5.92172 3.17157 5.17157ZM4 11V16C4 16.5304 4.21071 17.0391 4.58579 17.4142C4.96086 17.7893 5.46957 18 6 18H18C18.5304 18 19.0391 17.7893 19.4142 17.4142C19.7893 17.0391 20 16.5304 20 16V11H4ZM20 9H4V8C4 7.46957 4.21071 6.96086 4.58579 6.58579C4.96086 6.21071 5.46957 6 6 6H18C18.5304 6 19.0391 6.21071 19.4142 6.58579C19.7893 6.96086 20 7.46957 20 8V9ZM6 15C6 14.4477 6.44772 14 7 14H8C8.55228 14 9 14.4477 9 15C9 15.5523 8.55228 16 8 16H7C6.44772 16 6 15.5523 6 15ZM11 15C11 14.4477 11.4477 14 12 14H13C13.5523 14 14 14.4477 14 15C14 15.5523 13.5523 16 13 16H12C11.4477 16 11 15.5523 11 15Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconCreditCard",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
