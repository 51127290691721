<template>
    <div
        v-if="loadingInfo"
        class="h-full w-full shrink flex-col justify-end items-start gap-2 inline-flex px-2"
        data-testid="drawer-title"
    >
        <ChargemapSkeletonLoader class="w-full h-8" />
        <ChargemapSkeletonLoader class="w-full h-6" />
    </div>
    <div
        v-else
        class="h-full shrink flex-col justify-end items-start gap-2 inline-flex"
        data-testid="drawer-title"
    >
        <a
            class="text-slate-800 text-xl font-bold"
            :href="chargemapPoolDetailsUrl"
            target="_blank"
            rel="noopener noreferrer"
        >
            {{ stationName }}
        </a>
        <span class="self-stretch text-slate-600 font-normal">
            {{ stationAddress }}
        </span>
    </div>
</template>

<script>
import ChargemapSkeletonLoader from "../../global/ChargemapSkeletonLoader.vue";
export default {
  name: "DrawerTitle",
  components: { ChargemapSkeletonLoader },
  props: {
    pool: {
      type: Object,
      default: () => {
      }
    },
    loadingInfo: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    chargemapPoolDetailsUrl() {
      return `https://www.chargemap.com/${this.pool?.slug}.html`;
    },
    stationName() {
      return this.pool?.name || "";
    },
    stationAddress() {
      if (!this.pool) return "";
      const number = this.pool.number ? `${this.pool.number} ` : void 0;
      const streetName = this.pool.streetName ? `${this.pool.streetName}` : void 0;
      const postalCode = this.pool.postalCode ? `${this.pool.postalCode} ` : void 0;
      const city = this.pool.city ? `${this.pool.city}` : void 0;
      if (!postalCode && !city) {
        return `${number || ""}${streetName || ""}`;
      }
      if (!streetName && !city && !postalCode) {
        return "";
      }
      return `${number || ""}${streetName || ""} - ${postalCode || ""}${city || ""}`;
    }
  }
};
</script>
