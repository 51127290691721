<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        class="fill-current"
    >
        <path
            d="M14.9015 5.85421C14.4125 6.82517 13.229 7.21589 12.2581 6.72692C11.2871 6.23795 10.8964 5.05444 11.3854 4.08348C11.8743 3.11253 13.0578 2.7218 14.0288 3.21078C14.9998 3.69975 15.3905 4.88326 14.9015 5.85421Z"
        />
        <path
            d="M4.125 11.5129C4.125 10.788 4.71263 10.2004 5.4375 10.2004C6.16237 10.2004 6.75 10.788 6.75 11.5129V20.8879C6.75 21.6128 6.16237 22.2004 5.4375 22.2004C4.71263 22.2004 4.125 21.6128 4.125 20.8879V11.5129Z"
        />
        <path
            d="M9.23811 5.74591C9.67745 5.30657 10.3898 5.30657 10.8291 5.74591L11.6272 6.54401C12.0665 6.98335 12.0665 7.69566 11.6272 8.135L6.93458 12.8276C6.49524 13.267 5.78293 13.267 5.34359 12.8276L4.54549 12.0295C4.10615 11.5902 4.10615 10.8779 4.54549 10.4385L9.23811 5.74591Z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.0625 6.375C11.5803 6.375 12 6.79473 12 7.3125V9.87255L13.9146 11.4632C14.3128 11.7941 14.3674 12.3852 14.0366 12.7834C13.7057 13.1817 13.1146 13.2363 12.7164 12.9054L10.4634 11.0336C10.249 10.8555 10.125 10.5912 10.125 10.3125V7.3125C10.125 6.79473 10.5447 6.375 11.0625 6.375Z"
        />
        <path
            d="M19.214 15.9028C19.214 16.6959 18.571 17.3389 17.7779 17.3389C16.9848 17.3389 16.3418 16.6959 16.3418 15.9028C16.3418 15.1096 16.9848 14.4667 17.7779 14.4667C18.571 14.4667 19.214 15.1096 19.214 15.9028Z"
        />
        <path
            d="M15.316 12.8254C14.9761 12.8254 14.7005 13.101 14.7005 13.4409V15.2873H12.9981L13.2532 14.8454C13.2542 14.8438 13.2551 14.8421 13.2561 14.8404L13.3091 14.7487C13.4644 14.4796 13.3722 14.1356 13.1032 13.9803L11.3775 12.9839C11.1084 12.8286 10.7644 12.9208 10.6091 13.1898L10.5561 13.2815C10.4008 13.5506 10.4929 13.8946 10.762 14.0499L11.8094 14.6547L10.9535 16.1372C10.7464 16.496 10.8693 16.9547 11.228 17.1618L11.3148 17.2119C11.3472 17.2344 11.3816 17.2543 11.4176 17.2712L11.4191 17.2721C11.4606 17.2961 11.5048 17.3112 11.5495 17.3181C11.6045 17.3317 11.6621 17.3389 11.7213 17.3389H15.2704L15.2706 17.3372C15.2856 17.3383 15.3007 17.3389 15.316 17.3389C15.6559 17.3389 15.9315 17.0633 15.9315 16.7234V13.4409C15.9315 13.101 15.6559 12.8254 15.316 12.8254Z"
        />
        <path
            d="M10.125 18.75C10.125 18.3358 10.4608 18 10.875 18H19.5C19.9142 18 20.25 18.3358 20.25 18.75C20.25 19.1642 19.9142 19.5 19.5 19.5H10.875C10.4608 19.5 10.125 19.1642 10.125 18.75Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconBabyChanging"
};
</script>
