<template>
    <svg viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.466003 8.89073C0.466003 6.00439 0.466004 4.5635 1.03404 3.45896C1.53119 2.48476 2.33146 1.68919 3.32018 1.18625C4.43764 0.622619 5.9043 0.62262 8.83297 0.62262H33.133C36.0617 0.62262 37.5237 0.622619 38.6411 1.18625C39.6318 1.68663 40.4328 2.48291 40.9273 3.45896C41.5 4.5635 41.5 6.00439 41.5 8.89073V31.6178C41.5 34.5042 41.5 35.9451 40.9273 37.0496C40.4328 38.0257 39.6318 38.8219 38.6411 39.3223C37.5237 39.8814 36.0617 39.8814 33.133 39.8814H8.83297C5.9043 39.8814 4.43764 39.8814 3.32018 39.3223C2.33146 38.8194 1.53119 38.0238 1.03404 37.0496C0.466004 35.9451 0.466003 34.5042 0.466003 31.6178V8.89073Z"
            fill="#3B82F6"
        />
        <path
            d="M25.0733 10.5139V5.61852H22.3449V10.5139H19.6118V5.61852H16.8833V10.5139H14.1409V13.0003C14.1409 16.4548 15.6681 20.3002 19.6211 21.0184V30.0456C19.6211 30.9047 19.6211 31.5456 19.351 31.8092C19.1555 32.0047 18.5688 32.3319 16.8647 32.3319C15.533 32.3319 14.681 32.1183 14.3876 31.7274C14.0943 31.3365 14.1409 30.7547 14.1409 30.0456V25.1729C14.1409 22.8229 13.7172 20.3093 8.6793 20.3093H0.470642V23.0002H8.63739C11.431 23.0002 11.431 23.5093 11.431 25.1775V30.0456C11.431 31.041 11.431 32.3183 12.2179 33.3592C13.0746 34.491 14.5459 35.0183 16.874 35.0183C18.9692 35.0183 20.3614 34.6092 21.2554 33.7274C22.3262 32.6683 22.3635 31.1456 22.3588 30.0456V21.0184C26.3072 20.3002 27.839 16.473 27.839 13.0003V10.5139H25.0733Z"
            fill="white"
        />
    </svg>
</template>

<script>
export default {
  name: "IconChargemapBusiness"
};
</script>
