<template>
    <svg
        v-if="isWhite"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.99984 2.33341C7.23173 2.33341 5.53603 3.03579 4.28579 4.28604C3.03555 5.53628 2.33317 7.23197 2.33317 9.00008C2.33317 9.87556 2.50561 10.7425 2.84064 11.5513C3.17567 12.3601 3.66673 13.0951 4.28579 13.7141C4.90485 14.3332 5.63978 14.8242 6.44861 15.1593C7.25745 15.4943 8.12436 15.6667 8.99984 15.6667C9.87532 15.6667 10.7422 15.4943 11.5511 15.1593C12.3599 14.8242 13.0948 14.3332 13.7139 13.7141C14.3329 13.0951 14.824 12.3601 15.159 11.5513C15.4941 10.7425 15.6665 9.87556 15.6665 9.00008C15.6665 7.23197 14.9641 5.53628 13.7139 4.28604C12.4636 3.03579 10.7679 2.33341 8.99984 2.33341ZM3.10728 3.10752C4.67008 1.54472 6.7897 0.666748 8.99984 0.666748C11.21 0.666748 13.3296 1.54472 14.8924 3.10752C16.4552 4.67033 17.3332 6.78994 17.3332 9.00008C17.3332 10.0944 17.1176 11.1781 16.6988 12.1891C16.28 13.2002 15.6662 14.1188 14.8924 14.8926C14.1186 15.6665 13.1999 16.2803 12.1889 16.6991C11.1778 17.1179 10.0942 17.3334 8.99984 17.3334C7.90549 17.3334 6.82186 17.1179 5.81081 16.6991C4.79976 16.2803 3.8811 15.6665 3.10728 14.8926C2.33346 14.1188 1.71963 13.2002 1.30084 12.1891C0.882052 11.1781 0.666504 10.0944 0.666504 9.00008C0.666504 6.78994 1.54448 4.67033 3.10728 3.10752ZM6.74392 6.74416C7.06935 6.41872 7.59699 6.41872 7.92243 6.74416L8.99984 7.82157L10.0772 6.74416C10.4027 6.41872 10.9303 6.41872 11.2558 6.74416C11.5812 7.0696 11.5812 7.59723 11.2558 7.92267L10.1783 9.00008L11.2558 10.0775C11.5812 10.4029 11.5812 10.9306 11.2558 11.256C10.9303 11.5814 10.4027 11.5814 10.0772 11.256L8.99984 10.1786L7.92243 11.256C7.59699 11.5814 7.06935 11.5814 6.74392 11.256C6.41848 10.9306 6.41848 10.4029 6.74392 10.0775L7.82133 9.00008L6.74392 7.92267C6.41848 7.59723 6.41848 7.0696 6.74392 6.74416Z"
        />
    </svg>
    <svg
        v-else
        width="20"
        height="20"
        viewBox="0 0 20 20"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18V18ZM8.707 7.293C8.5184 7.11084 8.2658 7.01005 8.0036 7.01233C7.7414 7.0146 7.49059 7.11977 7.30518 7.30518C7.11977 7.49059 7.0146 7.7414 7.01233 8.0036C7.01005 8.2658 7.11084 8.5184 7.293 8.707L8.586 10L7.293 11.293C7.19749 11.3852 7.12131 11.4956 7.0689 11.6176C7.01649 11.7396 6.9889 11.8708 6.98775 12.0036C6.9866 12.1364 7.0119 12.2681 7.06218 12.391C7.11246 12.5139 7.18671 12.6255 7.2806 12.7194C7.3745 12.8133 7.48615 12.8875 7.60905 12.9378C7.73194 12.9881 7.86362 13.0134 7.9964 13.0123C8.12918 13.0111 8.2604 12.9835 8.3824 12.9311C8.50441 12.8787 8.61475 12.8025 8.707 12.707L10 11.414L11.293 12.707C11.4816 12.8892 11.7342 12.99 11.9964 12.9877C12.2586 12.9854 12.5094 12.8802 12.6948 12.6948C12.8802 12.5094 12.9854 12.2586 12.9877 11.9964C12.99 11.7342 12.8892 11.4816 12.707 11.293L11.414 10L12.707 8.707C12.8892 8.5184 12.99 8.2658 12.9877 8.0036C12.9854 7.7414 12.8802 7.49059 12.6948 7.30518C12.5094 7.11977 12.2586 7.0146 11.9964 7.01233C11.7342 7.01005 11.4816 7.11084 11.293 7.293L10 8.586L8.707 7.293Z"
        />
    </svg>
</template>
<script>
export default {
  props: {
    isWhite: {
      type: Boolean,
      default() {
        return false;
      }
    }
  }
};
</script>
