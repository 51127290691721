<template>
    <div
        class="md:pointer-events-auto transition-all duration-300 ease-in-out z-50"
        :class="
            showCloseButton
                ? 'opacity-100 h-8 w-8 pointer-events-auto select-auto'
                : 'opacity-0 h-0 w-0 overflow-hidden pointer-events-none select-none'
        "
        data-testid="drawer-close-button"
    >
        <ChargemapButton
            btn-alt="clear-full"
            class="w-full h-full"
            v-bind="$attrs"
            @click="closeDrawer"
        >
            <IconX class="w-6 h-6 text-slate-500" />
        </ChargemapButton>
    </div>
</template>

<script>
import ChargemapButton from "../../b2b/global/ChargemapButton.vue";
import IconX from "../../icons/IconX.vue";
export default {
  name: "DrawerCloseButton",
  components: {
    IconX,
    ChargemapButton
  },
  props: {
    showCloseButton: {
      type: Boolean,
      required: true
    }
  },
  emits: ["closeDrawer"],
  methods: {
    closeDrawer() {
      this.$emit("closeDrawer", false);
    }
  }
};
</script>
