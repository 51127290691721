<template>
    <svg
        v-if="isSolid"
        class="fill-current"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.6 9.99999C19.6 12.5461 18.5886 14.9879 16.7882 16.7882C14.9879 18.5886 12.5461 19.6 10 19.6C7.45395 19.6 5.01215 18.5886 3.2118 16.7882C1.41145 14.9879 0.400024 12.5461 0.400024 9.99999C0.400024 7.45392 1.41145 5.01212 3.2118 3.21177C5.01215 1.41142 7.45395 0.399994 10 0.399994C12.5461 0.399994 14.9879 1.41142 16.7882 3.21177C18.5886 5.01212 19.6 7.45392 19.6 9.99999ZM11.2 14.8C11.2 15.1183 11.0736 15.4235 10.8486 15.6485C10.6235 15.8736 10.3183 16 10 16C9.68176 16 9.37654 15.8736 9.1515 15.6485C8.92645 15.4235 8.80002 15.1183 8.80002 14.8C8.80002 14.4817 8.92645 14.1765 9.1515 13.9515C9.37654 13.7264 9.68176 13.6 10 13.6C10.3183 13.6 10.6235 13.7264 10.8486 13.9515C11.0736 14.1765 11.2 14.4817 11.2 14.8ZM10 3.99999C9.68176 3.99999 9.37654 4.12642 9.1515 4.35147C8.92645 4.57651 8.80002 4.88173 8.80002 5.19999V9.99999C8.80002 10.3183 8.92645 10.6235 9.1515 10.8485C9.37654 11.0736 9.68176 11.2 10 11.2C10.3183 11.2 10.6235 11.0736 10.8486 10.8485C11.0736 10.6235 11.2 10.3183 11.2 9.99999V5.19999C11.2 4.88173 11.0736 4.57651 10.8486 4.35147C10.6235 4.12642 10.3183 3.99999 10 3.99999Z"
        />
    </svg>
    <svg
        v-else
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 11.0506 2.20693 12.0909 2.60896 13.0615C3.011 14.0321 3.60028 14.914 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94942 18 10 18C11.0506 18 12.0909 17.7931 13.0615 17.391C14.0321 16.989 14.914 16.3997 15.6569 15.6569C16.3997 14.914 16.989 14.0321 17.391 13.0615C17.7931 12.0909 18 11.0506 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2ZM2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 11.3132 19.7413 12.6136 19.2388 13.8268C18.7362 15.0401 17.9997 16.1425 17.0711 17.0711C16.1425 17.9997 15.0401 18.7362 13.8268 19.2388C12.6136 19.7413 11.3132 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C2.00035 16.1425 1.26375 15.0401 0.761205 13.8268C0.258658 12.6136 0 11.3132 0 10C5.96046e-08 7.34784 1.05357 4.8043 2.92893 2.92893ZM10 5C10.5523 5 11 5.44772 11 6V10C11 10.5523 10.5523 11 10 11C9.44771 11 9 10.5523 9 10V6C9 5.44772 9.44771 5 10 5ZM9 14C9 13.4477 9.44771 13 10 13H10.01C10.5623 13 11.01 13.4477 11.01 14C11.01 14.5523 10.5623 15 10.01 15H10C9.44771 15 9 14.5523 9 14Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconExclamationCircle",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
