<template>
    <component
        :is="component"
        :disabled="isLoading || isDisabled"
        type="button"
        class="transition ease-in-out duration-150"
        :class="{
            loading: isLoading
        }"
        v-bind="$attrs"
    >
        <div class="inline-flex w-full items-center justify-center">
            <div v-if="isLoading" class="justify-center items-center relative">
                <div
                    class="flex flex-inline items-center opacity-0 gap-2"
                    :class="{
                        'flex-row-reverse': iconPosition === 'right'
                    }"
                >
                    <slot v-if="$slots.icon" name="icon" />
                    <slot />
                </div>
                <div
                    class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
                >
                    <IconLoader
                        class="h-5 m-auto w-5 text-gray-500 animate-spin"
                        data-testid="loader"
                    />
                </div>
            </div>
            <div
                v-else
                class="flex flex-inline items-center gap-2"
                :class="[
                    iconPosition === 'right' && 'flex-row-reverse',
                    customClasses
                ]"
            >
                <slot v-if="$slots.icon" name="icon" />
                <slot />
            </div>
        </div>
    </component>
</template>

<script>
import IconLoader from "../../icons/IconLoader.vue";
export default {
  name: "BaseButton",
  components: { IconLoader },
  props: {
    component: {
      type: String,
      default: "button",
      validator(value) {
        return ["button", "a"].includes(value);
      }
    },
    iconPosition: {
      type: String,
      default: "left",
      validator(value) {
        return ["left", "right"].includes(value);
      }
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    customClasses: {
      type: String,
      default: ""
    }
  }
};
</script>
