<template>
    <span v-if="isSolid">
        <svg
            viewBox="0 0 18 22"
            class="fill-current"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.6152 0.594952C11.9164 0.762894 12.0643 1.11463 11.9736 1.44736L10.9819 8.75003H17.25C17.5486 8.75003 17.8188 8.92721 17.9379 9.20111C18.0569 9.47502 18.0021 9.79344 17.7983 10.0118L7.29829 21.2618C7.06297 21.5139 6.68603 21.573 6.38479 21.4051C6.08355 21.2372 5.93568 20.8854 6.02643 20.5527L7.01806 13.25H0.750001C0.451353 13.25 0.181166 13.0728 0.0621408 12.7989C-0.0568848 12.525 -0.00206302 12.2066 0.20171 11.9883L10.7017 0.738287C10.937 0.486154 11.314 0.427009 11.6152 0.594952Z"
            />
        </svg>
    </span>
    <span v-else>
        <svg
            viewBox="0 0 20 22"
            class="fill-current"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.6856 0.349873C13.0801 0.540796 13.3044 0.966442 13.2387 1.39984L12.1629 8.50004H18.25C18.6482 8.50004 19.0085 8.73628 19.1672 9.10149C19.3259 9.46669 19.2528 9.89125 18.9811 10.1824L8.48106 21.4324C8.18197 21.7528 7.70904 21.8411 7.31446 21.6502C6.91987 21.4593 6.69562 21.0336 6.76129 20.6002L7.83708 13.5H1.75001C1.35181 13.5 0.99156 13.2638 0.832859 12.8986C0.674159 12.5334 0.747254 12.1088 1.01895 11.8177L11.519 0.567719C11.818 0.247263 12.291 0.158951 12.6856 0.349873ZM4.05123 11.5H9.00001C9.29138 11.5 9.56826 11.6271 9.75823 11.848C9.9482 12.069 10.0324 12.3618 9.98872 12.6498L9.22252 17.7067L15.9488 10.5H11C10.7086 10.5 10.4318 10.373 10.2418 10.152C10.0518 9.93111 9.96764 9.63831 10.0113 9.35023L10.7775 4.29333L4.05123 11.5Z"
            />
        </svg>
    </span>
</template>

<script>
export default {
  name: "IconBolt",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
