<template>
    <svg
        v-if="isSolid"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.6483 20.0484C11.4233 20.2734 11.1181 20.3997 10.7999 20.3997C10.4817 20.3997 10.1765 20.2734 9.95149 20.0484L2.75149 12.8484C2.52652 12.6234 2.40015 12.3182 2.40015 12C2.40015 11.6818 2.52652 11.3766 2.75149 11.1516L9.95149 3.9516C10.1778 3.73301 10.4809 3.61206 10.7956 3.61479C11.1102 3.61753 11.4112 3.74373 11.6337 3.96622C11.8562 4.18871 11.9824 4.48968 11.9851 4.80432C11.9878 5.11895 11.8669 5.42208 11.6483 5.6484L6.49669 10.8H20.3999C20.7181 10.8 21.0234 10.9264 21.2484 11.1515C21.4735 11.3765 21.5999 11.6817 21.5999 12C21.5999 12.3183 21.4735 12.6235 21.2484 12.8485C21.0234 13.0736 20.7181 13.2 20.3999 13.2H6.49669L11.6483 18.3516C11.8733 18.5766 11.9996 18.8818 11.9996 19.2C11.9996 19.5182 11.8733 19.8234 11.6483 20.0484Z"
        />
    </svg>

    <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.7071 4.29289C11.0976 4.68342 11.0976 5.31658 10.7071 5.70711L5.41421 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H5.41421L10.7071 18.2929C11.0976 18.6834 11.0976 19.3166 10.7071 19.7071C10.3166 20.0976 9.68342 20.0976 9.29289 19.7071L2.29289 12.7071C1.90237 12.3166 1.90237 11.6834 2.29289 11.2929L9.29289 4.29289C9.68342 3.90237 10.3166 3.90237 10.7071 4.29289Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconArrowRight",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
