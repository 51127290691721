<template>
    <div class="select space-y-1" data-testid="select">
        <label
            v-if="$slots.default"
            id="listbox-label"
            class="block text-sm leading-5 font-medium text-gray-700"
            @click="toggleOpen"
        >
            <slot />
        </label>
        <div class="relative">
            <span class="inline-block w-full rounded-md">
                <button
                    type="button"
                    :disabled="isDisabled"
                    aria-haspopup="listbox"
                    aria-expanded="true"
                    aria-labelledby="listbox-label"
                    class="cursor-default border-0 relative w-full rounded-md bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    :class="{ 'bg-gray-200': isDisabled }"
                    @click="toggleOpen"
                >
                    <span class="block truncate text-globalDark">
                        {{ displayValue }}
                    </span>
                    <span
                        class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                    >
                        <svg
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M10 0.5H0L5 5.5L10 0.5Z" fill="#495057" />
                        </svg>
                    </span>
                </button>
            </span>

            <transition
                enter-active-class="transition ease-out duration-100 transform"
                enter-class="opacity-0 -translate-y-2"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-75 transform"
                leave-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 -translate-y-2"
            >
                <div
                    v-show="isOpen"
                    class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10"
                >
                    <ul
                        tabindex="-1"
                        role="listbox"
                        aria-labelledby="listbox-label"
                        aria-activedescendant="listbox-item-3"
                        class="max-h-60 rounded-md py-1 text-base text-globalDark leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                    >
                        <li
                            v-for="opt in displayOptions"
                            id="listbox-item-0"
                            :key="opt.id"
                            role="option"
                            class="cursor-pointer select-none relative py-2 pl-8 pr-4 hover:bg-chargemap-500 hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-chargemap-500"
                            :class="[
                                opt.id === value
                                    ? 'text-primary'
                                    : 'text-globalDark'
                            ]"
                            @click="onSelect(opt.id)"
                        >
                            <span
                                class="block truncate"
                                :class="[
                                    opt.id === value
                                        ? 'font-semibold'
                                        : 'font-normal'
                                ]"
                            >
                                {{ opt.value }}
                            </span>
                            <span
                                v-if="opt.id === value"
                                class="absolute inset-y-0 left-0 flex items-center pl-2"
                                :class="[
                                    opt.id === value
                                        ? 'text-primary'
                                        : 'text-globalDark'
                                ]"
                            >
                                <svg
                                    class="h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </span>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Select',
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        options: {
            type: Array, // string, number or object { key, value, ... }
            required: true
        },
        optionsIdKey: {
            type: String,
            default: ''
        },
        optionsValueKey: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    emits: ['input'],
    data: () => ({
        isOpen: false
    }),
    computed: {
        displayValue() {
            const _options = this.displayOptions
            const currentOpt = _options.find((i) => i.id === this.value)
            if (currentOpt) {
                return currentOpt.value
            }
            return this.placeholder
        },
        displayOptions() {
            const listOptions = []
            if (this.options.length > 0) {
                const checkType = this.options[0]
                if (
                    checkType !== null &&
                    typeof checkType === 'object' &&
                    !Array.isArray(checkType)
                ) {
                    if (!!this.optionsIdKey && !!this.optionsValueKey) {
                        this.options.forEach((option) => {
                            const optId = option[this.optionsIdKey]
                            const optValue = option[this.optionsValueKey]
                            listOptions.push({ id: optId, value: optValue })
                        })
                    } else {
                        this.options.forEach((option) => {
                            const optId = option[Object.keys(option)[0]]
                            const optValue = option[Object.keys(option)[1]]
                            listOptions.push({ id: optId, value: optValue })
                        })
                    }
                } else if (
                    checkType !== null &&
                    (typeof checkType === 'string' ||
                        typeof checkType === 'number')
                ) {
                    this.options.forEach((option) => {
                        listOptions.push({ id: option, value: option })
                    })
                }
            }
            return listOptions
        }
    },
    methods: {
        onSelect(value) {
            this.isOpen = false
            this.$emit('input', value)
        },
        toggleOpen() {
            if (!this.isDisabled) {
                this.isOpen = !this.isOpen
            }
        }
    }
}
</script>
<style scoped>
.select label,
.select button {
  cursor: pointer;
}
</style>
