<template>
    <div
        class="flex flex-col gap-2 ml-2"
        data-testid="checkin-in-progress-content"
    >
        <div class="flex gap-1 items-center">
            <div
                class="font-bold leading-4 text-fifteen-exploitation text-sky-600"
            >
                {{ endDate ? displayRemainingTime : displayElapsedTime }}
            </div>
        </div>
        <div
            class="flex flex-col ml-8.5 gap-1 font-normal leading-4 text-fifteen-exploitation text-gray-700"
        >
            <div v-if="hasVehicle">
                <span class="text-gray-500">{{ getVehicleBrandName }}</span>
                {{ getVehicleModelName }}
            </div>
            <div v-if="hasConnectorType">
                <span class="text-gray-500">{{ $t('connector') }}</span>
                {{ connectorTypeTranslation }}
            </div>
        </div>
    </div>
</template>

<script>
import { DateTime, Duration } from "luxon";
export default {
  name: "CheckinInProgressContent",
  props: {
    startDate: {
      type: Object,
      required: true
    },
    endDate: {
      type: Object,
      default: void 0
    },
    vehicle: {
      type: Object,
      default() {
        return {};
      }
    },
    connector: {
      type: Object,
      default() {
        return {};
      }
    },
    connectorTypeTranslation: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      now: DateTime.now(),
      tick: void 0
    };
  },
  computed: {
    remaining() {
      return this.endDate?.diff(this.now).toObject() || {};
    },
    displayRemainingTime() {
      const remainingTime = Duration.fromObject(this.remaining).toFormat(
        "mm:ss"
      );
      if (this.remaining.milliseconds && this.remaining.milliseconds < 6e4) {
        return `${this.$t("remaining.seconds", { str: remainingTime })}`;
      }
      return `${this.$t("remaining.minutes", { str: remainingTime })}`;
    },
    displayElapsedTime() {
      const counter = this.now.diff(this.startDate).toObject();
      return Duration.fromObject(counter).toFormat("hh:mm:ss");
    },
    isFinished() {
      return this.endDate ? this.now >= this.endDate : false;
    },
    hasConnectorType() {
      return this.connector && this.connector.type;
    },
    hasVehicle() {
      return this.vehicle && this.vehicle.brandName && this.vehicle.modelName;
    },
    getVehicleModelName() {
      return this.vehicle.modelName;
    },
    getVehicleBrandName() {
      return this.vehicle.brandName;
    }
  },
  watch: {
    now() {
      if (this.isFinished) {
        clearInterval(this.tick);
      }
    }
  },
  mounted() {
    this.tick = setInterval(() => {
      this.now = DateTime.now();
    }, 1e3);
  }
};
</script>
