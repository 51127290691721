<template>
    <div class="flex flex-row gap-4 items-center">
        <IconSpeaker is-solid class="w-5 h-5" />
        <div
            class="text-gray-800 font-medium text-fifteen-exploitation leading-4"
        >
            <div v-if="isFailedCharge">
                <span v-if="isAnonymous">
                    {{ $t('anonymous.charge.failed') }}
                </span>
                <span v-else>
                    {{ username }}
                    <span class="text-gray-500 font-normal">
                        {{ $t('charge.failed') }}
                    </span>
                </span>
                <span>&nbsp;</span>
                <span class="text-gray-500 font-normal">{{ displayDate }}</span>
            </div>

            <div v-else>
                <span v-if="isAnonymous" class="text-gray-500 font-normal">
                    {{ $t('anonymous.has_alerted') }}
                </span>
                <span v-else>
                    {{ username }}
                    <span class="text-gray-500 font-normal">
                        {{ $t('has_alerted') }}
                    </span>
                </span>
                <span>&nbsp;</span>
                <span class="text-gray-500 font-normal">{{ displayDate }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import IconSpeaker from "../../../icons/IconSpeaker.vue";
export default {
  name: "ReportHeader",
  components: {
    IconSpeaker
  },
  props: {
    creationDate: {
      type: Object,
      default: DateTime.now()
    },
    isAnonymous: {
      type: Boolean,
      required: true
    },
    isFailedCharge: {
      type: Boolean,
      required: true
    },
    username: {
      type: String,
      default: ""
    },
    locale: {
      type: String,
      required: true
    }
  },
  computed: {
    displayDate() {
      const now = DateTime.now();
      if (now.diff(this.creationDate, "hours").hours >= 24) {
        return this.creationDate.toRelative({ locale: this.locale });
      }
      return this.creationDate.toRelativeCalendar({ locale: this.locale });
    }
  }
};
</script>
