<template>
    <nav
        v-if="tabs && tabs.length > 0 && currentTab"
        class="tab__list"
        :class="[
            fillSpace ? 'mx-auto' : '',
            isB2B && '!gap-3 md:!px-0 md:border-b border-slate-200',
            type === 'underline'
                ? 'md:gap-0 md:justify-start md:w-full md:px-3'
                : 'p-1 bg-slate-50 rounded-[10px] justify-start items-center gap-1 inline-flex'
        ]"
        data-testid="chargemap-tabs"
        aria-label="Tabs"
    >
        <a
            v-for="tab in tabs"
            :ref="tab.id"
            :key="tab.id"
            disabled
            class="item"
            :class="[
                {
                    'underline-item': type === 'underline',
                    'rounded-item': type === 'rounded',
                    'block-item': type === 'block'
                },
                isSelectedTab(tab.id) ? 'selected' : 'not-selected',
                isB2B && 'isB2B',
                fillSpace && 'md:flex-1'
            ]"
            :aria-current="isSelectedTab(tab.id) ? 'page' : undefined"
            @click="changeTab(tab.id)"
        >
            {{ tab.text ? tab.text : tab.id }}
            <span
                v-if="tab.indicator"
                class="inline-flex justify-center items-center h-5 w-8 ml-2 text-xs rounded-full"
                :class="[
                    isSelectedTab(tab.id)
                        ? indicatorColorClass
                        : 'bg-gray-200 text-gray-900'
                ]"
            >
                {{ tab.indicator }}
            </span>
            <div v-if="tab.icon && tab.icon.id">
                <component
                    :is="tab.icon.id"
                    class="ml-1 -mt-0.5"
                    :class="[
                        tab.icon.color ? `text-${tab.icon.color}-400` : ''
                    ]"
                />
            </div>
        </a>
    </nav>
</template>

<script>
export default {
  name: "ChargemapTabs",
  props: {
    tabs: {
      type: Array,
      required: true
    },
    indicators: {
      type: Array,
      default: () => []
    },
    colorIndicator: {
      type: String,
      default: "primary"
    },
    selectedTab: {
      type: String,
      default: null
    },
    fillSpace: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "underline"
    },
    isB2B: {
      type: Boolean,
      default: false
    }
  },
  emits: ["setSelectedTab"],
  data() {
    return {
      currentTab: null
    };
  },
  computed: {
    indicatorColorClass() {
      return `bg-${this.colorIndicator}-200 text-${this.colorIndicator}-900`;
    }
  },
  watch: {
    selectedTab: {
      handler(val) {
        this.changeTab(val);
      },
      immediate: true
    },
    currentTab(val) {
      this.$emit("setSelectedTab", val);
    }
  },
  methods: {
    isSelectedTab(tabId) {
      return this.currentTab === tabId;
    },
    changeTab(idTab) {
      if (!this.tabs || this.tabs.length === 0) {
        return;
      }
      const hasTabAndExist = idTab && this.tabs.some((element) => element.id === idTab);
      if (hasTabAndExist) {
        this.currentTab = idTab;
      } else {
        this.currentTab = this.tabs[0].id;
      }
    }
  }
};
</script>

<style scoped>
.tab__list {
  @apply flex
            flex-row
            items-end
            flex-nowrap
            
            z-20;
  /* Horizontal scroll snap */
  scroll-snap-type: inline mandatory;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
}
.tab__list::-webkit-scrollbar {
  /* Chrome */
  display: none;
}
.tab__list > a {
  scroll-snap-align: center;
}
.tab__list > .item {
  @apply flex items-center justify-center font-semibold text-sm cursor-pointer whitespace-nowrap transition-all duration-300 transform-gpu ease-in-out;
}
.tab__list > .item.underline-item {
  @apply rounded-3xl px-4 py-2 md:pb-3 md:pt-2 hover:text-slate-900 md:disabled:text-slate-400 md:hover:border-primary-500 md:rounded-none md:border-b-[3px];
}
.tab__list > .item.underline-item.selected {
  @apply border-primary-500 bg-sky-100 md:bg-transparent text-primary-800 md:text-slate-800;
}
.tab__list > .item.underline-item.not-selected {
  @apply text-slate-600 border-transparent;
}
.tab__list > .item.underline-item.isB2B {
  @apply px-4 py-2 md:pt-0 md:px-1 md:pb-2;
}
.tab__list > .item.underline-item.isB2B.not-selected {
  @apply text-slate-500;
}
.tab__list > .item.rounded-item {
  @apply rounded-3xl px-4 py-2 hover:text-slate-900;
}
.tab__list > .item.rounded-item.selected {
  @apply border-primary-500 bg-sky-100 text-primary-800;
}
.tab__list > .item.rounded-item.not-selected {
  @apply text-slate-600 border-transparent;
}
.tab__list > .item.rounded-item.isB2B {
  @apply px-4 py-2;
}
.tab__list > .item.rounded-item.isB2B.not-selected {
  @apply text-slate-500;
}
.tab__list > .item.block-item {
  @apply px-3 py-2 rounded-md justify-center items-center gap-2 inline-flex;
}
.tab__list > .item.block-item.selected {
  @apply bg-white shadow text-slate-900;
}
.tab__list > .item.block-item.not-selected {
  @apply bg-transparent text-slate-500 hover:bg-white hover:shadow hover:text-slate-900;
}
</style>
