<template>
    <svg
        v-if="isSolid"
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.80002 3.59998C4.1635 3.59998 3.55306 3.85283 3.10297 4.30292C2.65288 4.75301 2.40002 5.36346 2.40002 5.99998C2.40002 6.6365 2.65288 7.24694 3.10297 7.69703C3.55306 8.14712 4.1635 8.39998 4.80002 8.39998H19.2C19.8365 8.39998 20.447 8.14712 20.8971 7.69703C21.3472 7.24694 21.6 6.6365 21.6 5.99998C21.6 5.36346 21.3472 4.75301 20.8971 4.30292C20.447 3.85283 19.8365 3.59998 19.2 3.59998H4.80002Z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.59998 9.59998H20.4V18C20.4 18.6365 20.1471 19.2469 19.697 19.697C19.2469 20.1471 18.6365 20.4 18 20.4H5.99998C5.36346 20.4 4.75301 20.1471 4.30292 19.697C3.85283 19.2469 3.59998 18.6365 3.59998 18V9.59998ZM9.59998 13.2C9.59998 12.8817 9.7264 12.5765 9.95145 12.3514C10.1765 12.1264 10.4817 12 10.8 12H13.2C13.5182 12 13.8235 12.1264 14.0485 12.3514C14.2735 12.5765 14.4 12.8817 14.4 13.2C14.4 13.5182 14.2735 13.8235 14.0485 14.0485C13.8235 14.2735 13.5182 14.4 13.2 14.4H10.8C10.4817 14.4 10.1765 14.2735 9.95145 14.0485C9.7264 13.8235 9.59998 13.5182 9.59998 13.2Z"
        />
    </svg>

    <svg
        v-else
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5 5C4.73478 5 4.48043 5.10536 4.29289 5.29289C4.10536 5.48043 4 5.73478 4 6C4 6.26522 4.10536 6.51957 4.29289 6.70711C4.48043 6.89464 4.73478 7 5 7H19C19.2652 7 19.5196 6.89464 19.7071 6.70711C19.8946 6.51957 20 6.26522 20 6C20 5.73478 19.8946 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5H5ZM2.87868 3.87868C3.44129 3.31607 4.20435 3 5 3H19C19.7957 3 20.5587 3.31607 21.1213 3.87868C21.6839 4.44129 22 5.20435 22 6C22 6.79565 21.6839 7.55871 21.1213 8.12132C20.8019 8.44073 20.4179 8.68067 20 8.82843V18C20 18.7957 19.6839 19.5587 19.1213 20.1213C18.5587 20.6839 17.7957 21 17 21H7C6.20435 21 5.44129 20.6839 4.87868 20.1213C4.31607 19.5587 4 18.7956 4 18V8.82843C3.5821 8.68067 3.19808 8.44073 2.87868 8.12132C2.31607 7.55871 2 6.79565 2 6C2 5.20435 2.31607 4.44129 2.87868 3.87868ZM6 9V18C6 18.2652 6.10536 18.5196 6.29289 18.7071C6.48043 18.8946 6.73478 19 7 19H17C17.2652 19 17.5196 18.8946 17.7071 18.7071C17.8946 18.5196 18 18.2652 18 18V9H6ZM9 12C9 11.4477 9.44772 11 10 11H14C14.5523 11 15 11.4477 15 12C15 12.5523 14.5523 13 14 13H10C9.44772 13 9 12.5523 9 12Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconArchive",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
