<template>
    <svg
        v-if="isSolid"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current"
        viewBox="0 0 24 24"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 3.63281L14.625 2.8125L11.625 1.875V2.625V3.75V6H10.875V6.84974C9.70224 7.05962 8.6137 7.5947 7.75736 8.39752C6.63214 9.45242 6 10.8832 6 12.375L18 12.375C18 10.8832 17.3679 9.45242 16.2426 8.39752C15.2957 7.50974 14.0647 6.94936 12.75 6.7941V6H12V3.63281ZM5.0625 13.125C4.75184 13.125 4.5 13.3768 4.5 13.6875C4.5 13.9982 4.75184 14.25 5.0625 14.25H6.75V19.5H8.25V14.25H9.75V19.5H11.25V14.25H12.75V19.5H14.25V14.25H15.75V19.5H17.25V14.25H18.9375C19.2482 14.25 19.5 13.9982 19.5 13.6875C19.5 13.3768 19.2482 13.125 18.9375 13.125H5.0625ZM3.75 22.125C3.75 21.0895 4.58947 20.25 5.625 20.25H18.375C19.4105 20.25 20.25 21.0895 20.25 22.125H3.75Z"
        />
    </svg>
    <svg
        v-else
        fill="none"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 2C11.4477 2 11 2.44772 11 3V4.22876C10.5821 4.42577 10.1981 4.7457 9.87868 5.17157C9.69167 5.42092 9.53189 5.69981 9.40192 6C9.29435 6.24842 9.2072 6.51143 9.14189 6.78437C8.36999 7.22826 7.65972 7.85244 7.05025 8.63604C6.11061 9.84415 5.46569 11.3604 5.175 13L4 13C3.44771 13 3 13.4477 3 14C3 14.5523 3.44772 15 4 15L5 15V20C3.89543 20 3 20.8954 3 22H21C21 20.8954 20.1046 20 19 20V15H20C20.5523 15 21 14.5523 21 14C21 13.4477 20.5523 13 20 13H18.825C18.5343 11.3604 17.8894 9.84415 16.9497 8.63604C16.3403 7.85244 15.63 7.22826 14.8581 6.78438C14.7928 6.51143 14.7056 6.24842 14.5981 6C14.4681 5.69981 14.3083 5.42092 14.1213 5.17157C13.8019 4.7457 13.4179 4.42577 13 4.22876V3C13 2.44772 12.5523 2 12 2ZM12 8C10.8594 8 9.62784 8.57964 8.62896 9.86392C7.96952 10.7118 7.47571 11.7939 7.21306 13L16.7869 13C16.5243 11.7939 16.0305 10.7118 15.371 9.86392C14.3722 8.57964 13.1406 8 12 8ZM7 20L7 15L9 15V20H7ZM13 20V15H11V20H13ZM17 15V20H15V15H17Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconTouristAttraction",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
