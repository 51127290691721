<template>
    <svg
        v-if="isSolid"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            d="M11.25 4.53286C9.73455 3.56279 7.93246 3 6 3C4.86178 3 3.76756 3.19535 2.75007 3.55499C2.45037 3.66091 2.25 3.94425 2.25 4.26212V18.5121C2.25 18.7556 2.36818 18.9839 2.56696 19.1245C2.76574 19.265 3.02039 19.3004 3.24993 19.2192C4.10911 18.9156 5.03441 18.75 6 18.75C7.99502 18.75 9.82325 19.4573 11.25 20.6357V4.53286Z"
        />
        <path
            d="M12.75 20.6357C14.1768 19.4573 16.005 18.75 18 18.75C18.9656 18.75 19.8909 18.9156 20.7501 19.2192C20.9796 19.3004 21.2343 19.265 21.433 19.1245C21.6318 18.9839 21.75 18.7556 21.75 18.5121V4.26212C21.75 3.94425 21.5496 3.66091 21.2499 3.55499C20.2324 3.19535 19.1382 3 18 3C16.0675 3 14.2655 3.56279 12.75 4.53286V20.6357Z"
        />
    </svg>
    <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 5.00178V17.2002C4.64677 17.0689 5.31571 17 6 17C7.82083 17 9.52904 17.4873 11 18.3381V6.50457C9.63002 5.40613 7.89265 4.75 6 4.75C5.3084 4.75 4.63843 4.83753 4 5.00178ZM13 6.50457V18.3381C14.471 17.4873 16.1792 17 18 17C18.6843 17 19.3532 17.0689 20 17.2002V5.00178C19.3616 4.83753 18.6916 4.75 18 4.75C16.1073 4.75 14.37 5.40613 13 6.50457ZM12 4.74936C10.3289 3.49441 8.25047 2.75 6 2.75C4.833 2.75 3.71063 2.95032 2.66675 3.31928C2.26716 3.46051 2 3.8383 2 4.26212V18.5121C2 18.8367 2.15757 19.1412 2.42261 19.3286C2.68765 19.516 3.02718 19.5631 3.33325 19.455C4.16604 19.1606 5.06318 19 6 19C8.04958 19 9.91707 19.7694 11.3331 21.0368C11.7128 21.3766 12.2872 21.3766 12.6669 21.0368C14.0829 19.7694 15.9504 19 18 19C18.9368 19 19.834 19.1606 20.6668 19.455C20.9728 19.5631 21.3123 19.516 21.5774 19.3286C21.8424 19.1412 22 18.8367 22 18.5121V4.26212C22 3.8383 21.7328 3.46051 21.3332 3.31928C20.2894 2.95032 19.167 2.75 18 2.75C15.7495 2.75 13.6711 3.49441 12 4.74936Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconBookOpen",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
