<template>
    <svg
        v-if="isSolid"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            d="M16.3032 4.30319C16.5246 4.07396 16.7894 3.89113 17.0822 3.76534C17.375 3.63956 17.6899 3.57336 18.0086 3.57059C18.3273 3.56782 18.6433 3.62854 18.9383 3.74922C19.2332 3.86989 19.5012 4.0481 19.7265 4.27344C19.9519 4.49878 20.1301 4.76675 20.2507 5.0617C20.3714 5.35665 20.4321 5.67268 20.4294 5.99135C20.4266 6.31002 20.3604 6.62495 20.2346 6.91776C20.1088 7.21057 19.926 7.47539 19.6968 7.69679L18.7452 8.64839L15.3516 5.25479L16.3032 4.30319ZM13.6548 6.95159L3.59998 17.0064V20.4H6.99358L17.0496 10.3452L13.6548 6.95159Z"
        />
    </svg>
    <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.5 3.99969C18.1021 3.99969 17.7205 4.15776 17.4391 4.43913L16.6462 5.23202L18.768 7.35381L19.5609 6.56092C19.8423 6.27955 20.0003 5.89794 20.0003 5.50002C20.0003 5.10211 19.8423 4.7205 19.5609 4.43913C19.2795 4.15776 18.8979 3.99969 18.5 3.99969ZM20.9751 7.97513C21.6315 7.31869 22.0003 6.42837 22.0003 5.50002C22.0003 4.57168 21.6315 3.68136 20.9751 3.02492C20.3187 2.36848 19.4283 1.99969 18.5 1.99969C17.5717 1.99969 16.6813 2.36848 16.0249 3.02492L14.5254 4.5244C14.5252 4.52457 14.5256 4.52422 14.5254 4.5244C14.5252 4.52457 14.5245 4.52526 14.5244 4.52544L2.29289 16.7569C2.10536 16.9445 2 17.1988 2 17.464V21.036C2 21.5883 2.44772 22.036 3 22.036H6.5C6.76522 22.036 7.01957 21.9307 7.20711 21.7431L20.9751 7.97513ZM17.3538 8.76802L15.232 6.64624L4 17.8782V20.036H6.08579L17.3538 8.76802Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconPencil",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
