<!-- eslint-disable vue/no-v-model-argument -->
<template>
    <div class="w-full h-full relative" data-testid="chargemap-map">
        <LMap
            v-model:zoom="zoom"
            v-model:bounds="bounds"
            v-model:center="center"
            :min-zoom="minZoom"
            :max-zoom="maxZoom"
            :use-global-leaflet="false"
            class="z-10"
            :options="{ zoomControl: false }"
            data-testid="map-component"
            @update:center="centerUpdated"
            @update:bounds="boundsUpdated"
            @update:zoom="zoomUpdated"
            @click="isMapKeyOpen = false"
        >
            <LTileLayer :url="tilesUrl" />
            <LControl v-if="!hideControls" position="bottomright">
                <MapZoom @zoom-in="zoomIn" @zoom-out="zoomOut" />
                <MapKey
                    :keys="keys"
                    :title="keyTitle"
                    :is-open="isMapKeyOpen"
                    @updateIsMapKeyOpen="updateIsMapKeyOpen"
                />
            </LControl>
            <LCircleMarker
                v-if="circleMarker.center"
                :lat-lng="circleMarker.center"
                :radius="circleMarker.radius"
                :fill-color="circleMarker.color"
                :fill-opacity="0.8"
                :stroke="false"
            />
            <LMarker
                v-for="marker in markerList"
                v-show="markerList"
                :key="marker.id"
                :lat-lng="marker.latLng"
                @click="markerClicked(marker)"
            >
                <LIcon
                    v-if="!markerIsCluster(marker)"
                    :icon-url="marker.icon"
                    :icon-size="[40, 40]"
                />
                <LIcon v-else>
                    <div class="h-8 w-8">
                        <img
                            :src="marker.icon"
                            class="-m-4 w-8 h-8"
                            alt="Cluster"
                        />
                        <div class="absolute flex -m-4 w-8 h-8">
                            <span class="m-auto text-white text-base">
                                {{ marker.count }}
                            </span>
                        </div>
                    </div>
                </LIcon>
            </LMarker>
        </LMap>
    </div>
</template>

<script>
import {
  LCircleMarker,
  LControl,
  LIcon,
  LMap,
  LMarker,
  LTileLayer
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
import MapKey from "../map/MapKey.vue";
import MapZoom from "../map/MapZoom.vue";
export default {
  name: "ChargemapMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    MapKey,
    LControl,
    MapZoom,
    LCircleMarker
  },
  props: {
    tilesUrl: {
      type: String,
      default: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    },
    markerList: {
      type: Array,
      required: true
    },
    defaultSelectedMarker: {
      type: Object,
      default: void 0
    },
    /** If this isn't set, we use Geolocalisation to center the map or fallback on Strasbourg */
    mapCenter: {
      type: Object,
      default: void 0
    },
    mapZoom: {
      type: Number,
      default: 9
    },
    centerOnClickedMarker: {
      type: Boolean,
      default: true
    },
    drawCircleAroundMarker: {
      type: Boolean,
      default: true
    },
    hideControls: {
      type: Boolean,
      default: false
    },
    minZoom: {
      type: Number,
      default: 0
    },
    maxZoom: {
      type: Number,
      default: 18
    },
    keys: {
      type: Array,
      default: () => []
    },
    keyTitle: {
      type: String,
      default: ""
    }
  },
  emits: ["markerClicked", "centerUpdated", "boundsUpdated", "zoomUpdated"],
  data() {
    return {
      zoom: 1,
      center: [48.5734053, 7.7521113],
      bounds: void 0,
      circleMarker: {
        center: void 0,
        radius: 28,
        color: "#B1C3D4"
      },
      selectedMarker: void 0,
      localizationCookie: void 0,
      memoizedBounds: void 0,
      isMapKeyOpen: false,
      loading: true
    };
  },
  watch: {
    selectedMarker() {
      if (this.centerOnClickedMarker) {
        if (!this.selectedMarker) {
          return;
        }
        this.center = this.selectedMarker.latLng;
        if (this.selectedMarker && this.markerIsCluster(this.selectedMarker) && this.zoom < this.maxZoom) {
          setTimeout(() => {
            this.zoom += 1;
          }, 250);
        }
      }
      if (this.selectedMarker && this.drawCircleAroundMarker && !this.markerIsCluster(this.selectedMarker)) {
        this.circleMarker.center = this.selectedMarker?.latLng;
      }
    },
    markerList() {
      if (this.loading && this.markerList.length > 0) {
        this.selectedMarker = this.defaultSelectedMarker;
        this.loading = false;
      }
    }
  },
  beforeMount() {
    const geolocalisation = sessionStorage.getItem("geolocalisation");
    if (geolocalisation) {
      this.localizationCookie = geolocalisation.split(",").map((value) => parseFloat(value));
    } else {
      this.localizationCookie = void 0;
    }
    if (this.mapCenter && !this.defaultSelectedMarker) {
      this.center = this.mapCenter;
    } else if (this.centerOnClickedMarker && !this.defaultSelectedMarker) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.getPositionSuccess,
          this.getPositionFailed
        );
      }
    }
    if (this.centerOnClickedMarker && this.defaultSelectedMarker) {
      this.selectedMarker = this.defaultSelectedMarker;
      this.center = this.defaultSelectedMarker.latLng;
      this.circleMarker.center = this.selectedMarker.latLng;
    }
  },
  mounted() {
    setTimeout(() => {
      this.zoom = this.mapZoom;
    }, 200);
  },
  methods: {
    /**
     * @name centerUpdated
     * @description Emits centerUpdated when the center changes
     *
     * @param {number[]} centerCoordinates Center coordinates sent by the leaflet map
     */
    centerUpdated(centerCoordinates) {
      this.$emit("centerUpdated", centerCoordinates);
    },
    /**
     * @name boundsUpdated
     * @description Emits boundsUpdated when the bounds change
     *
     * @param {MapBounds} boundsCoordinates Bounds coordinates sent by the leaflet map
     */
    boundsUpdated(boundsCoordinates) {
      if (JSON.stringify(boundsCoordinates) !== JSON.stringify(this.memoizedBounds)) {
        this.memoizedBounds = boundsCoordinates;
        this.$emit(
          "boundsUpdated",
          boundsCoordinates,
          // We inverse the bounds for the API needs
          this.inverseBounds(boundsCoordinates)
        );
      }
    },
    /**
     * @name zoomUpdated
     * @description Emits zoomUpdated when the bounds change
     *
     * @param {number} zoomValue Zoom value sent by the leaflet map
     */
    zoomUpdated(zoomValue) {
      this.$emit("zoomUpdated", zoomValue);
    },
    /**
     * @name markerClicked
     * @description Emits zoomUpdated when the bounds change
     *
     * @param {Marker} marker Zoom value sent by the leaflet map
     */
    markerClicked(marker) {
      this.selectedMarker = marker;
      this.$emit("markerClicked", marker);
    },
    markerIsCluster(marker) {
      return marker.type === "cluster";
    },
    getPositionSuccess(position) {
      setTimeout(() => {
        this.center = [
          position.coords.latitude,
          position.coords.longitude
        ];
      }, 250);
      if (!this.localizationCookie) {
        sessionStorage.setItem(
          "geolocalisation",
          [
            position.coords.latitude,
            position.coords.longitude
          ].toString()
        );
        this.localizationCookie = [
          position.coords.latitude,
          position.coords.longitude
        ];
      }
    },
    getPositionFailed() {
      if (this.localizationCookie) {
        setTimeout(() => {
          this.center = this.localizationCookie;
        }, 50);
      }
    },
    zoomIn() {
      if (this.maxZoom > this.zoom) {
        this.zoom += 1;
      }
    },
    zoomOut() {
      if (this.minZoom < this.zoom) {
        this.zoom -= 1;
      }
    },
    inverseBounds(bounds) {
      return {
        NW: {
          lat: bounds._northEast.lat,
          lng: bounds._southWest.lng
        },
        SE: {
          lat: bounds._southWest.lat,
          lng: bounds._northEast.lng
        }
      };
    },
    updateIsMapKeyOpen(val) {
      this.isMapKeyOpen = val;
    }
  }
};
</script>
