<template>
    <div
        class="px-3 py-2 rounded flex-col justify-center items-center inline-flex"
        data-testid="chargemap-label"
        :class="[
            `text-${textColor}`,
            `border-${color}-200`,
            `bg-${color}-100`,
            border && 'border'
        ]"
    >
        <div
            class="text-center text-base font-light leading-tight"
            :class="[`text-${textColor}`]"
        >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
  name: "ChargemapLabel",
  props: {
    color: {
      type: String,
      default: "zinc"
    },
    textColor: {
      type: String,
      default: "zinc-800"
    },
    border: {
      type: Boolean,
      default: false
    }
  }
};
</script>
