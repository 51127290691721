<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path
            fill="#28a745"
            d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16c-8.837 0-16-7.163-16-16s7.163-16 16-16z"
        ></path>
        <path
            fill="#fff"
            d="M19.256 8.687c-1.576-0.702-3.336-0.876-5.019-0.496s-3.197 1.294-4.318 2.606-1.788 2.95-1.901 4.671c-0.114 1.721 0.332 3.433 1.271 4.881s2.321 2.552 3.939 3.15c1.618 0.598 3.386 0.657 5.041 0.167s3.106-1.5 4.139-2.882 1.591-3.060 1.593-4.785v-0.817c0-0.491 0.398-0.889 0.889-0.889s0.889 0.398 0.889 0.889v0.818c-0.001 2.109-0.684 4.161-1.946 5.849s-3.037 2.924-5.059 3.522c-2.022 0.598-4.183 0.526-6.161-0.205s-3.666-2.081-4.814-3.85c-1.148-1.769-1.693-3.861-1.554-5.965s0.954-4.107 2.324-5.71 3.221-2.72 5.278-3.185c2.057-0.465 4.208-0.252 6.134 0.606 0.448 0.2 0.65 0.725 0.45 1.174s-0.725 0.65-1.174 0.45z"
        ></path>
        <path
            fill="#fff"
            d="M25.517 8.26c0.347 0.347 0.348 0.91 0.001 1.257l-8.889 8.898c-0.167 0.167-0.393 0.261-0.629 0.261s-0.462-0.094-0.629-0.26l-2.667-2.667c-0.347-0.347-0.347-0.91 0-1.257s0.91-0.347 1.257 0l2.038 2.038 8.26-8.269c0.347-0.347 0.91-0.348 1.257-0.001z"
        ></path>
    </svg>
</template>

<script>
export default {
  name: "IconPassActivated"
};
</script>
