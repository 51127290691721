<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        class="fill-current"
    >
        <path
            d="M16.002 22.464l-6.707 3.949 1.766-7.446-5.923-5.010 7.814-0.644 3.050-7.028 3.050 7.028 7.814 0.644-5.923 5.010 1.766 7.446-6.707-3.949zM31.558 13.694c0.852-0.72 0.396-2.085-0.727-2.177l-9.817-0.812-3.84-8.835c-0.439-1.010-1.907-1.010-2.346 0l-3.84 8.835-9.821 0.813c-1.123 0.093-1.578 1.457-0.727 2.177l7.444 6.301-2.227 9.367c-0.254 1.070 0.935 1.912 1.9 1.345l8.444-4.97 8.439 4.967c0.965 0.568 2.155-0.276 1.899-1.346l-2.24-9.362 7.458-6.3z"
        ></path>
    </svg>
</template>

<script>
export default {
  name: "IconStarEmpty"
};
</script>
