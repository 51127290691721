<template>
    <div class="p-2 z-50 bottom-0 right-0" data-testid="map-key">
        <button
            class="h-10 w-10 bg-white p-1 shadow border border-opacity-20 border-black rounded-lg hover:bg-gray-100"
            data-testid="button-open-key"
            :aria-label="title"
            @click="toggleIsOpen"
        >
            <IconInfoCircle class="w-5 h-5 mx-auto my-auto text-slate-500" />
        </button>
        <div
            :class="[
                'w-80 425:w-88 xs:w-96 flex-col gap-3 absolute right-0 bottom-12 md:right-12 md:bottom-0 bg-white m-2 rounded-lg shadow border border-slate-300 p-6',
                localIsOpen ? 'flex' : 'hidden'
            ]"
            data-testid="div-key"
        >
            <span
                v-show="title"
                class="text-slate-800 text-lg font-semibold leading-7"
            >
                {{ title }}
            </span>
            <template v-for="(legend, keyIndex) in keys">
                <div
                    v-if="keyIndex > 0"
                    :key="keyIndex"
                    class="border border-slate-300"
                ></div>
                <div
                    v-for="(key, legendIndex) in legend"
                    :key="legendIndex"
                    class="flex flex-row gap-2"
                >
                    <img
                        v-for="(icon, iconIndex) in getIconsUrl(
                            keyIndex,
                            legendIndex
                        )"
                        :key="iconIndex"
                        class="w-8 h-8"
                        :src="icon"
                        :alt="key.text"
                    />
                    <span class="my-auto text-slate-600 text-sm">
                        {{ key.text }}
                    </span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import IconInfoCircle from "../../icons/IconInfoCircle.vue";
export default {
  name: "MapKey",
  components: {
    IconInfoCircle
  },
  props: {
    keys: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ""
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  emits: ["updateIsMapKeyOpen"],
  data() {
    return {
      localIsOpen: false
    };
  },
  watch: {
    isOpen(val) {
      this.localIsOpen = val;
    }
  },
  methods: {
    getIconsUrl(keyIndex, legendIndex) {
      return this.keys[keyIndex]?.[legendIndex]?.iconsUrls || [];
    },
    toggleIsOpen() {
      this.localIsOpen = !this.localIsOpen;
      this.$emit("updateIsMapKeyOpen", this.localIsOpen);
    }
  }
};
</script>
