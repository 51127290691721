<template>
    <div class="flex flex-col gap-1">
        <DrawerInfoCard>
            <template #title>
                {{ $t('word.network') }}
            </template>
            <div
                class="flex flex-row text-sky-600 justify-between items-center"
            >
                <div
                    class="grow shrink basis-0 flex-col justify-start items-start inline-flex"
                >
                    <div class="self-stretch text-base leading-tight">
                        {{ getNetworkName }}
                    </div>
                </div>
                <IconChevronRight class="w-6 h-6" />
            </div>
            <div>
                <PoolRating
                    :rating="getNetworkGlobalRating"
                    size="extra-small"
                    text-size="font-medium"
                />
            </div>
        </DrawerInfoCard>
        <ChargemapCaption class="px-1">
            <template #icon>
                <IconInfoCircle class="w-3 h-3" is-solid />
            </template>
            {{ $t('tab_info.network.caption') }}
        </ChargemapCaption>
    </div>
</template>

<script>
import IconChevronRight from "../../../../icons/IconChevronRight.vue";
import IconInfoCircle from "../../../../icons/IconInfoCircle.vue";
import ChargemapCaption from "../../../global/ChargemapCaption.vue";
import PoolRating from "../../../rating/PoolRating.vue";
import DrawerInfoCard from "./DrawerInfoCard.vue";
export default {
  name: "DrawerInfoNetwork",
  components: {
    DrawerInfoCard,
    IconChevronRight,
    PoolRating,
    ChargemapCaption,
    IconInfoCircle
  },
  props: {
    pool: {
      type: Object,
      default: void 0
    }
  },
  computed: {
    getNetworkName() {
      return this.pool?.network?.name || "";
    },
    getNetworkGlobalRating() {
      return this.pool?.network?.averageRating || 0;
    }
  }
};
</script>
