<template>
    <svg
        v-if="isSolid"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 21.6C14.5461 21.6 16.9879 20.5886 18.7882 18.7882C20.5886 16.9879 21.6 14.5461 21.6 12C21.6 9.45395 20.5886 7.01215 18.7882 5.2118C16.9879 3.41145 14.5461 2.40002 12 2.40002C9.45395 2.40002 7.01215 3.41145 5.2118 5.2118C3.41145 7.01215 2.40002 9.45395 2.40002 12C2.40002 14.5461 3.41145 16.9879 5.2118 18.7882C7.01215 20.5886 9.45395 21.6 12 21.6ZM13.2 7.20002C13.2 6.88176 13.0736 6.57654 12.8486 6.3515C12.6235 6.12645 12.3183 6.00002 12 6.00002C11.6818 6.00002 11.3765 6.12645 11.1515 6.3515C10.9265 6.57654 10.8 6.88176 10.8 7.20002V12C10.8001 12.3183 10.9266 12.6234 11.1516 12.8484L14.5452 16.2432C14.6567 16.3547 14.7891 16.4432 14.9347 16.5035C15.0804 16.5638 15.2366 16.5949 15.3942 16.5949C15.5519 16.5949 15.708 16.5638 15.8537 16.5035C15.9994 16.4432 16.1317 16.3547 16.2432 16.2432C16.3547 16.1317 16.4432 15.9994 16.5035 15.8537C16.5638 15.708 16.5949 15.5519 16.5949 15.3942C16.5949 15.2366 16.5638 15.0804 16.5035 14.9347C16.4432 14.7891 16.3547 14.6567 16.2432 14.5452L13.2 11.5032V7.20002Z"
        />
    </svg>

    <svg
        v-else
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 13.0506 4.20693 14.0909 4.60896 15.0615C5.011 16.0321 5.60028 16.914 6.34315 17.6569C7.08601 18.3997 7.96793 18.989 8.93853 19.391C9.90914 19.7931 10.9494 20 12 20C13.0506 20 14.0909 19.7931 15.0615 19.391C16.0321 18.989 16.914 18.3997 17.6569 17.6569C18.3997 16.914 18.989 16.0321 19.391 15.0615C19.7931 14.0909 20 13.0506 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 13.3132 21.7413 14.6136 21.2388 15.8268C20.7362 17.0401 19.9997 18.1425 19.0711 19.0711C18.1425 19.9997 17.0401 20.7362 15.8268 21.2388C14.6136 21.7413 13.3132 22 12 22C10.6868 22 9.38642 21.7413 8.17317 21.2388C6.95991 20.7362 5.85752 19.9997 4.92893 19.0711C4.00035 18.1425 3.26375 17.0401 2.7612 15.8268C2.25866 14.6136 2 13.3132 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893ZM12 7C12.5523 7 13 7.44772 13 8V11.5858L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L11.2929 12.7071C11.1054 12.5196 11 12.2652 11 12V8C11 7.44772 11.4477 7 12 7Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconClock",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
