<template>
    <div
        class="flex justify-between w-full py-4"
        data-testid="filter-rating-range"
    >
        <ChargemapSlider
            :min="1"
            :max="5"
            :default-values="range"
            :trigger-update="triggerRangeUpdate"
            @update-values="updatedValues"
        />
    </div>
</template>

<script>
import ChargemapSlider from "../../b2b/global/ChargemapSlider.vue";
export default {
  name: "FilterRatingRange",
  components: { ChargemapSlider },
  props: {
    values: {
      type: Object,
      default: void 0
    },
    triggerRangeUpdate: {
      type: Boolean,
      default: false
    }
  },
  emits: ["updatedRange"],
  data: () => ({
    triggerValueUpdate: false
  }),
  computed: {
    range() {
      return this.values ? [this.values.min, this.values.max] : [1, 5];
    }
  },
  methods: {
    updatedValues(newValues) {
      if (newValues[0] === 1 && newValues[1] === 5) {
        this.$emit("updatedRange", null);
        return;
      }
      this.$emit("updatedRange", newValues);
    }
  }
};
</script>
