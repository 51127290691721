<template>
    <div
        data-testid="card"
        class="flex flex-col items-center bg-white rounded-lg border-solid border-zinc-200 w-full h-min"
        :class="[!noBorder && 'border', !noPadding && 'p-4']"
    >
        <slot name="title" />
    </div>
</template>

<script>
export default {
  name: "FeedbackCard",
  props: {
    noBorder: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  }
};
</script>
