<template>
    <svg
        v-if="isSolid"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 22"
        class="fill-current"
    >
        <path
            d="M3.23282 0.637041C2.7636 1.01788 2.49999 1.5344 2.49999 2.07299C2.49999 2.61157 2.7636 3.1281 3.23282 3.50894L3.23522 3.51087C3.48672 3.71603 3.62796 3.99369 3.62796 4.28318C3.62796 4.57358 3.48583 4.85209 3.23282 5.05743C3.00769 5.24015 3.00769 5.5364 3.23282 5.71912C3.45795 5.90185 3.82296 5.90185 4.04809 5.71912C4.51732 5.33829 4.78093 4.82176 4.78093 4.28318C4.78093 3.74459 4.51732 3.22807 4.04809 2.84723L4.04562 2.84523C3.79416 2.64008 3.65295 2.36244 3.65295 2.07299C3.65295 1.78259 3.79509 1.50408 4.04809 1.29873C4.27322 1.11601 4.27322 0.819763 4.04809 0.637041C3.82296 0.45432 3.45795 0.45432 3.23282 0.637041Z"
        />
        <path
            d="M4.73991 2.12477C4.73991 1.58619 5.00352 1.06966 5.47275 0.688823C5.69788 0.506102 6.06288 0.506102 6.28801 0.688823C6.51315 0.871545 6.51315 1.16779 6.28801 1.35052C6.03501 1.55586 5.89287 1.83437 5.89287 2.12477C5.89287 2.41517 6.03501 2.69368 6.28801 2.89903L6.29213 2.9024C6.75878 3.28292 7.02085 3.79799 7.02085 4.335C7.02085 4.87359 6.75724 5.39011 6.28801 5.77095C6.06288 5.95367 5.69788 5.95367 5.47275 5.77095C5.24762 5.58823 5.24762 5.29198 5.47275 5.10926C5.72575 4.90391 5.86789 4.6254 5.86789 4.335C5.86789 4.0446 5.72575 3.76609 5.47275 3.56075L5.46864 3.55738C5.00198 3.17686 4.73991 2.66178 4.73991 2.12477Z"
        />
        <path
            d="M7.71269 0.740648C7.24346 1.12149 6.97985 1.63801 6.97985 2.1766C6.97985 2.71518 7.24346 3.23171 7.71269 3.61254L7.71508 3.61447C7.96659 3.81963 8.10783 4.0973 8.10783 4.38678C8.10783 4.67719 7.9657 4.95569 7.71269 5.16104C7.48756 5.34376 7.48756 5.64001 7.71269 5.82273C7.93782 6.00545 8.30283 6.00545 8.52796 5.82273C8.99719 5.44189 9.26079 4.92537 9.2608 4.38678C9.2608 3.8482 8.99719 3.33167 8.52796 2.95083L8.52556 2.9489C8.27406 2.74374 8.13282 2.46608 8.13282 2.1766C8.13282 1.88619 8.27495 1.60769 8.52796 1.40234C8.75309 1.21962 8.75309 0.92337 8.52796 0.740648C8.30283 0.557927 7.93782 0.557927 7.71269 0.740648Z"
        />
        <path
            d="M0.439844 7.44923C0.651962 6.85031 1.21841 6.45 1.85378 6.45H10.1462C10.7816 6.45 11.348 6.85031 11.5601 7.44923L11.95 8.55H0.0499878L0.439844 7.44923Z"
        />
        <path
            d="M1.09999 9.25H10.9L9.65195 20.1703C9.56537 20.9279 8.92418 21.5 8.16165 21.5H3.83832C3.07579 21.5 2.43461 20.9279 2.34802 20.1703L1.09999 9.25Z"
        />
    </svg>
    <svg
        v-else
        viewBox="0 0 14 23"
        fill="none"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.30819 11.8936L2.21262 11H2.26928L2.37968 12.0998L3.13592 19.6337L2.30819 11.8936ZM4.64047 1.30274L4.28214 0.861241L4.64047 1.30274ZM11.7873 11L11.6918 11.8936L10.864 19.6337L11.6203 12.0998L11.7307 11H11.7873Z"
            stroke-width="2"
            stroke-linecap="round"
        />
    </svg>
</template>

<script>
export default {
  name: "IconDrinks",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
