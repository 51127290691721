<template>
    <div
        v-show="loading"
        id="parentLoader"
        ref="parentLoader"
        data-testid="chargemap-loader"
        class="z-10 flex justify-center items-center"
        :style="[
            height !== 0 ? `height: calc(100vh - ${height}px)` : 'display:none'
        ]"
    >
        <div class="absolute flex justify-center items-center z-20">
            <div
                class="flex-initial"
                :class="!noShadow ? 'shadow-xl bg-white rounded-2xl p-4' : ''"
            >
                <div class="loader-container" :class="sizeClass">
                    <div class="loader-circle" />
                    <div class="loader-spinner">
                        <div class="quarter">
                            <div
                                class="quarter__circle"
                                :class="[`border-${color}-400`, sizeClass]"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChargemapLoader',
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'primary',
            required: false,
            validator(value) {
                return [
                    'primary',
                    'slate',
                    'red',
                    'yellow',
                    'green',
                    'blue',
                    'indigo',
                    'purple',
                    'pink',
                    'violet'
                ].includes(value)
            }
        },
        noShadow: {
            type: Boolean,
            default: false
        },
        loaderSize: {
            type: String,
            default: 'large',
            validator(value) {
                return ['small', 'medium', 'large'].includes(value)
            }
        }
    },
    data() {
        return {
            height: 0
        }
    },
    computed: {
        sizeClass() {
            if (this.loaderSize === 'small') return 'w-8 h-8'
            if (this.loaderSize === 'medium') return 'w-12 h-12'
            return 'w-20 h-20'
        }
    },
    watch: {
        loading() {
            setTimeout(() => {
                this.calculateHeight()
            }, 1)
        }
    },
    mounted() {
        this.calculateHeight()
    },
    methods: {
        calculateHeight() {
            const element = this.$refs?.parentLoader
            let height = 0
            if (element) {
                height = element.getBoundingClientRect().top
            }
            if (height !== 0) this.height = height + 40
        }
    }
}
</script>

<style scoped>
.loader-spinner,
.loader-spinner * {
  box-sizing: border-box;
}

.loader-container {
  @apply relative;
}

.loader-circle,
.loader-spinner {
  @apply absolute inset-0 w-full h-full overflow-hidden;
}

.loader-circle {
  @apply border-4 rounded-full border-gray-200;
}

.loader-spinner {
  animation: loader-spin 2s infinite;
}

.quarter {
  @apply absolute h-1/2 top-0 left-0 overflow-hidden;
  width: 70%;
}

.quarter__circle {
  @apply absolute top-0 left-0 border-4 rounded-full;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
