<template>
    <div
        v-if="isChargeFinished"
        class="flex flex-col gap-1 w-full"
        data-testid="checkin"
    >
        <FeedbackRating
            v-if="hasRating"
            :user="commonProps.user"
            :is-anonymous="commonProps.isAnonymous"
            :rating="commonProps.rating"
            :cdnUrl
        />
        <CheckinHeader
            :user="commonProps.user"
            :is-anonymous="commonProps.isAnonymous"
            :creation-date="checkinProps.chargeStartDate"
            :end-date="checkinProps.chargeEndDate"
            :locale
        />
        <CheckinContent
            :vehicle="checkinProps.vehicle"
            :connector="checkinProps.connector"
            :connector-type-translation="getConnectorTypeTranslation"
            :has-charged-with-pass="getHasChargedWithPass"
        />
        <div
            v-if="hasComment"
            class="flex flex-row pl-1.5 items-start mt-3 w-full"
        >
            <IconChatAlt class="h-[18px] w-[18px] text-gray-comment" is-solid />
            <CommentContent
                :content="checkinProps.comment"
                :must-display-whole-comment="
                    commonProps.mustDisplayWholeComment
                "
                :small-margin-left="true"
                class="w-full"
            />
        </div>
    </div>
    <div v-else class="flex flex-col gap-1 w-full">
        <CheckinInProgressHeader
            :user="commonProps.user"
            :is-anonymous="commonProps.isAnonymous"
        />
        <CheckinInProgressContent
            :start-date="checkinProps.chargeStartDate"
            :end-date="checkinProps.chargeEndDate"
            :vehicle="checkinProps.vehicle"
            :connector="checkinProps.connector"
            :connector-type-translation="getConnectorTypeTranslation"
        />
    </div>
</template>

<script>
import { DateTime } from "luxon";
import CheckinContent from "./CheckinContent.vue";
import CheckinHeader from "./CheckinHeader.vue";
import CommentContent from "../comment/CommentContent.vue";
import CheckinInProgressHeader from "./CheckinInProgressHeader.vue";
import CheckinInProgressContent from "./CheckinInProgressContent.vue";
import FeedbackRating from "../FeedbackRating.vue";
import IconChatAlt from "../../../icons/IconChatAlt.vue";
export default {
  name: "Checkin",
  components: {
    FeedbackRating,
    CheckinInProgressHeader,
    CheckinInProgressContent,
    CommentContent,
    CheckinHeader,
    CheckinContent,
    IconChatAlt
  },
  props: {
    commonProps: {
      type: Object,
      default() {
        return {};
      }
    },
    checkinProps: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: true
    },
    cdnUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      now: DateTime.now(),
      tick: void 0
    };
  },
  computed: {
    isChargeFinished() {
      return this.checkinProps.chargeEndDate && this.checkinProps.chargeEndDate < this.now;
    },
    getConnectorTypeTranslation() {
      return this.$t(this.checkinProps.connector?.type || "");
    },
    hasComment() {
      return this.checkinProps.comment;
    },
    hasRating() {
      return this.commonProps.rating && this.commonProps.rating.globalScore;
    },
    getHasChargedWithPass() {
      return this.commonProps.user && this.commonProps.user.hasChargedWithPass && this.commonProps.user.hasChargedWithPass === true;
    }
  },
  watch: {
    now() {
      if (this.isChargeFinished) {
        clearInterval(this.tick);
      }
    }
  },
  mounted() {
    if (!this.isChargeFinished) {
      this.tick = setInterval(() => {
        this.now = DateTime.now();
      }, 1e3);
    }
  }
};
</script>
