<template>
    <div
        class="flex gap-4 flex-col mt-2 md:mt-4 max-w-full"
        data-testid="drawer-header"
    >
        <div class="flex flex-row justify-between align-start px-4 md:px-6">
            <DrawerHeaderFree
                :pool="pool"
                :s3-url="s3Url"
                :loading-info="loadingInfo"
                :chargemap-url="chargemapUrl"
                :loading-pictures="loadingPictures"
                :show-partner-full-message="showPartnerFullMessage"
                :show-close-button="showCloseButton"
                @close-drawer="closeDrawer"
            />
        </div>
        <div v-if="hasMessage" class="px-4 md:px-6">
            <DrawerMessage
                :message-text="getMessageContent"
                :type="getMessageType"
                :button-text="getMessageAction"
                @message-action-click="messageActionClick"
            />
        </div>

        <DrawerBadges
            class="px-4 md:px-6"
            :pool="pool"
            :loading-info="loadingInfo"
        />
        <DrawerPicturesContainer
            v-if="pictures.length > 0 || loadingPictures"
            :pictures="pictures"
            :loading-pictures="loadingPictures"
            :s3-url="s3Url"
            @openPicture="openPicture"
        />
        <DrawerReliabilityBar
            :pool="pool"
            :loading-info="loadingInfo"
            :cdn-url
            class="w-full px-4 md:px-6"
            @reliability-clicked="reliabilityClicked"
        />

        <div class="px-4 md:px-6">
            <ChargemapSkeletonLoader
                v-if="loadingInfo"
                class="h-10 border border-zinc-300 rounded-lg"
            />
            <ChargemapButton
                v-else
                btn-alt="clear"
                :is-loading="loadingInfo"
                class="w-full"
                @click="openTariff"
            >
                {{ $t('drawer.tariffs') }}
            </ChargemapButton>
        </div>

        <ConnectorsCarousel
            v-if="poolConnectors.length > 0 || loadingStations"
            :pool-connectors="poolConnectors"
            :legacy-api-url="legacyApiUrl"
            :loading-stations="loadingStations"
            @connector-clicked="connectorClicked"
        />
    </div>
</template>

<script>
import { MessageTypes } from "../../../interfaces/drawer";
import ChargemapButton from "../../b2b/global/ChargemapButton.vue";
import ChargemapSkeletonLoader from "../global/ChargemapSkeletonLoader.vue";
import DrawerBadges from "./header/DrawerBadges.vue";
import ConnectorsCarousel from "../global/ConnectorsCarousel.vue";
import DrawerHeaderFree from "./header/DrawerHeaderFree.vue";
import DrawerMessage from "./header/DrawerMessage.vue";
import DrawerPicturesContainer from "./header/DrawerPicturesContainer.vue";
import DrawerReliabilityBar from "./header/DrawerReliabilityBar.vue";
export default {
  name: "DrawerHeader",
  components: {
    DrawerHeaderFree,
    DrawerBadges,
    DrawerPicturesContainer,
    DrawerReliabilityBar,
    DrawerMessage,
    ConnectorsCarousel,
    ChargemapSkeletonLoader,
    ChargemapButton
  },
  props: {
    pool: {
      type: Object,
      default: void 0
    },
    poolConnectors: {
      type: Array,
      required: true
    },
    pictures: {
      type: Array,
      required: true
    },
    showCloseButton: {
      type: Boolean,
      required: true
    },
    s3Url: {
      type: String,
      required: true
    },
    legacyApiUrl: {
      type: String,
      required: true
    },
    chargemapUrl: {
      type: String,
      required: true
    },
    message: {
      type: Object,
      default: void 0
    },
    loadingPictures: {
      type: Boolean,
      required: true
    },
    loadingInfo: {
      type: Boolean,
      required: true
    },
    loadingStations: {
      type: Boolean,
      required: true
    },
    showPartnerFullMessage: {
      type: Boolean,
      default: false
    },
    cdnUrl: {
      type: String,
      default: ""
    }
  },
  emits: [
    "closeDrawer",
    "openPicture",
    "reliabilityClicked",
    "messageActionClick",
    "connectorClicked",
    "openTariff"
  ],
  data() {
    return {
      MessageTypes
    };
  },
  computed: {
    getMessageType() {
      return this.message?.type || MessageTypes.NEUTRAL;
    },
    getMessageContent() {
      return this.message?.content || "";
    },
    getMessageAction() {
      return this.message?.action || "";
    },
    getMessageLink() {
      return this.message?.actionLink || "";
    },
    hasMessage() {
      return this.message !== void 0;
    }
  },
  methods: {
    closeDrawer() {
      this.$emit("closeDrawer", false);
    },
    reliabilityClicked(payload) {
      this.$emit("reliabilityClicked", payload);
    },
    openPicture(index) {
      this.$emit("openPicture", index);
    },
    messageActionClick() {
      this.$emit("messageActionClick");
    },
    connectorClicked(connector) {
      this.$emit("connectorClicked", connector);
    },
    openTariff() {
      this.$emit("openTariff");
    }
  }
};
</script>
../../../interfaces/drawer;d ../../../interfaces/drawer
