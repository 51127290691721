<template>
    <svg
        v-if="isSolid"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.8 2.40002C10.5772 2.40014 10.3588 2.46228 10.1693 2.57949C9.97987 2.69669 9.82676 2.86433 9.72718 3.06362L8.85838 4.80002H4.79998C4.48172 4.80002 4.17649 4.92645 3.95145 5.1515C3.7264 5.37654 3.59998 5.68176 3.59998 6.00002C3.59998 6.31828 3.7264 6.62351 3.95145 6.84855C4.17649 7.0736 4.48172 7.20002 4.79998 7.20002V19.2C4.79998 19.8365 5.05283 20.447 5.50292 20.8971C5.95301 21.3472 6.56346 21.6 7.19998 21.6H16.8C17.4365 21.6 18.0469 21.3472 18.497 20.8971C18.9471 20.447 19.2 19.8365 19.2 19.2V7.20002C19.5182 7.20002 19.8235 7.0736 20.0485 6.84855C20.2735 6.62351 20.4 6.31828 20.4 6.00002C20.4 5.68176 20.2735 5.37654 20.0485 5.1515C19.8235 4.92645 19.5182 4.80002 19.2 4.80002H15.1416L14.2728 3.06362C14.1732 2.86433 14.0201 2.69669 13.8306 2.57949C13.6411 2.46228 13.4228 2.40014 13.2 2.40002H10.8ZM8.39998 9.60002C8.39998 9.28176 8.5264 8.97654 8.75145 8.7515C8.97649 8.52645 9.28172 8.40002 9.59998 8.40002C9.91823 8.40002 10.2235 8.52645 10.4485 8.7515C10.6735 8.97654 10.8 9.28176 10.8 9.60002V16.8C10.8 17.1183 10.6735 17.4235 10.4485 17.6486C10.2235 17.8736 9.91823 18 9.59998 18C9.28172 18 8.97649 17.8736 8.75145 17.6486C8.5264 17.4235 8.39998 17.1183 8.39998 16.8V9.60002ZM14.4 8.40002C14.0817 8.40002 13.7765 8.52645 13.5514 8.7515C13.3264 8.97654 13.2 9.28176 13.2 9.60002V16.8C13.2 17.1183 13.3264 17.4235 13.5514 17.6486C13.7765 17.8736 14.0817 18 14.4 18C14.7182 18 15.0235 17.8736 15.2485 17.6486C15.4735 17.4235 15.6 17.1183 15.6 16.8V9.60002C15.6 9.28176 15.4735 8.97654 15.2485 8.7515C15.0235 8.52645 14.7182 8.40002 14.4 8.40002Z"
        />
    </svg>
    <svg
        v-else
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H19.9311L19.1305 19.213C19.1305 19.2131 19.1305 19.2129 19.1305 19.213C19.0765 19.9698 18.7379 20.6783 18.1826 21.1954C17.6274 21.7125 16.8968 22 16.138 22H7.86202C7.10323 22 6.37262 21.7125 5.81735 21.1954C5.26213 20.6783 4.92347 19.97 4.86954 19.2132C4.86953 19.2132 4.86955 19.2133 4.86954 19.2132L4.06886 8H4C3.44772 8 3 7.55228 3 7C3 6.44772 3.44772 6 4 6H8V4C8 3.46957 8.21071 2.96086 8.58579 2.58579ZM10 6H14V4H10V6ZM6.07395 8L6.86446 19.0708C6.88242 19.3231 6.99533 19.5594 7.18042 19.7318C7.36551 19.9042 7.60905 20 7.862 20H16.138C16.391 20 16.6345 19.9042 16.8196 19.7318C17.0047 19.5594 17.1176 19.3233 17.1355 19.071L17.926 8H6.07395ZM10 10C10.5523 10 11 10.4477 11 11V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V11C9 10.4477 9.44772 10 10 10ZM14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V11C13 10.4477 13.4477 10 14 10Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconTrash",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
