<template>
    <ChargemapModal
        v-if="showPricesModal"
        :show-x="true"
        data-testid="pool-prices-modal"
        @updateIsVisible="updateShowPricesModal"
    >
        <template #title>
            <slot name="title" />
        </template>
        <IconLoader
            v-show="loading"
            class="h-5 m-auto w-5 text-gray-500 animate-spin"
            data-testid="loader"
        />
        <iframe
            :id="`prices-modal-${poolId}`"
            class="prices-modals"
            :srcdoc="pricesModalHTML"
        ></iframe>
    </ChargemapModal>
</template>

<script>
import IconLoader from "../../icons/IconLoader.vue";
import ChargemapModal from "../global/ChargemapModal.vue";
export default {
  name: "PoolPricesModal",
  components: { ChargemapModal, IconLoader },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    showPricesModal: {
      type: Boolean,
      default: false
    },
    pricesModalHTML: {
      type: String,
      default: ""
    },
    poolId: {
      type: Number,
      required: true
    }
  },
  emits: ["updateShowPricesModal"],
  methods: {
    updateShowPricesModal(val) {
      this.$emit("updateShowPricesModal", val);
    }
  }
};
</script>

<style>
.prices-modals{border:0;transition:all .3s ease-in-out;width:100%}
</style>
