<template>
    <svg
        v-if="isSolid"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.0484 12.3516C20.2733 12.5767 20.3997 12.8818 20.3997 13.2C20.3997 13.5182 20.2733 13.8234 20.0484 14.0484L12.8484 21.2484C12.6233 21.4734 12.3181 21.5998 12 21.5998C11.6818 21.5998 11.3766 21.4734 11.1516 21.2484L3.95155 14.0484C3.83694 13.9377 3.74552 13.8053 3.68263 13.6589C3.61974 13.5125 3.58663 13.355 3.58525 13.1957C3.58387 13.0364 3.61423 12.8784 3.67456 12.7309C3.7349 12.5834 3.82401 12.4494 3.93668 12.3368C4.04935 12.2241 4.18333 12.135 4.33081 12.0746C4.47828 12.0143 4.6363 11.9839 4.79563 11.9853C4.95497 11.9867 5.11243 12.0198 5.25884 12.0827C5.40524 12.1456 5.53765 12.237 5.64835 12.3516L10.8 17.5032V3.60002C10.8 3.28176 10.9264 2.97654 11.1514 2.7515C11.3765 2.52645 11.6817 2.40002 12 2.40002C12.3182 2.40002 12.6234 2.52645 12.8485 2.7515C13.0735 2.97654 13.2 3.28176 13.2 3.60002V17.5032L18.3516 12.3516C18.5766 12.1267 18.8818 12.0003 19.2 12.0003C19.5181 12.0003 19.8233 12.1267 20.0484 12.3516Z"
        />
    </svg>

    <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 2C12.5523 2 13 2.44772 13 3V18.5858L18.2929 13.2929C18.6834 12.9024 19.3166 12.9024 19.7071 13.2929C20.0976 13.6834 20.0976 14.3166 19.7071 14.7071L12.7071 21.7071C12.3166 22.0976 11.6834 22.0976 11.2929 21.7071L4.29289 14.7071C3.90237 14.3166 3.90237 13.6834 4.29289 13.2929C4.68342 12.9024 5.31658 12.9024 5.70711 13.2929L11 18.5858V3C11 2.44772 11.4477 2 12 2Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconArrowUp",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
