<template>
    <svg
        v-if="isSolid"
        class="fill-current"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.39999 5.99998C2.39999 5.36346 2.65285 4.75301 3.10294 4.30292C3.55302 3.85283 4.16347 3.59998 4.79999 3.59998H19.2C19.8365 3.59998 20.447 3.85283 20.8971 4.30292C21.3471 4.75301 21.6 5.36346 21.6 5.99998V8.39998C21.6 9.03649 21.3471 9.64694 20.8971 10.097C20.447 10.5471 19.8365 10.8 19.2 10.8H4.79999C4.16347 10.8 3.55302 10.5471 3.10294 10.097C2.65285 9.64694 2.39999 9.03649 2.39999 8.39998V5.99998ZM19.2 7.19998C19.2 7.51824 19.0736 7.82346 18.8485 8.0485C18.6235 8.27355 18.3183 8.39998 18 8.39998C17.6817 8.39998 17.3765 8.27355 17.1515 8.0485C16.9264 7.82346 16.8 7.51824 16.8 7.19998C16.8 6.88172 16.9264 6.57649 17.1515 6.35145C17.3765 6.1264 17.6817 5.99998 18 5.99998C18.3183 5.99998 18.6235 6.1264 18.8485 6.35145C19.0736 6.57649 19.2 6.88172 19.2 7.19998ZM2.39999 15.6C2.39999 14.9635 2.65285 14.353 3.10294 13.9029C3.55302 13.4528 4.16347 13.2 4.79999 13.2H19.2C19.8365 13.2 20.447 13.4528 20.8971 13.9029C21.3471 14.353 21.6 14.9635 21.6 15.6V18C21.6 18.6365 21.3471 19.2469 20.8971 19.697C20.447 20.1471 19.8365 20.4 19.2 20.4H4.79999C4.16347 20.4 3.55302 20.1471 3.10294 19.697C2.65285 19.2469 2.39999 18.6365 2.39999 18V15.6ZM19.2 16.8C19.2 17.1182 19.0736 17.4235 18.8485 17.6485C18.6235 17.8735 18.3183 18 18 18C17.6817 18 17.3765 17.8735 17.1515 17.6485C16.9264 17.4235 16.8 17.1182 16.8 16.8C16.8 16.4817 16.9264 16.1765 17.1515 15.9514C17.3765 15.7264 17.6817 15.6 18 15.6C18.3183 15.6 18.6235 15.7264 18.8485 15.9514C19.0736 16.1765 19.2 16.4817 19.2 16.8Z"
        />
    </svg>

    <svg
        v-else
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5 5C4.73478 5 4.48043 5.10536 4.29289 5.29289C4.10536 5.48043 4 5.73478 4 6V10C4 10.2652 4.10536 10.5196 4.29289 10.7071C4.48043 10.8946 4.73478 11 5 11H19C19.2652 11 19.5196 10.8946 19.7071 10.7071C19.8946 10.5196 20 10.2652 20 10V6C20 5.73478 19.8946 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5H5ZM21.2361 12C21.7267 11.4515 22 10.7398 22 10V6C22 5.20435 21.6839 4.44129 21.1213 3.87868C20.5587 3.31607 19.7957 3 19 3H5C4.20435 3 3.44129 3.31607 2.87868 3.87868C2.31607 4.44129 2 5.20435 2 6V10C2 10.7398 2.27328 11.4515 2.76393 12C2.27328 12.5485 2 13.2602 2 14V18C2 18.7956 2.31607 19.5587 2.87868 20.1213C3.44129 20.6839 4.20435 21 5 21H19C19.7957 21 20.5587 20.6839 21.1213 20.1213C21.6839 19.5587 22 18.7957 22 18V14C22 13.2602 21.7267 12.5485 21.2361 12ZM19 13H5C4.73478 13 4.48043 13.1054 4.29289 13.2929C4.10536 13.4804 4 13.7348 4 14V18C4 18.2652 4.10536 18.5196 4.29289 18.7071C4.48043 18.8946 4.73478 19 5 19H19C19.2652 19 19.5196 18.8946 19.7071 18.7071C19.8946 18.5196 20 18.2652 20 18V14C20 13.7348 19.8946 13.4804 19.7071 13.2929C19.5196 13.1054 19.2652 13 19 13ZM16 8C16 7.44772 16.4477 7 17 7H17.01C17.5623 7 18.01 7.44772 18.01 8C18.01 8.55228 17.5623 9 17.01 9H17C16.4477 9 16 8.55228 16 8ZM16 16C16 15.4477 16.4477 15 17 15H17.01C17.5623 15 18.01 15.4477 18.01 16C18.01 16.5523 17.5623 17 17.01 17H17C16.4477 17 16 16.5523 16 16Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconServer",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
