<template>
    <svg
        v-if="isSolid"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            d="M3.6 1.20001C3.28174 1.20001 2.97652 1.32644 2.75147 1.55148C2.52643 1.77653 2.4 2.08175 2.4 2.40001C2.4 2.71827 2.52643 3.0235 2.75147 3.24854C2.97652 3.47358 3.28174 3.60001 3.6 3.60001H5.064L5.43 5.06641C5.43364 5.0833 5.43764 5.1001 5.442 5.11681L7.0716 11.6328L6 12.7032C4.488 14.2152 5.5584 16.8 7.6968 16.8H18C18.3183 16.8 18.6235 16.6736 18.8485 16.4485C19.0736 16.2235 19.2 15.9183 19.2 15.6C19.2 15.2818 19.0736 14.9765 18.8485 14.7515C18.6235 14.5264 18.3183 14.4 18 14.4H7.6968L8.8968 13.2H16.8C17.0228 13.1999 17.2412 13.1378 17.4306 13.0205C17.6201 12.9033 17.7732 12.7357 17.8728 12.5364L21.4728 5.33641C21.5642 5.15351 21.6073 4.95028 21.5981 4.74602C21.5889 4.54176 21.5277 4.34323 21.4202 4.16929C21.3128 3.99534 21.1626 3.85174 20.9841 3.7521C20.8055 3.65247 20.6045 3.60012 20.4 3.60001H7.536L7.164 2.10841C7.099 1.84892 6.94914 1.61858 6.73825 1.454C6.52736 1.28941 6.26752 1.20002 6 1.20001H3.6ZM19.2 19.8C19.2 20.2774 19.0104 20.7352 18.6728 21.0728C18.3352 21.4104 17.8774 21.6 17.4 21.6C16.9226 21.6 16.4648 21.4104 16.1272 21.0728C15.7896 20.7352 15.6 20.2774 15.6 19.8C15.6 19.3226 15.7896 18.8648 16.1272 18.5272C16.4648 18.1897 16.9226 18 17.4 18C17.8774 18 18.3352 18.1897 18.6728 18.5272C19.0104 18.8648 19.2 19.3226 19.2 19.8ZM7.8 21.6C8.27739 21.6 8.73523 21.4104 9.07279 21.0728C9.41036 20.7352 9.6 20.2774 9.6 19.8C9.6 19.3226 9.41036 18.8648 9.07279 18.5272C8.73523 18.1897 8.27739 18 7.8 18C7.32261 18 6.86477 18.1897 6.52721 18.5272C6.18964 18.8648 6 19.3226 6 19.8C6 20.2774 6.18964 20.7352 6.52721 21.0728C6.86477 21.4104 7.32261 21.6 7.8 21.6Z"
        />
    </svg>
    <svg
        v-else
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2 3C2 2.44772 2.44772 2 3 2H5C5.47668 2 5.8871 2.33646 5.98058 2.80388L6.2198 4H21C21.3466 4 21.6684 4.17945 21.8507 4.47427C22.0329 4.76909 22.0494 5.13723 21.8944 5.44721L17.8944 13.4472C17.725 13.786 17.3788 14 17 14H7.41421L5.41421 16H17C17.7957 16 18.5587 16.3161 19.1213 16.8787C19.6839 17.4413 20 18.2043 20 19C20 19.7957 19.6839 20.5587 19.1213 21.1213C18.5587 21.6839 17.7957 22 17 22C16.2043 22 15.4413 21.6839 14.8787 21.1213C14.3161 20.5587 14 19.7957 14 19C14 18.6561 14.0591 18.3182 14.1716 18H9.82843C9.94094 18.3182 10 18.6561 10 19C10 19.7957 9.68393 20.5587 9.12132 21.1213C8.55871 21.6839 7.79565 22 7 22C6.20435 22 5.44129 21.6839 4.87868 21.1213C4.31607 20.5587 4 19.7957 4 19C4 18.5359 4.10754 18.0829 4.3088 17.6743C3.32906 17.0242 3.04025 15.5455 3.99989 14.5859L5.91446 12.6713L4.1802 4H3C2.44772 4 2 3.55228 2 3ZM7.8198 12H16.382L19.382 6H6.6198L7.8198 12ZM7 18C6.73478 18 6.48043 18.1054 6.29289 18.2929C6.10536 18.4804 6 18.7348 6 19C6 19.2652 6.10536 19.5196 6.29289 19.7071C6.48043 19.8946 6.73478 20 7 20C7.26522 20 7.51957 19.8946 7.70711 19.7071C7.89464 19.5196 8 19.2652 8 19C8 18.7348 7.89464 18.4804 7.70711 18.2929C7.51957 18.1054 7.26522 18 7 18ZM17 18C16.7348 18 16.4804 18.1054 16.2929 18.2929C16.1054 18.4804 16 18.7348 16 19C16 19.2652 16.1054 19.5196 16.2929 19.7071C16.4804 19.8946 16.7348 20 17 20C17.2652 20 17.5196 19.8946 17.7071 19.7071C17.8946 19.5196 18 19.2652 18 19C18 18.7348 17.8946 18.4804 17.7071 18.2929C17.5196 18.1054 17.2652 18 17 18Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconShoppingCart",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
