<template>
    <ChargemapInput
        v-number="numberFormat"
        data-testid="chargemap-number-input"
        :model-value="modelValue"
        :no-x="true"
        v-bind="$attrs"
        @update:model-value="updateValue"
    />
</template>

<script>
import { directive as VNumber } from "@coders-tm/vue-number-format";
import isString from "../../../utils/shared/isString";
import ChargemapInput from "./ChargemapInput.vue";
export default {
  name: "ChargemapNumberInput",
  directives: {
    number: VNumber
  },
  components: { ChargemapInput },
  props: {
    modelValue: {
      type: [String, Number],
      default: ""
    },
    decimal: {
      type: String,
      default: ","
    },
    separator: {
      type: String,
      default: ""
    },
    precision: {
      type: Number,
      default: 2
    },
    min: {
      type: Number,
      default: void 0
    },
    max: {
      type: Number,
      default: void 0
    }
  },
  emits: ["update:modelValue", "formattedValueUpdate"],
  computed: {
    getNumberSeparator() {
      return this.$n(1.3).slice(1, 2);
    },
    numberFormat() {
      return {
        decimal: this.decimal,
        separator: this.separator,
        precision: this.precision,
        min: this.min,
        max: this.max
      };
    }
  },
  methods: {
    updateValue(value) {
      const val = !isString(value) ? value.value : value;
      const parsable = val.replaceAll(this.getNumberSeparator, ".");
      const regex = /[^0-9.,]/;
      const cleaned = parsable.replace(regex, "");
      const numberValue = parseFloat(cleaned.replace(",", "."));
      this.$emit(
        "formattedValueUpdate",
        Intl.NumberFormat("en-US").format(numberValue)
      );
      this.$emit("update:modelValue", numberValue);
    }
  }
};
</script>
