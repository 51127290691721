<template>
    <div
        class="fixed flex h-full items-center p-6 justify-center inset-0 bg-gray-600 bg-opacity-20 z-50"
        data-testid="chargemap-modal"
        @click.once="clickedOutside"
    >
        <div
            class="relative bg-white rounded-2xl shadow-md overflow-auto max-h-[95vh]"
            :class="[
                size === 'sm' && 'w-2/3 max-w-3xl',
                size === 'md' && 'w-11/12 md:w-4/6 max-w-3xl',
                size === 'lg' && 'w-11/12 md:w-10/12 max-w-6xl',
                size === 'xl' && 'w-full h-full',
                !noPadding && 'p-6',
                noScroll ? 'overflow-visible' : 'overflow-auto'
            ]"
        >
            <div
                class="flex flex-col justify-between gap-6 h-full overflow-auto"
                :class="[
                    !noPadding && 'p-1',
                    noScroll ? 'overflow-visible' : 'overflow-auto'
                ]"
            >
                <div
                    v-if="$slots.title"
                    class="text-xl font-medium leading-6 tracking-light text-gray-900"
                    :class="[textCenter && 'text-center']"
                >
                    <slot name="title" />
                </div>
                <div
                    class="flex flex-col gap-6 h-full"
                    :class="[textCenter && 'text-center']"
                >
                    <slot />
                </div>
                <div v-if="$slots.actions" class="flex justify-end space-x-2">
                    <slot name="actions" />
                </div>
            </div>
            <div
                v-if="showX"
                data-testid="chargemap-modal-x"
                class="absolute right-4 top-4 z-20 cursor-pointer h-10 w-10 bg-white p-1 rounded-lg text-zinc-500 hover:text-zinc-700 flex items-center justify-center transition-all duration-300 ease-in-out"
                :class="[
                    clearX ??
                        'shadow border border-opacity-20 border-black hover:bg-gray-100'
                ]"
                @click="closeModal"
            >
                <IconX class="h-6 w-6" />
            </div>
        </div>
    </div>
</template>

<script>
import IconX from "../../icons/IconX.vue";
export default {
  name: "ChargemapModal",
  components: {
    IconX
  },
  props: {
    size: {
      type: String,
      default: "md",
      validator(value) {
        return ["sm", "md", "lg", "xl"].includes(value);
      }
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    textCenter: {
      type: Boolean,
      default: false
    },
    showX: {
      type: Boolean,
      default: false
    },
    noScroll: {
      type: Boolean,
      default: false
    },
    clearX: {
      type: Boolean,
      default: false
    }
  },
  emits: ["updateIsVisible", "clickedOutside"],
  mounted() {
    document.addEventListener(
      "keyup",
      (event) => this.closeModalWhenEscape(event)
    );
  },
  beforeUnmount() {
    document.removeEventListener(
      "keyup",
      (event) => this.closeModalWhenEscape(event)
    );
  },
  methods: {
    closeModal() {
      this.$emit("updateIsVisible", false);
    },
    closeModalWhenEscape(event) {
      if (event.key === "Escape") {
        this.closeModal();
      }
    },
    clickedOutside() {
      this.$emit("clickedOutside");
    }
  }
};
</script>
