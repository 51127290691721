<template>
    <div class="flex flex-col gap-4 mb-2" data-testid="feedback-rating">
        <div
            v-if="isAnonymous"
            class="text-gray-500 font-normal text-fifteen-exploitation leading-4"
        >
            {{ $t('anonymous.has_rated') }}
        </div>
        <div
            v-else
            class="text-gray-800 font-medium text-fifteen-exploitation leading-4"
        >
            {{ getUsername }}
            <span class="text-gray-500 font-normal">{{ $t('has_rated') }}</span>
        </div>
        <div>
            <PoolRating
                :class="{ 'cursor-pointer': isNewFeedback }"
                :rating="getFormattedGlobalScore"
                :display-rating-as-text="false"
                @click="onClickRating"
            />
        </div>
    </div>
    <RatingDetailPopup
        v-if="isNewFeedback"
        ref="ratingDetailPopup"
        :global-score-average="getFormattedGlobalScore"
        :location-score-average="getLocationScore"
        :material-score-average="getMaterialScore"
        :price-score-average="getPriceScore"
        :security-score-average="getSecurityScore"
        :cdnUrl
    >
        <template #title>
            {{ getPopupTitle }}
        </template>
    </RatingDetailPopup>
</template>

<script>
import PoolRating from "../rating/PoolRating.vue";
import RatingDetailPopup from "../rating/RatingDetailPopup.vue";
export default {
  name: "FeedbackRating",
  components: { PoolRating, RatingDetailPopup },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      }
    },
    isAnonymous: {
      type: Boolean,
      required: true
    },
    rating: {
      type: Object,
      required: true
    },
    cdnUrl: {
      type: String,
      default: ""
    }
  },
  computed: {
    getGlobalScore() {
      return this.rating?.globalScore;
    },
    getFormattedGlobalScore() {
      return this.formatScore(this.rating.globalScore);
    },
    getMaterialScore() {
      return this.rating?.materialScore && this.formatScore(this.rating.materialScore) || "";
    },
    getPriceScore() {
      return this.rating?.priceScore && this.formatScore(this.rating.priceScore) || "";
    },
    getLocationScore() {
      return this.rating?.locationScore && this.formatScore(this.rating.locationScore) || "";
    },
    getSecurityScore() {
      return this.rating?.securityScore && this.formatScore(this.rating.securityScore) || "";
    },
    fullStars() {
      return this.getGlobalScore ? Math.min(5, Math.floor(this.getGlobalScore)) : 0;
    },
    emptyStars() {
      if (!this.getGlobalScore) {
        return 5;
      }
      return this.hasAHalfStar ? 5 - this.fullStars - 1 : 5 - this.fullStars;
    },
    hasAHalfStar() {
      return this.getGlobalScore ? this.getGlobalScore - this.fullStars > 0.5 : false;
    },
    getUsername() {
      return this.user?.username;
    },
    isNewFeedback() {
      return this.getMaterialScore && this.getPriceScore && this.getLocationScore && this.getSecurityScore;
    },
    getPopupTitle() {
      return this.isAnonymous ? this.$t("evaluation") : this.$t("user_evaluation", { user: this.user.username });
    }
  },
  methods: {
    formatScore(score) {
      return (Math.round(score * 100) / 100).toPrecision(2);
    },
    onClickRating() {
      if (this.isNewFeedback) {
        ;
        this.$refs.ratingDetailPopup.show();
      }
    }
  }
};
</script>
