import { createPersistedState } from "pinia-plugin-persistedstate";
import { defineNuxtPlugin, useRuntimeConfig } from "#app";
import { persistedState } from "#imports";
export default defineNuxtPlugin((nuxtApp) => {
  const {
    cookieOptions,
    debug,
    storage
  } = useRuntimeConfig().public.persistedState;
  const pinia = nuxtApp.$pinia;
  pinia.use(createPersistedState({
    storage: storage === "cookies" ? persistedState.cookiesWithOptions(cookieOptions) : persistedState[storage],
    debug
  }));
});
