<template>
    <div>
        <div
            class="flex flex-row justify-between cursor-pointer p-6 items-center"
            data-testid="pool-connector-details"
            @click="toggleExpanded"
        >
            <div class="flex flex-row gap-2 items-center">
                <img
                    :alt="connectorType.label"
                    :src="getConnectorImgUrl"
                    draggable="false"
                    class="w-12 h-12"
                    loading="lazy"
                />
                <div>
                    <div class="text-zinc-500 text-xs">
                        {{ connectorType.label }}
                    </div>
                    <p class="text-zinc-800">{{ powerConnector }} kW max</p>
                </div>
            </div>
            <div class="flex flex-row gap-4 items-center">
                <ChargemapBadge
                    :type="getBadgeType"
                    size="extra-large"
                    class="w-fit"
                    custom-text-size="text-lg leading-tight"
                >
                    <div v-if="isAllStatic">
                        <span class="font-semibold">
                            x {{ totalConnectorsCount }}
                        </span>
                    </div>
                    <div v-else-if="isAllUnknown">
                        <span class="font-semibold">
                            ? / {{ totalConnectorsCount }}
                        </span>
                    </div>
                    <div v-else>
                        <span class="font-semibold">
                            {{ availableConnectorsCount }}
                        </span>
                        <span>&nbsp;/ {{ totalConnectorsCount }}</span>
                    </div>
                </ChargemapBadge>
                <IconChevronDown
                    class="w-7 h-7 transition-all duration-500 text-zinc-400"
                    :class="{ expanded: expanded }"
                />
            </div>
        </div>

        <div
            class="transition-all duration-500 ease-in-out flex flex-col overflow-hidden"
            :style="{
                'max-height': expanded ? `${connectorLinesHeight}px` : '0'
            }"
        >
            <span class="text-zinc-500 pl-6 text-xs font-normal">
                {{ $t('connector') }}
            </span>
            <div
                v-for="connectorStatus in orderedConnectors"
                :key="connectorStatus.realtimeStatus?.toString()"
                class="text-xs font-medium"
            >
                <ConnectorLine
                    v-for="id in connectorStatus.ids"
                    :id="id"
                    :key="id"
                    :realtime-state="connectorStatus.realtimeStatus"
                />
            </div>
            <div ref="connectorsList" />
        </div>
    </div>
</template>

<script>
import { BadgeType } from "../../../interfaces/UI";
import { PoolRealtimeState } from "../../../interfaces/pool";
import IconChevronDown from "../../icons/IconChevronDown.vue";
import ChargemapBadge from "../global/ChargemapBadge.vue";
import ConnectorLine from "./ConnectorLine.vue";
export default {
  name: "PoolConnectorDetails",
  components: { ChargemapBadge, IconChevronDown, ConnectorLine },
  props: {
    connector: {
      type: Object,
      required: true
    },
    totalConnectorsCount: {
      type: Number,
      required: true
    },
    availableConnectorsCount: {
      type: Number,
      required: true
    },
    powerConnector: {
      type: Number,
      required: true
    },
    connectorType: {
      type: Object,
      required: true
    },
    isAllStatic: {
      type: Boolean,
      default: false
    },
    isAllFaulty: {
      type: Boolean,
      default: false
    },
    isAllUnknown: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    legacyApiUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      expanded: this.isOpen,
      connectorList: this.$refs.connectorsList
    };
  },
  computed: {
    getConnectorImgUrl() {
      return `${this.legacyApiUrl}/medias/v2/connector_types/icon/original/${this.connectorType.id}/${this.connectorType.icon}` || "";
    },
    getBadgeType() {
      if (this.isAllFaulty) {
        return BadgeType.ERROR;
      }
      if (this.isAllStatic || this.availableConnectorsCount === 0) {
        return BadgeType.NEUTRAL;
      }
      if (this.isAllUnknown) {
        return BadgeType.WARNING;
      }
      if (this.availableConnectorsCount > 0) {
        return BadgeType.SUCCESS;
      }
      return BadgeType.NEUTRAL;
    },
    orderedConnectors() {
      const connectors = this.connector.realtimeStatusCount.map(
        (el) => el
      );
      const cons = connectors.sort((a, b) => {
        let aValue = 6;
        let bValue = 6;
        if (a.realtimeStatus === PoolRealtimeState.AVAILABLE) {
          aValue = 5;
        }
        if (b.realtimeStatus === PoolRealtimeState.AVAILABLE) {
          bValue = 5;
        }
        if (a.realtimeStatus === PoolRealtimeState.UNAVAILABLE) {
          aValue = 4;
        }
        if (b.realtimeStatus === PoolRealtimeState.UNAVAILABLE) {
          bValue = 4;
        }
        if (!a.realtimeStatus) {
          aValue = 3;
        }
        if (!b.realtimeStatus) {
          bValue = 3;
        }
        if (a.realtimeStatus === PoolRealtimeState.FAULTED || a.realtimeStatus === PoolRealtimeState.OUT_OF_ORDER) {
          aValue = 2;
        }
        if (b.realtimeStatus === PoolRealtimeState.FAULTED || b.realtimeStatus === PoolRealtimeState.OUT_OF_ORDER) {
          bValue = 2;
        }
        if (a.realtimeStatus === PoolRealtimeState.UNKNOWN) {
          aValue = 1;
        }
        if (b.realtimeStatus === PoolRealtimeState.UNKNOWN) {
          bValue = 1;
        }
        return aValue < bValue ? 1 : -1;
      });
      return cons;
    },
    connectorLinesHeight() {
      if (!this.totalConnectorsCount) {
        return 0;
      }
      return this.totalConnectorsCount * 70 + 24;
    }
  },
  watch: {
    isOpen(value) {
      if (value) {
        if (!this.isAllStatic && !this.isAllUnknown) {
          this.expanded = true;
        }
        this.$nextTick(() => {
          this.connectorList.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
          });
        });
      }
    }
  },
  mounted() {
    this.connectorList = this.$refs.connectorsList;
  },
  methods: {
    getConnectorStatusCount(connectorStatus) {
      return connectorStatus.count;
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
    }
  }
};
</script>

<style scoped>
.expanded {
  transform: rotate(-180deg);
}
</style>
