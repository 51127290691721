<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 40"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.125 5C2.43464 5 1.875 5.55964 1.875 6.25C1.875 6.94036 2.43464 7.5 3.125 7.5H8.29509C8.61731 7.5 8.88672 7.74497 8.91727 8.06574L10.8374 28.2273C11.0513 30.4727 12.9371 32.1875 15.1927 32.1875H30C30.6904 32.1875 31.25 31.6279 31.25 30.9375C31.25 30.2471 30.6904 29.6875 30 29.6875H15.1927C14.226 29.6875 13.4178 28.9526 13.3262 27.9903L13.234 27.0222L30.7549 25.5621C32.5592 25.4117 34.0846 24.1654 34.5915 22.4272L37.6583 11.9125C37.8917 11.1125 37.2917 10.3125 36.4583 10.3125H18.7653C18.7561 10.3124 18.7469 10.3124 18.7376 10.3125H11.6426L11.406 7.82872C11.2533 6.22486 9.90621 5 8.29509 5H3.125ZM12.4297 12.8125H17.6109L18.0317 17.3368L12.8627 17.4888L12.4297 12.8125ZM13.0936 19.9831L13.511 24.4904L18.6573 24.0616L18.2638 19.831L13.0936 19.9831ZM20.7677 19.7574L21.1488 23.8539L25.9203 23.4563L25.718 19.6118L20.7677 19.7574ZM30.5473 23.0707L28.4128 23.2486L28.2176 19.5383L32.8699 19.4014L32.1915 21.7272C31.9743 22.4722 31.3206 23.0063 30.5473 23.0707ZM33.6057 16.8787L28.0861 17.041L27.8636 12.8125H34.7917L33.6057 16.8787ZM20.1217 12.8125H25.3601L25.5865 17.1146L20.5357 17.2631L20.1217 12.8125ZM16.5625 37.1875C17.7706 37.1875 18.75 36.2081 18.75 35C18.75 33.7919 17.7706 32.8125 16.5625 32.8125C15.3544 32.8125 14.375 33.7919 14.375 35C14.375 36.2081 15.3544 37.1875 16.5625 37.1875ZM33.75 35C33.75 36.2081 32.7706 37.1875 31.5625 37.1875C30.3544 37.1875 29.375 36.2081 29.375 35C29.375 33.7919 30.3544 32.8125 31.5625 32.8125C32.7706 32.8125 33.75 33.7919 33.75 35Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconShop"
};
</script>
