<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.07 4.93C17.19 3.05 14.65 2 12 2C9.35 2 6.8 3.05 4.93 4.93C3.05 6.8 2 9.35 2 12C2 14.65 3.05 17.2 4.93 19.07C6.81 20.95 9.35 22 12 22C14.65 22 17.2 20.95 19.07 19.07C20.95 17.19 22 14.65 22 12C22 9.35 20.95 6.8 19.07 4.93ZM6.34 6.34C7.17 5.51 8.15 4.9 9.23 4.5H9.24C9.24 4.5 9.24 4.5 9.23 4.5C9.12 4.76 9.02 5.04 8.92 5.32C8.41 6.84 8.09 8.83 8.01 11H4.06C4.28 9.24 5.08 7.6 6.34 6.34ZM6.35 17.66C5.09 16.39 4.29 14.75 4.07 13H8.03C8.1 15.17 8.43 17.16 8.94 18.68C9.03 18.97 9.14 19.25 9.25 19.51C8.17 19.12 7.18 18.49 6.35 17.66ZM13.17 18.05C12.92 18.81 12.64 19.35 12.38 19.68C12.25 19.84 12.15 19.93 12.08 19.97C12.05 19.99 12.02 20 12.01 20H11.99C11.99 20 11.96 20 11.92 19.97C11.85 19.93 11.75 19.84 11.62 19.68C11.36 19.35 11.08 18.81 10.83 18.05C10.4 16.76 10.1 15 10.02 13H13.98C13.9 15 13.6 16.76 13.17 18.05ZM10.03 11C10.11 9 10.41 7.24 10.84 5.95C11.09 5.19 11.37 4.65 11.63 4.32C11.76 4.16 11.86 4.07 11.93 4.03C11.96 4.01 11.99 4 12 4H12.02C12.02 4 12.05 4.01 12.09 4.03C12.16 4.07 12.26 4.16 12.39 4.32C12.65 4.65 12.93 5.19 13.18 5.95C13.61 7.24 13.91 9 13.99 11H10.03ZM17.65 6.34C18.91 7.6 19.71 9.24 19.93 11H15.97C15.89 8.83 15.57 6.84 15.06 5.32C14.96 5.03 14.86 4.75 14.75 4.49C15.83 4.89 16.82 5.51 17.65 6.34ZM17.66 17.66C16.83 18.49 15.85 19.1 14.77 19.5C14.77 19.5 14.77 19.5 14.78 19.5C14.89 19.24 14.99 18.96 15.09 18.68C15.6 17.16 15.92 15.17 16 13H19.95C19.73 14.76 18.93 16.4 17.67 17.66H17.66Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconWorld"
};
</script>
