<template>
    <div
        data-testid="pool-connectors-list"
        class="relative"
        :class="{
            'select-none': isMousePressed
        }"
    >
        <div
            ref="connectorsList"
            class="hidden-scrollbar w-full flex gap-2.5 min-h-14 overflow-y-auto pl-2 pr-4 md:pr-6 cursor-grab"
            :class="{
                'cursor-grabbing': isMousePressed,
                'scroll-smooth': !isMousePressed
            }"
            @mousedown="startDragging"
            @touchstart="startDragging"
            @mouseup="stopDragging"
            @mouseleave="mouseLeave"
            @scroll="scroll"
            @touchend="stopDragging"
            @mousemove="move"
            @touchmove="move"
        >
            <div
                v-if="loadingStations"
                class="flex-shrink-0 px-4 gap-3 select-none flex-row flex"
            >
                <ChargemapSkeletonLoader
                    v-for="index in 5"
                    :key="index"
                    class="h-16 w-24 flex items-stretch justify-center rounded-lg"
                />
            </div>
            <div
                v-for="poolConnector of orderedConnectors"
                v-else
                :key="`${poolConnector.id}`"
                class="flex-shrink-0 p-1"
            >
                <PoolConnector
                    v-if="getConnectorType(poolConnector) !== null"
                    :total-connectors-count="getConnectorsCount(poolConnector)"
                    :available-connectors-count="
                        getAvailableConnectorsCount(poolConnector)
                    "
                    :is-all-static="isAllStatic(poolConnector)"
                    :is-all-unknown="isAllUnknown(poolConnector)"
                    :is-all-faulty="isAllFaulty(poolConnector)"
                    :power-connector="getPower(poolConnector)"
                    :connector-type="getConnectorType(poolConnector)"
                    :legacy-api-url="legacyApiUrl"
                    :disabled
                    :isB2B
                    @click="connectorClicked(poolConnector)"
                />
            </div>
        </div>
        <button
            class="w-8 h-8 hidden md:flex left-1.5 top-4 absolute bg-zinc-100 rounded-full justify-center items-center shadow transition-all duration-500 ease-in-out"
            :style="{
                opacity: canScrollLeft ? 1 : 0,
                pointerEvents: canScrollLeft ? 'all' : 'none'
            }"
            @click="scrollLeft"
        >
            <IconChevronLeft class="w-5 h-5"/>
        </button>
        <button
            class="w-8 h-8 hidden md:flex right-2.5 top-4 absolute bg-zinc-100 rounded-full justify-center items-center shadow transition-all duration-500 ease-in-out"
            :style="{
                opacity: canScrollRight ? 1 : 0,
                pointerEvents: canScrollRight ? 'all' : 'none'
            }"
            @click="scrollRight"
        >
            <IconChevronRight class="w-5 h-5"/>
        </button>
    </div>
</template>

<script>
import { PoolRealtimeState } from "../../../interfaces/pool";
import PoolConnector from "../pool/PoolConnector.vue";
import IconChevronRight from "../../icons/IconChevronRight.vue";
import IconChevronLeft from "../../icons/IconChevronLeft.vue";
import ChargemapSkeletonLoader from "./ChargemapSkeletonLoader.vue";
export default {
  name: "ConnectorsCarousel",
  components: {
    PoolConnector,
    IconChevronRight,
    IconChevronLeft,
    ChargemapSkeletonLoader
  },
  props: {
    poolConnectors: {
      type: Array,
      required: true
    },
    legacyApiUrl: {
      type: String,
      required: true
    },
    loadingStations: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isB2B: {
      type: Boolean,
      default: true
    }
  },
  emits: ["connectorClicked"],
  data() {
    return {
      isMousePressed: false,
      isMouseDragging: false,
      scrollLeftValue: 0,
      startX: 0,
      connectorsList: this.$refs.connectorsList,
      canScrollRight: true,
      canScrollLeft: false,
      timeout: null
    };
  },
  computed: {
    orderedConnectors() {
      const connectors = this.poolConnectors.map((el) => el);
      return connectors.sort((a, b) => a.power < b.power ? 1 : -1).sort((a, b) => {
        let aValue = 5;
        let bValue = 5;
        if (this.getAvailableConnectorsCount(a) === 0) {
          aValue = 4;
        }
        if (this.getAvailableConnectorsCount(b) === 0) {
          bValue = 4;
        }
        if (this.isAllStatic(a)) {
          aValue = 3;
        }
        if (this.isAllFaulty(a)) {
          aValue = 2;
        }
        if (this.isAllUnknown(a)) {
          aValue = 1;
        }
        if (this.isAllStatic(b)) {
          bValue = 3;
        }
        if (this.isAllFaulty(b)) {
          bValue = 2;
        }
        if (this.isAllUnknown(b)) {
          bValue = 1;
        }
        return aValue < bValue ? 1 : -1;
      });
    }
  },
  mounted() {
    this.connectorsList = this.$refs.connectorsList;
    this.calculateCanScroll();
    window.addEventListener("resize", () => this.calculateCanScroll());
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => this.calculateCanScroll());
  },
  methods: {
    getConnectorsCount(poolConnector) {
      return poolConnector.realtimeStatusCount?.reduce(
        (total, current) => total + current.count,
        0
      ) || 0;
    },
    calculateCanScroll(scrollValue = 0) {
      this.canScrollRight = this.connectorsList.scrollLeft + scrollValue + this.connectorsList.clientWidth <= this.connectorsList.scrollWidth && this.poolConnectors.length > 4 && !this.loadingStations;
      this.canScrollLeft = this.connectorsList.scrollLeft + scrollValue > 0 && this.poolConnectors.length > 4 && !this.loadingStations;
    },
    getAvailableConnectorsCount(poolConnector) {
      return poolConnector.realtimeStatusCount?.filter(
        (realtimeStatusCount) => realtimeStatusCount?.realtimeStatus === PoolRealtimeState.AVAILABLE
      )[0]?.count || 0;
    },
    isAllStatic(poolConnector) {
      return !poolConnector.realtimeStatusCount.some(
        (realtimeStatus) => realtimeStatus.realtimeStatus
      );
    },
    isAllFaulty(poolConnector) {
      return poolConnector.realtimeStatusCount?.filter(
        (realtimeStatusCount) => realtimeStatusCount?.realtimeStatus === PoolRealtimeState.FAULTED || realtimeStatusCount?.realtimeStatus === PoolRealtimeState.OUT_OF_ORDER
      ).reduce((total, current) => total + current.count, 0) === this.getConnectorsCount(poolConnector);
    },
    isAllUnknown(poolConnector) {
      return poolConnector.realtimeStatusCount?.filter(
        (realtimeStatusCount) => realtimeStatusCount?.realtimeStatus === PoolRealtimeState.UNKNOWN
      ).reduce((total, current) => total + current.count, 0) === this.getConnectorsCount(poolConnector);
    },
    getPower(poolConnector) {
      return poolConnector.power || 0;
    },
    getConnectorType(poolConnector) {
      return poolConnector.connectorType;
    },
    scroll(e) {
      this.calculateCanScroll(e.target.scrollLeft);
    },
    startDragging(e) {
      this.isMousePressed = true;
      this.startX = e.clientX || e.touches?.[0].clientX - this.connectorsList.offsetLeft;
      this.scrollLeftValue = this.connectorsList.scrollLeft;
    },
    mouseLeave() {
      this.isMousePressed = false;
      this.isMouseDragging = false;
    },
    stopDragging() {
      this.isMousePressed = false;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.isMouseDragging = false;
      }, 1);
    },
    move(e) {
      if (!this.isMousePressed) {
        return;
      }
      const x = e.clientX || e.touches?.[0].clientX - this.connectorsList.offsetLeft;
      const scroll = x - this.startX;
      if (x !== this.startX) {
        this.isMouseDragging = true;
      } else {
        return;
      }
      this.connectorsList.scrollLeft = this.scrollLeftValue - scroll;
      this.calculateCanScroll(-scroll);
    },
    scrollRight(e) {
      e.stopPropagation();
      this.connectorsList.scrollLeft += 100;
      this.calculateCanScroll(100);
    },
    scrollLeft(e) {
      e.stopPropagation();
      this.connectorsList.scrollLeft -= 100;
      this.calculateCanScroll(-100);
    },
    connectorClicked(connector) {
      if (!this.isMouseDragging) {
        this.$emit("connectorClicked", connector);
      }
    }
  }
};
</script>

<style scoped>
.hidden-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-overflow-scrolling: touch;
}
.hidden-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0px;
  display: none;
}
</style>
