<template>
    <svg
        v-if="isSolid"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.56 1.25522C13.8036 1.33183 14.0164 1.48416 14.1675 1.69005C14.3186 1.89594 14.4 2.14465 14.4 2.40002V8.40002H19.2C19.4195 8.39992 19.6348 8.46003 19.8225 8.57381C20.0103 8.68759 20.1632 8.85067 20.2646 9.04531C20.3661 9.23995 20.4122 9.4587 20.398 9.67774C20.3838 9.89678 20.3098 10.1077 20.184 10.2876L11.784 22.2876C11.6378 22.4971 11.4286 22.6544 11.1868 22.7367C10.945 22.8191 10.6833 22.8221 10.4396 22.7454C10.196 22.6687 9.9832 22.5163 9.83219 22.3103C9.68118 22.1043 9.59984 21.8554 9.6 21.6V15.6H4.8C4.5805 15.6001 4.36517 15.54 4.17746 15.4262C3.98975 15.3125 3.83684 15.1494 3.73537 14.9547C3.6339 14.7601 3.58777 14.5413 3.60198 14.3223C3.6162 14.1033 3.69022 13.8923 3.816 13.7124L12.216 1.71242C12.3624 1.50336 12.5717 1.34643 12.8134 1.26443C13.0551 1.18243 13.3166 1.17962 13.56 1.25642V1.25522Z"
        />
    </svg>
    <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.7816 2.74418C18.3124 2.93364 18.6667 3.43635 18.6667 3.99991V11.9999H26.6667C27.1819 11.9999 27.651 12.2967 27.8716 12.7623C28.0922 13.2278 28.0249 13.7788 27.6986 14.1776L15.6986 28.8442C15.3418 29.2804 14.7492 29.4451 14.2184 29.2556C13.6877 29.0662 13.3333 28.5635 13.3333 27.9999V19.9999H5.33335C4.81816 19.9999 4.34907 19.7031 4.12846 19.2375C3.90784 18.772 3.97517 18.221 4.3014 17.8223L16.3014 3.15559C16.6583 2.71942 17.2508 2.55472 17.7816 2.74418ZM8.14701 17.3332H14.6667C15.4031 17.3332 16 17.9302 16 18.6666V24.2647L23.853 14.6666H17.3333C16.597 14.6666 16 14.0696 16 13.3332V7.73512L8.14701 17.3332Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconLightningBolt",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
