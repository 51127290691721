<template>
    <ChargemapButton btn-alt="white" data-testid="chargemap-filter-button">
        <template #icon>
            <IconFilter class="w-5 h-5 text-slate-500" />
        </template>
        <div class="flex flex-row gap-2 text-slate-500">
            {{ $t('filters.filters') }}

            <div
                v-if="filtersCount > 0"
                class="h-6 px-2 bg-primary-100 font-medium text-primary-800 rounded-sm"
            >
                {{ filtersCount }}
            </div>

            <IconX
                v-if="filtersCount > 0 && !readOnly"
                class="w-6 h-6 transition-colors duration-300 ease-in-out select-all pointer-events-auto"
                aria-roledescription="button"
                tabindex="0"
                :is-solid="xButtonHovered"
                :class="xButtonHovered ? 'text-red-500' : 'text-slate-500'"
                @mouseenter="xButtonHovered = true"
                @mouseleave="xButtonHovered = false"
                @click="clearFilters"
                @click.stop
            />
        </div>
    </ChargemapButton>
</template>

<script>
import IconFilter from "../../icons/IconFilter.vue";
import IconX from "../../icons/IconX.vue";
import ChargemapButton from "./ChargemapButton.vue";
export default {
  name: "ChargemapFilterButton",
  components: {
    ChargemapButton,
    IconFilter,
    IconX
  },
  props: {
    filtersCount: {
      type: Number,
      default: 0
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ["clearFilters"],
  data() {
    return {
      xButtonHovered: false
    };
  },
  methods: {
    clearFilters() {
      this.$emit("clearFilters");
    }
  }
};
</script>
