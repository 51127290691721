<!-- eslint-disable vue/no-v-html -->
<template>
    <div data-testid="drawer-info-description">
        <DrawerInfoCard>
            <div class="flex flex-col items-start gap-1">
                <span
                    ref="content"
                    class="content text-ellipsis"
                    :class="{
                        'max-h-[18px] overflow-hidden': !displayEverything
                    }"
                    :style="displayEverything ? '' : '-webkit-line-clamp: 3'"
                    v-html="formattedDescription"
                />
                <button
                    v-if="!displayEverything"
                    class="show-button"
                    @click="onClickUnfold"
                >
                    {{ $t('show.more') }}
                </button>
                <button
                    v-if="hasClickedToUnfold"
                    class="show-button"
                    @click="onClickFold"
                >
                    {{ $t('show.less') }}
                </button>
            </div>
        </DrawerInfoCard>
    </div>
</template>

<script>
import DrawerInfoCard from "./DrawerInfoCard.vue";
export default {
  name: "DrawerInfoDescription",
  components: { DrawerInfoCard },
  props: {
    description: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      hasClickedToUnfold: false,
      hasSmallContentHeight: false
    };
  },
  computed: {
    displayEverything() {
      return this.hasSmallContentHeight || this.hasClickedToUnfold;
    },
    formattedDescription() {
      return this.description.replace(/\n/g, "<br />");
    }
  },
  mounted() {
    if (!this.$refs.content) return;
    this.hasSmallContentHeight = this.$refs.content.scrollHeight <= this.$refs.content.clientHeight;
  },
  methods: {
    onClickUnfold() {
      this.hasClickedToUnfold = true;
    },
    onClickFold() {
      this.hasClickedToUnfold = false;
    }
  }
};
</script>

<style>
.content {
  @apply font-normal tracking-light text-left text-gray-l-800 break-words;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.show-button {
  @apply text-sky-700 font-semibold cursor-pointer leading-tight;
}
</style>
