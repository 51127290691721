<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.10204 7.03572H1.25V14.0612H9.10204V7.03572ZM5.17604 12.3699C6.20312 12.3699 7.03573 11.5373 7.03573 10.5102C7.03573 9.48312 6.20312 8.6505 5.17604 8.6505C4.14896 8.6505 3.31635 9.48312 3.31635 10.5102C3.31635 11.5373 4.14896 12.3699 5.17604 12.3699Z"
        />
        <path
            d="M1.66327 14.4745H2.90307V20.4668C2.90307 20.8092 2.62553 21.0867 2.28317 21.0867C1.94081 21.0867 1.66327 20.8092 1.66327 20.4668V14.4745Z"
        />
        <path
            d="M7.44898 14.4745H8.68878V20.4668C8.68878 20.8092 8.41124 21.0867 8.06888 21.0867C7.72652 21.0867 7.44898 20.8092 7.44898 20.4668V14.4745Z"
        />
        <path d="M2.48979 15.7143H8.27551V16.9541H2.48979V15.7143Z" />
        <path d="M2.48979 18.1939H8.27551V19.4337H2.48979V18.1939Z" />
        <path
            d="M5.38266 2.43718L8.27551 1.53316L4.96938 0.5L4.9694 3.62925L0.125 6.5H10.25L5.38266 3.61565V2.43718Z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.91524 13.95H9.51531V11.1684H9.91524C10.9746 11.1684 11.9891 11.58 12.7281 12.3099L18.9134 18.418C19.1079 18.6101 19.3749 18.7184 19.6537 18.7184H20.1092C20.8773 18.7184 21.5 19.3411 21.5 20.1092C21.5 20.8773 20.8773 21.5 20.1092 21.5H19.6537C18.5943 21.5 17.5798 21.0883 16.8408 20.3585L10.6555 14.2504C10.461 14.0583 10.194 13.95 9.91524 13.95Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconPark"
};
</script>
