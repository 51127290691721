<template>
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.796 0H2.20415C0.747558 0 0 0.747551 0 2.20408V10.102H6.63439C7.04946 10.102 7.52516 10.6751 7.52516 10.9984L7.50857 14.8996C7.64459 15.0392 7.96787 15.131 8.1037 15.131C8.28746 15.131 8.49499 15.0833 8.63267 14.9914V8.76673C7.24777 8.47286 6.4287 6.96857 6.4287 4.59184H7.16336V2.84694C7.16336 2.69449 7.28648 2.57143 7.4389 2.57143H7.9898C8.14241 2.57143 8.26534 2.69449 8.26534 2.84694V4.59184H10.1022V2.84694C10.1022 2.69449 10.2251 2.57143 10.3777 2.57143H10.9286C11.081 2.57143 11.2042 2.69449 11.2042 2.84694V4.59184H11.7827C11.7827 6.86388 11.0151 8.33694 9.73484 8.72082V15.0392C9.73484 15.9447 8.88444 16.2404 8.08177 16.2257C7.12281 16.2092 6.4287 15.9576 6.4287 15.0612V11.1729L0 11.1909V15.7959C0 17.2526 0.747558 18 2.20415 18H15.796C17.2526 18 18 17.2526 18 15.7959V2.20408C18 0.747551 17.2526 0 15.796 0Z"
            fill="#22BCFF"
        />
    </svg>
</template>

<script>
export default {
  name: "IconChargemap"
};
</script>
