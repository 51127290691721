import { useCookie, useNuxtApp } from "#app";
function usePersistedstateCookies(cookieOptions) {
  return {
    getItem: (key) => cookieOptions?.readonly ? useCookie(key, {
      ...cookieOptions,
      encode: encodeURIComponent,
      decode: decodeURIComponent,
      readonly: true
    }).value : useCookie(key, {
      ...cookieOptions,
      encode: encodeURIComponent,
      decode: decodeURIComponent,
      readonly: false
    }).value,
    setItem: (key, value) => {
      if (cookieOptions?.readonly)
        throw new Error("Cannot set a readonly cookie.");
      useCookie(key, {
        ...cookieOptions,
        encode: encodeURIComponent,
        decode: decodeURIComponent,
        readonly: false
      }).value = value;
    }
  };
}
function usePersistedstateLocalStorage() {
  return {
    getItem: (key) => {
      return !useNuxtApp().ssrContext ? localStorage.getItem(key) : null;
    },
    setItem: (key, value) => {
      if (!useNuxtApp().ssrContext)
        localStorage.setItem(key, value);
    }
  };
}
function usePersistedstateSessionStorage() {
  return {
    getItem: (key) => {
      return !useNuxtApp().ssrContext ? sessionStorage.getItem(key) : null;
    },
    setItem: (key, value) => {
      if (!useNuxtApp().ssrContext)
        sessionStorage.setItem(key, value);
    }
  };
}
export const persistedState = {
  localStorage: usePersistedstateLocalStorage(),
  sessionStorage: usePersistedstateSessionStorage(),
  cookies: usePersistedstateCookies(),
  cookiesWithOptions: usePersistedstateCookies
};
