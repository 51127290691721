<template>
    <svg
        v-if="isSolid"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.4 2.39999C7.76348 2.39999 7.15303 2.65285 6.70294 3.10294C6.25286 3.55302 6 4.16347 6 4.79999V19.2C6 19.8365 6.25286 20.447 6.70294 20.8971C7.15303 21.3471 7.76348 21.6 8.4 21.6H15.6C16.2365 21.6 16.847 21.3471 17.2971 20.8971C17.7471 20.447 18 19.8365 18 19.2V4.79999C18 4.16347 17.7471 3.55302 17.2971 3.10294C16.847 2.65285 16.2365 2.39999 15.6 2.39999H8.4ZM12 19.2C12.3183 19.2 12.6235 19.0736 12.8485 18.8485C13.0736 18.6235 13.2 18.3183 13.2 18C13.2 17.6817 13.0736 17.3765 12.8485 17.1515C12.6235 16.9264 12.3183 16.8 12 16.8C11.6817 16.8 11.3765 16.9264 11.1515 17.1515C10.9264 17.3765 10.8 17.6817 10.8 18C10.8 18.3183 10.9264 18.6235 11.1515 18.8485C11.3765 19.0736 11.6817 19.2 12 19.2Z"
        />
    </svg>

    <svg
        v-else
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2H16C16.7956 2 17.5587 2.31607 18.1213 2.87868C18.6839 3.44129 19 4.20435 19 5V19C19 19.7957 18.6839 20.5587 18.1213 21.1213C17.5587 21.6839 16.7957 22 16 22H8C7.20435 22 6.44129 21.6839 5.87868 21.1213C5.31607 20.5587 5 19.7957 5 19V5C5 4.20435 5.31607 3.44129 5.87868 2.87868ZM8 4C7.73478 4 7.48043 4.10536 7.29289 4.29289C7.10536 4.48043 7 4.73478 7 5V19C7 19.2652 7.10536 19.5196 7.29289 19.7071C7.48043 19.8946 7.73478 20 8 20H16C16.2652 20 16.5196 19.8946 16.7071 19.7071C16.8946 19.5196 17 19.2652 17 19V5C17 4.73478 16.8946 4.48043 16.7071 4.29289C16.5196 4.10536 16.2652 4 16 4H8ZM11 18C11 17.4477 11.4477 17 12 17H12.01C12.5623 17 13.01 17.4477 13.01 18C13.01 18.5523 12.5623 19 12.01 19H12C11.4477 19 11 18.5523 11 18Z"
        />
        >
    </svg>
</template>

<script>
export default {
  name: "IconDeviceMobile",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
