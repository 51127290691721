<template>
    <div class="pagination" aria-label="Pagination">
        <div class="pagination__container">
            <div class="hidden md:block">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p class="text-sm" v-html="paginationText"></p>
            </div>
            <div class="pagination__pages">
                <button
                    :disabled="modelValue === 1"
                    @click="goTo(modelValue - 1)"
                >
                    {{ previousText }}
                </button>
                <button v-show="modelValue > range + 1" @click="goTo(1)">
                    1
                </button>
                <button v-show="modelValue > range + 2" disabled>…</button>
                <button v-for="rb in rangeBefore" :key="rb" @click="goTo(rb)">
                    {{ rb }}
                </button>
                <button disabled class="active">{{ modelValue }}</button>
                <button v-for="ra in rangeAfter" :key="ra" @click="goTo(ra)">
                    {{ ra }}
                </button>
                <button
                    v-show="
                        modelValue < maxPage - range &&
                        maxPage - range > modelValue + range
                    "
                    disabled
                >
                    …
                </button>
                <button v-show="modelValue < maxPage" @click="goTo(maxPage)">
                    {{ maxPage }}
                </button>
                <button
                    :disabled="modelValue === maxPage"
                    @click="goTo(modelValue + 1)"
                >
                    {{ nextText }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pagination',
    props: {
        modelValue: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            required: true
        },
        range: {
            type: Number,
            default: 1
        },
        paginationTexts: {
            type: String,
            required: true
        },
        previousText: {
            type: String,
            required: true
        },
        nextText: {
            type: String,
            required: true
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {}
    },
    computed: {
        start() {
            return (this.modelValue - 1) * this.perPage + 1
        },
        end() {
            return this.total < this.modelValue * this.perPage
                ? this.total
                : this.modelValue * this.perPage
        },
        maxPage() {
            return this.total % this.perPage === 0
                ? this.total / this.perPage
                : (this.total - (this.total % this.perPage)) / this.perPage + 1
        },
        rangeBefore() {
            const rangeBefore = []
            for (let index = this.range; index > 0; index -= 1) {
                const prevValue = this.modelValue - index
                if (prevValue > 0) {
                    rangeBefore.push(prevValue)
                }
            }
            return rangeBefore
        },
        rangeAfter() {
            const rangeAfter = []
            for (let index = 1; index <= this.range; index += 1) {
                const nextValue = this.modelValue + index
                if (nextValue < this.maxPage) {
                    rangeAfter.push(nextValue)
                }
            }
            return rangeAfter
        },
        paginationText() {
            let textReplaced = this.paginationTexts
            textReplaced = textReplaced.replace(
                '%s',
                `<span class="font-medium">${this.start.toString()}</span>`
            )
            textReplaced = textReplaced.replace(
                '%e',
                `<span class="font-medium">${this.end.toString()}</span>`
            )
            textReplaced = textReplaced.replace(
                '%t',
                `<span class="font-medium">${this.total.toString()}</span>`
            )
            return textReplaced
        }
    },
    methods: {
        goTo(index) {
            this.$emit('update:modelValue', index)
        }
    }
}
</script>

<style scoped>
.pagination {
  @apply relative
        flex items-center justify-between
        bg-transparent
        w-full
        h-[3.75rem]
        text-globalDark
        p-4;
}
.pagination--padding {
  @apply px-4 md:px-6;
}
.pagination.pagination--mobile-ctrl {
  @apply fixed md:relative
            inset-x-0 bottom-0
            bg-white md:bg-transparent
            border-t md:border-0 border-gray-200;
}
.pagination__container {
  @apply flex-1 flex items-center justify-center md:justify-between;
}
.pagination__pages {
  border-color: #dfdfdf;
  @apply rounded border divide-x text-sm;
}
.pagination__pages button {
  @apply rounded-none bg-white border-gray-300 border-0 text-globalSecondary;
}
.pagination__pages button:first-child {
  @apply rounded-l py-1.5 px-2;
}
.pagination__pages button:not(:last-child):not(:first-child) {
  @apply border-r-0 py-1.5 px-4;
}
.pagination__pages button:last-child {
  @apply rounded-r py-1.5 px-2;
}
.pagination__pages button:hover:not(:disabled), .pagination__pages button:disabled.active {
  @apply bg-blue-500 text-white;
}

.pagination__pages > ul > li:not(:first-child) > button {
  @apply border-l border-gray-300;
}
</style>
