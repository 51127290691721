<template>
    <ChargemapFiltersContainer
        data-testid="chargemap-feedbacks-filters"
        :show="show"
        :in-drawer="inDrawer"
        :text-apply="textApply"
        :text-cancel="textCancel"
        @updateFilters="updateFilters"
        @updateVisible="updateVisible"
    >
        <div class="flex flex-col gap-6">
            <div class="w-full px-6">
                <ChargemapInput
                    v-model="searchValue"
                    :placeholder="placeholderText"
                    is-search
                    no-x
                />
            </div>
            <div class="flex flex-col">
                <div v-for="(filter, index) in filteredFilters" :key="index">
                    <ChargemapAccordion
                        :title="filter.name"
                        :trigger-height-calculation="
                            shouldTriggerHeightCalculation
                        "
                    >
                        <div
                            v-if="filter.values"
                            class="flex flex-col gap-4 pt-4 pb-8"
                        >
                            <div
                                v-for="(value, indexValue) in filter.values"
                                :key="indexValue"
                                class="flex gap-2 font-medium cursor-pointer"
                            >
                                <input
                                    :id="`filter-${index}-${indexValue}`"
                                    :checked="value.checked"
                                    type="checkbox"
                                    @change="
                                        updateFilterValue(
                                            filter.name,
                                            value.value
                                        )
                                    "
                                />

                                <label
                                    class="cursor-pointer"
                                    :for="`filter-${index}-${indexValue}`"
                                >
                                    <ChargemapLabel
                                        v-if="value.type === FilterType.BLOCK"
                                        :color="value.color"
                                    >
                                        {{ value.name }}
                                    </ChargemapLabel>
                                    <span v-else>
                                        {{ value.name }}
                                    </span>
                                </label>
                            </div>
                        </div>
                        <FilterRatingRange
                            v-else
                            :values="filter.range"
                            :trigger-range-update="shouldTriggerRangeReset"
                            @updated-range="
                                (values) => updateRange(filter.name, values)
                            "
                        />
                    </ChargemapAccordion>
                </div>
            </div>
        </div>
    </ChargemapFiltersContainer>
</template>

<script>
import { FilterUIType } from "../../../interfaces/UI";
import ChargemapInput from "../../b2b/global/ChargemapInput.vue";
import ChargemapAccordion from "../global/ChargemapAccordion.vue";
import ChargemapFiltersContainer from "../global/ChargemapFiltersContainer.vue";
import ChargemapLabel from "../global/ChargemapLabel.vue";
import FilterRatingRange from "../rating/FilterRatingRange.vue";
export default {
  name: "ChargemapFeedbacksFilter",
  components: {
    ChargemapFiltersContainer,
    ChargemapInput,
    ChargemapAccordion,
    FilterRatingRange,
    ChargemapLabel
  },
  props: {
    filters: {
      type: Array,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    },
    placeholderText: {
      type: String,
      required: true
    },
    textApply: {
      type: String,
      required: true
    },
    textCancel: {
      type: String,
      required: true
    },
    inDrawer: {
      type: Boolean,
      default: false
    }
  },
  emits: ["updateFilters", "updateVisible"],
  data() {
    return {
      filtersCloned: [],
      searchValue: "",
      shouldTriggerHeightCalculation: false,
      shouldTriggerRangeReset: false,
      FilterType: FilterUIType
    };
  },
  computed: {
    filteredFilters() {
      const res = JSON.parse(JSON.stringify(this.filtersCloned));
      res.forEach((filterCategory) => {
        filterCategory.values = filterCategory.values?.filter(
          (filter) => filter.name.toLowerCase().includes(this.searchValue.toLowerCase())
        );
      });
      return res;
    }
  },
  watch: {
    filters: {
      handler() {
        this.filtersCloned = JSON.parse(JSON.stringify(this.filters));
        const filter = this.filtersCloned.find(
          (filterValue) => !!filterValue.range
        );
        if (!filter) {
          this.triggerRangeReset();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    triggerRangeReset() {
      this.shouldTriggerRangeReset = true;
      this.$nextTick(() => {
        this.shouldTriggerRangeReset = false;
      });
    },
    updateFilters() {
      this.$emit("updateFilters", this.filtersCloned);
    },
    updateFilterValue(filterName, valueName) {
      const filter = this.filtersCloned.find(
        (filterValue) => filterValue.name === filterName
      );
      if (filter) {
        const value = filter.values?.find(
          (filterValue) => filterValue.value === valueName
        );
        if (value) {
          value.checked = !value.checked;
        }
      }
    },
    updateRange(filterName, values) {
      const filter = this.filtersCloned.find(
        (filterValue) => filterValue.name === filterName
      );
      if (filter) {
        if (!values) {
          filter.range = null;
          return;
        }
        filter.range = {
          min: values[0],
          max: values[1]
        };
      }
    },
    updateVisible(val) {
      this.$emit("updateVisible", val);
    },
    triggerHeightCalculation() {
      this.shouldTriggerHeightCalculation = true;
      this.$nextTick(() => {
        this.shouldTriggerHeightCalculation = false;
      });
    }
  }
};
</script>
