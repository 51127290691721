<template>
    <div :class="getClass">
        <div class="flex flex-col gap-1">
            <div class="flex justify-between items-start gap-2">
                <span
                    class="font-medium text-fifteen-exploitation leading-4.5 gray-l-700"
                >
                    {{ label }}
                </span>
                <IconInfoCircle
                    v-if="displayInfoIcon"
                    class="ml-1.5 cursor-pointer text-gray-l-500 h-4"
                    @click="openCorrespondingPopup"
                />
            </div>
            <PoolRating :rating="rating" />
        </div>
    </div>
    <RatingCriteriaPopup
        v-if="hasPopupData"
        ref="ratingCriteriaPopup"
        :content="getPopupContent"
        :image="getPopupImagePath"
        :title="getPopupTitle"
    />
</template>

<script>
import PoolRating from "./PoolRating.vue";
import RatingCriteriaPopup from "./RatingCriteriaPopup.vue";
import IconInfoCircle from "../../icons/IconInfoCircle.vue";
export default {
  name: "RatingDetailPopupItem",
  components: { PoolRating, RatingCriteriaPopup, IconInfoCircle },
  props: {
    label: {
      type: String,
      required: true
    },
    rating: {
      type: [String, Number],
      required: true
    },
    displayInfoIcon: {
      type: Boolean,
      default: true
    },
    withBorderBottom: {
      type: Boolean,
      default: false
    },
    popupData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    getClass() {
      return this.withBorderBottom ? "border-b border-gray-200 py-4" : "py-4";
    },
    hasPopupData() {
      return this.popupData && Object.keys(this.popupData).length;
    },
    getPopupImagePath() {
      return this.popupData?.imagePath;
    },
    getPopupTitle() {
      return this.popupData?.title;
    },
    getPopupContent() {
      return this.popupData?.content;
    },
    getCloseButtonLabel() {
      return this.popupData?.closeButtonLabel;
    }
  },
  methods: {
    openCorrespondingPopup() {
      ;
      this.$refs.ratingCriteriaPopup.show();
    }
  }
};
</script>
