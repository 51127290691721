<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        class="fill-current"
    >
        <path
            d="M11.924 0.521c0.694 0.694 0.694 1.82 0 2.514l-5.854 5.854h17.041c2.358 0 4.618 0.937 6.285 2.603s2.604 3.928 2.604 6.285v12.445c0 0.982-0.796 1.778-1.778 1.778s-1.778-0.796-1.778-1.778v-12.445c0-1.415-0.562-2.771-1.562-3.771s-2.357-1.562-3.771-1.562h-17.041l5.854 5.854c0.694 0.694 0.694 1.82 0 2.514s-1.82 0.694-2.514 0l-8.889-8.889c-0.694-0.694-0.694-1.82 0-2.514l8.889-8.889c0.694-0.694 1.82-0.694 2.514 0z"
        ></path>
    </svg>
</template>

<script>
export default {
  name: "IconCornerUpLeft"
};
</script>
