<template>
    <svg
        v-if="isSolid"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.8484 2.75161C12.6234 2.52665 12.3182 2.40027 12 2.40027C11.6818 2.40027 11.3766 2.52665 11.1516 2.75161L2.75159 11.1516C2.533 11.3779 2.41205 11.6811 2.41478 11.9957C2.41751 12.3103 2.54372 12.6113 2.76621 12.8338C2.9887 13.0563 3.28967 13.1825 3.60431 13.1852C3.91894 13.188 4.22207 13.067 4.44839 12.8484L4.79999 12.4968V20.4C4.79999 20.7183 4.92642 21.0235 5.15146 21.2485C5.3765 21.4736 5.68173 21.6 5.99999 21.6H8.39999C8.71825 21.6 9.02347 21.4736 9.24852 21.2485C9.47356 21.0235 9.59999 20.7183 9.59999 20.4V18C9.59999 17.6818 9.72642 17.3765 9.95146 17.1515C10.1765 16.9264 10.4817 16.8 10.8 16.8H13.2C13.5182 16.8 13.8235 16.9264 14.0485 17.1515C14.2736 17.3765 14.4 17.6818 14.4 18V20.4C14.4 20.7183 14.5264 21.0235 14.7515 21.2485C14.9765 21.4736 15.2817 21.6 15.6 21.6H18C18.3182 21.6 18.6235 21.4736 18.8485 21.2485C19.0736 21.0235 19.2 20.7183 19.2 20.4V12.4968L19.5516 12.8484C19.7779 13.067 20.081 13.188 20.3957 13.1852C20.7103 13.1825 21.0113 13.0563 21.2338 12.8338C21.4563 12.6113 21.5825 12.3103 21.5852 11.9957C21.5879 11.6811 21.467 11.3779 21.2484 11.1516L12.8484 2.75161Z"
        />
    </svg>

    <svg
        v-else
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L21.7071 11.2929C22.0976 11.6834 22.0976 12.3166 21.7071 12.7071C21.3166 13.0976 20.6834 13.0976 20.2929 12.7071L20 12.4142V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V12.4142L3.70711 12.7071C3.31658 13.0976 2.68342 13.0976 2.29289 12.7071C1.90237 12.3166 1.90237 11.6834 2.29289 11.2929L11.2929 2.29289ZM6 10.4142V20H9V16C9 15.4696 9.21071 14.9609 9.58579 14.5858C9.96086 14.2107 10.4696 14 11 14H13C13.5304 14 14.0391 14.2107 14.4142 14.5858C14.7893 14.9609 15 15.4696 15 16V20H18V10.4142L12 4.41421L6 10.4142ZM13 20V16H11V20H13Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconHome",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
