<template>
    <div class="flex flex-col gap-4 w-full">
        <div
            class="justify-start items-start inline-flex w-full"
            data-testid="drawer-header-free"
        >
            <ChargemapSkeletonLoader
                v-if="loadingPictures"
                class="w-16 h-16 shrink-0 mr-4"
            />
            <img
                v-else-if="imageUrl"
                class="w-16 h-16 relative rounded-lg mr-4"
                :src="imageUrl"
            />
            <div
                v-else
                class="w-16 h-16 flex flex-shrink-0 items-center justify-center relative rounded-lg mr-4 border border-zinc-200"
            >
                <IconCamera class="w-12 h-12 text-zinc-500" />
            </div>
            <DrawerTitle :pool="pool" :loading-info="loadingInfo" />

            <ChargemapButton
                btn-alt="clear"
                class="float-right ml-auto mr-1 w-8 h-8"
                v-bind="$attrs"
                @click="editPool"
            >
                <IconPencilAlt class="w-6 h-6" />
            </ChargemapButton>
            <DrawerCloseButton
                class="float-right mr-0"
                :show-close-button="showCloseButton"
                @closeDrawer="closeDrawer"
            />
        </div>

        <ChargemapMessage
            v-if="showPartnerFullMessage"
            identifier="package-full-alert-drawer-title"
            :has-polygone="true"
            type="partner"
        >
            <i18n-t
                keypath="drawer.messages.partners_full.stand_out"
                tag="div"
                for="drawer.messages.partners_full.stand_out"
                class="text-lg"
            >
                <template #partnersFull>
                    <span class="font-semibold">
                        {{ $t('drawer.messages.partners_full.partners_full') }}
                    </span>
                </template>
            </i18n-t>

            <template #btnClose>
                <ChargemapButton btn-alt="clear-white" class="w-full">
                    {{ $t('drawer.messages.partners_full.close') }}
                </ChargemapButton>
            </template>

            <template #btnAction>
                <ChargemapButton
                    btn-alt="white"
                    class="w-full !text-violet-500"
                >
                    {{ $t('drawer.messages.partners_full.more') }}
                </ChargemapButton>
            </template>
        </ChargemapMessage>
    </div>
</template>
<script>
import ChargemapButton from "../../../b2b/global/ChargemapButton.vue";
import IconCamera from "../../../icons/IconCamera.vue";
import IconPencilAlt from "../../../icons/IconPencilAlt.vue";
import DrawerCloseButton from "../../drawer/DrawerCloseButton.vue";
import ChargemapMessage from "../../global/ChargemapMessage.vue";
import ChargemapSkeletonLoader from "../../global/ChargemapSkeletonLoader.vue";
import DrawerTitle from "./DrawerTitle.vue";
export default {
  name: "DrawerHeaderFree",
  components: {
    DrawerTitle,
    ChargemapMessage,
    ChargemapButton,
    ChargemapSkeletonLoader,
    IconCamera,
    IconPencilAlt,
    DrawerCloseButton
  },
  props: {
    pool: {
      type: Object,
      default: void 0
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    s3Url: {
      type: String,
      required: true
    },
    chargemapUrl: {
      type: String,
      required: true
    },
    loadingInfo: {
      type: Boolean,
      required: true
    },
    loadingPictures: {
      type: Boolean,
      required: true
    },
    showPartnerFullMessage: {
      type: Boolean,
      default: false
    }
  },
  emits: ["closeDrawer"],
  computed: {
    imageUrl() {
      return this.pool?.avatar ? `${this.s3Url}/charging_pool_photos/url/160x160/${this.pool.avatar?.id}/${this.pool.avatar?.url}` : void 0;
    }
  },
  methods: {
    closeDrawer() {
      this.$emit("closeDrawer", false);
    },
    editPool() {
      window.open(
        `${this.chargemapUrl}/wizard/${this.pool?.id}`,
        "_blank",
        "noreferrer"
      );
    }
  }
};
</script>
