<template>
    <div class="flex flex-col my-2 gap-6 w-full" data-testid="chargemap-slider">
        <Slider
            v-model="values"
            :min="min"
            :max="max"
            :step="0.1"
            :lazy="false"
            :merge="-1"
            :tooltips="false"
            :classes="sliderStyle"
            class="px-2"
        />
        <div class="flex justify-between flex-row">
            <div class="w-20">
                <ChargemapNumberInput
                    :model-value="minValue"
                    :min="min"
                    :step="0.1"
                    :max="parsedMaxValue"
                    :decimal="getNumberSeparator"
                    :label="$t('slider.min')"
                    @formatted-value-update="validateMin"
                />
            </div>
            <div class="w-20">
                <ChargemapNumberInput
                    :model-value="maxValue"
                    :min="parsedMinValue"
                    :max="max"
                    :decimal="getNumberSeparator"
                    :label="$t('slider.max')"
                    @formatted-value-update="validateMax"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Slider from "@vueform/slider";
import ChargemapNumberInput from "./ChargemapNumberInput.vue";
export default {
  name: "ChargemapSlider",
  components: {
    Slider,
    ChargemapNumberInput
  },
  props: {
    defaultValues: {
      type: Array,
      required: true
    },
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    triggerUpdate: {
      type: Boolean,
      default: false
    }
  },
  emits: ["updateValues"],
  data: () => ({
    values: [1, 3],
    minValue: "2",
    maxValue: "4",
    sliderStyle: {
      horizontal: "slider-horizontal",
      connect: "absolute z-1 top-0 right-0 transform-origin-0 transform-style-flat h-full w-full bg-primary-500 cursor-pointer tap:duration-300 tap:transition-transform disabled:bg-slate-400 disabled:cursor-not-allowed",
      handle: "slider-icons absolute rounded bg-white border border-primary-500 rounded shadow-slider cursor-grab focus:outline-none h:w-5 h:h-5 h:-right-2 txt-rtl-h:-left-2 txt-rtl-h:right-auto v:w-5 v:h-5 v:-top-2 v:-right-1.25 disabled:cursor-not-allowed focus:ring focus:ring-primary-500 focus:ring-opacity-30"
    }
  }),
  computed: {
    getNumberSeparator() {
      return this.$n(1.3).slice(1, 2);
    },
    parsedMinValue() {
      return parseFloat(this.minValue);
    },
    parsedMaxValue() {
      return parseFloat(this.maxValue);
    }
  },
  watch: {
    values: {
      handler() {
        this.minValue = this.values[0].toString();
        this.maxValue = this.values[1].toString();
        this.$emit("updateValues", this.values);
      },
      deep: true
    },
    triggerUpdate(value) {
      if (value) {
        this.values = this.defaultValues;
      }
    }
  },
  mounted() {
    this.values = this.defaultValues;
  },
  methods: {
    validateMin(value) {
      if (value) {
        const validatedValue = this.validatedValue(
          parseFloat(value),
          this.min,
          parseFloat(this.maxValue)
        );
        this.minValue = validatedValue.toString();
        this.values[0] = validatedValue;
      }
    },
    validateMax(value) {
      if (value) {
        const validatedValue = this.validatedValue(
          parseFloat(value),
          parseFloat(this.minValue),
          this.max
        );
        this.maxValue = validatedValue.toString();
        this.values[1] = validatedValue;
      }
    },
    validatedValue(numberValue, minAllowedValue, maxAllowedValue) {
      if (numberValue < minAllowedValue) {
        return minAllowedValue;
      }
      if (numberValue > maxAllowedValue) {
        return maxAllowedValue;
      }
      return numberValue;
    }
  }
};
</script>
