<template>
    <svg
        v-if="isSolid"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            d="M20.8968 3.1032C20.4468 2.65327 19.8364 2.40051 19.2 2.40051C18.5636 2.40051 17.9533 2.65327 17.5032 3.1032L8.40002 12.2064V15.6H11.7936L20.8968 6.4968C21.3468 6.04673 21.5995 5.43639 21.5995 4.8C21.5995 4.16361 21.3468 3.55327 20.8968 3.1032Z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.40002 7.2C2.40002 6.56348 2.65288 5.95303 3.10297 5.50294C3.55306 5.05286 4.1635 4.8 4.80002 4.8H9.60002C9.91828 4.8 10.2235 4.92643 10.4486 5.15147C10.6736 5.37652 10.8 5.68174 10.8 6C10.8 6.31826 10.6736 6.62348 10.4486 6.84853C10.2235 7.07357 9.91828 7.2 9.60002 7.2H4.80002V19.2H16.8V14.4C16.8 14.0817 16.9265 13.7765 17.1515 13.5515C17.3765 13.3264 17.6818 13.2 18 13.2C18.3183 13.2 18.6235 13.3264 18.8486 13.5515C19.0736 13.7765 19.2 14.0817 19.2 14.4V19.2C19.2 19.8365 18.9472 20.447 18.4971 20.8971C18.047 21.3471 17.4365 21.6 16.8 21.6H4.80002C4.1635 21.6 3.55306 21.3471 3.10297 20.8971C2.65288 20.447 2.40002 19.8365 2.40002 19.2V7.2Z"
        />
    </svg>
    <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.4032 4.04993C19.2803 3.99965 19.1487 3.97435 19.0159 3.9755C18.8831 3.97666 18.7519 4.00424 18.6299 4.05665C18.5079 4.10906 18.3975 4.18524 18.3053 4.28075L18.2931 4.29315L10 12.5863V14H11.4138L19.7069 5.70693L19.7193 5.69475C19.8148 5.6025 19.891 5.49216 19.9434 5.37016C19.9958 5.24815 20.0234 5.11693 20.0245 4.98415C20.0257 4.85138 20.0004 4.7197 19.9501 4.5968C19.8998 4.4739 19.8256 4.36225 19.7317 4.26836C19.6378 4.17446 19.5261 4.10021 19.4032 4.04993ZM18.9985 1.97558C19.3968 1.97212 19.7919 2.04802 20.1606 2.19886C20.5293 2.34971 20.8642 2.57247 21.1459 2.85414C21.4276 3.13582 21.6503 3.47078 21.8012 3.83947C21.952 4.20815 22.0279 4.60319 22.0245 5.00153C22.021 5.39987 21.9382 5.79353 21.781 6.15954C21.6251 6.52261 21.3989 6.85126 21.1156 7.12664L12.5351 15.7071C12.3476 15.8947 12.0932 16 11.828 16H9C8.44772 16 8 15.5523 8 15V12.172C8 11.9068 8.10536 11.6525 8.29289 11.4649L16.8734 2.88445C17.1488 2.60114 17.4774 2.37499 17.8405 2.21902C18.2065 2.0618 18.6002 1.97904 18.9985 1.97558ZM3.87868 4.87872C4.44129 4.31611 5.20435 4.00004 6 4.00004H11C11.5523 4.00004 12 4.44776 12 5.00004C12 5.55233 11.5523 6.00004 11 6.00004H6C5.73478 6.00004 5.48043 6.1054 5.29289 6.29293C5.10536 6.48047 5 6.73482 5 7.00004V18C5 18.2653 5.10536 18.5196 5.29289 18.7071C5.48043 18.8947 5.73478 19 6 19H17C17.2652 19 17.5196 18.8947 17.7071 18.7071C17.8946 18.5196 18 18.2653 18 18V13C18 12.4478 18.4477 12 19 12C19.5523 12 20 12.4478 20 13V18C20 18.7957 19.6839 19.5588 19.1213 20.1214C18.5587 20.684 17.7957 21 17 21H6C5.20435 21 4.44129 20.684 3.87868 20.1214C3.31607 19.5588 3 18.7957 3 18V7.00004C3 6.20439 3.31607 5.44133 3.87868 4.87872Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconPencilAlt",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
