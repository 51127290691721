<template>
    <svg
        v-if="isSolid"
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 14.4C12.6365 14.4 13.2469 14.1471 13.697 13.697C14.1471 13.2469 14.4 12.6365 14.4 12C14.4 11.3635 14.1471 10.753 13.697 10.3029C13.2469 9.85283 12.6365 9.59998 12 9.59998C11.3634 9.59998 10.753 9.85283 10.3029 10.3029C9.85282 10.753 9.59996 11.3635 9.59996 12C9.59996 12.6365 9.85282 13.2469 10.3029 13.697C10.753 14.1471 11.3634 14.4 12 14.4Z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.549561 12C2.07836 7.13158 6.62636 3.59998 12 3.59998C17.3736 3.59998 21.9216 7.13158 23.4504 12C21.9216 16.8684 17.3736 20.4 12 20.4C6.62636 20.4 2.07836 16.8684 0.549561 12ZM16.8 12C16.8 13.273 16.2942 14.4939 15.3941 15.3941C14.4939 16.2943 13.273 16.8 12 16.8C10.7269 16.8 9.50602 16.2943 8.60585 15.3941C7.70567 14.4939 7.19996 13.273 7.19996 12C7.19996 10.7269 7.70567 9.50604 8.60585 8.60586C9.50602 7.70569 10.7269 7.19998 12 7.19998C13.273 7.19998 14.4939 7.70569 15.3941 8.60586C16.2942 9.50604 16.8 10.7269 16.8 12Z"
        />
    </svg>

    <svg
        v-else
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12C10 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10ZM9.17158 9.17157C9.92173 8.42143 10.9391 8 12 8C13.0609 8 14.0783 8.42143 14.8284 9.17157C15.5786 9.92172 16 10.9391 16 12C16 13.0609 15.5786 14.0783 14.8284 14.8284C14.0783 15.5786 13.0609 16 12 16C10.9391 16 9.92173 15.5786 9.17158 14.8284C8.42143 14.0783 8.00001 13.0609 8.00001 12C8.00001 10.9391 8.42143 9.92172 9.17158 9.17157Z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.51201 12C4.74752 15.4964 8.08323 18 12 18C15.9177 18 19.2525 15.4965 20.488 12C19.2525 8.50354 15.9177 6 12 6C8.08323 6 4.74752 8.50359 3.51201 12ZM1.50394 11.7004C2.90509 7.23851 7.07378 4 12 4C16.9273 4 21.0949 7.23856 22.4961 11.7004C22.5573 11.8954 22.5573 12.1046 22.4961 12.2996C21.0949 16.7614 16.9273 20 12 20C7.07378 20 2.90509 16.7615 1.50394 12.2996C1.4427 12.1046 1.4427 11.8954 1.50394 11.7004Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconEye",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
