<template>
    <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23.75 3.96448C23.4499 3.96448 23.1527 4.02359 22.8754 4.13845C22.5981 4.25331 22.3461 4.42166 22.1339 4.63389L5.49364 21.2742L4.28155 25.7185L8.72587 24.5064L25.3661 7.86613C25.5784 7.6539 25.7467 7.40194 25.8616 7.12465C25.9764 6.84736 26.0356 6.55016 26.0356 6.25001C26.0356 5.94987 25.9764 5.65267 25.8616 5.37538C25.7467 5.09808 25.5784 4.84613 25.3661 4.63389C25.1539 4.42166 24.902 4.25331 24.6247 4.13845C24.3474 4.02359 24.0502 3.96448 23.75 3.96448ZM21.9187 1.82875C22.4993 1.58826 23.1216 1.46448 23.75 1.46448C24.3785 1.46448 25.0008 1.58826 25.5814 1.82875C26.162 2.06925 26.6895 2.42175 27.1339 2.86613C27.5783 3.3105 27.9308 3.83806 28.1713 4.41867C28.4118 4.99928 28.5356 5.62157 28.5356 6.25001C28.5356 6.87845 28.4118 7.50074 28.1713 8.08136C27.9308 8.66196 27.5783 9.18952 27.1339 9.63389L10.2589 26.5089C10.1051 26.6627 9.91379 26.7737 9.70393 26.831L2.82893 28.706C2.39616 28.824 1.93333 28.7011 1.61615 28.3839C1.29896 28.0667 1.17605 27.6039 1.29407 27.1711L3.16907 20.2961C3.22631 20.0862 3.33733 19.8949 3.49115 19.7411L20.3661 2.86613C20.8105 2.42175 21.3381 2.06925 21.9187 1.82875Z"
            fill="white"
        />
    </svg>
</template>

<script>
export default {
  name: "IconEditWhite"
};
</script>
