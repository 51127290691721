<template>
    <div
        class="flex flex-col justify-between align-start gap-4 pb-4 overflow-auto"
        :class="[isDesktopDrawer ? 'w-[488px]' : 'w-full']"
        data-testid="drawer-content"
    >
        <DrawerHeader
            :pool="pool"
            :pictures="pictures"
            :show-close-button="showCloseButton"
            :pool-connectors="poolConnectors"
            :s3-url="s3Url"
            :legacy-api-url="legacyApiUrl"
            :loading-pictures="loadingPictures"
            :loading-info="loadingInfo"
            :loading-stations="loadingStations"
            :message="message"
            :chargemap-url="chargemapUrl"
            :show-partner-full-message="showPartnerFullMessage"
            :cdnUrl
            @closeDrawer="close"
            @reliabilityClicked="reliabilityClicked"
            @openPicture="openPicture"
            @messageActionClick="messageActionClick"
            @connectorClicked="connectorClicked"
            @openTariff="openTariff"
        />

        <div class="md:mx-2 sticky top-0 bg-white z-20">
            <ChargemapTabs
                class="w-fit flex-grow pr-4"
                :tabs="tabs"
                :selected-tab="selectedTab"
                :fill-space="true"
                @setSelectedTab="setSelectedTab"
            />
        </div>
        <div class="flex flex-col gap-6 px-4 md:gap-8 md:px-6 w-full">
            <InfoTab
                v-if="isTab(DrawerTabs.INFO)"
                :pool="pool"
                :loading-info="loadingInfo"
                :is-partner="isPartner"
            />
            <FeedbackTab
                v-if="isTab(DrawerTabs.REVIEWS)"
                :feedbacks="feedbacks"
                :pool="pool"
                :rating-range="range"
                :isPartner="isPartner"
                :max-feedbacks="maxFeedbacks"
                :loading-more-feedbacks="loadingMoreFeedbacks"
                :feedback-filters="feedbackFilters"
                :loading-feedback="loadingFeedbacks"
                :open-additional-filters="openAdditionalFilters"
                :active-additional-filters="activeAdditionalFilters"
                @loadMoreFeedbacks="loadMoreFeedbacks"
                @openFeedbackModal="openFeedbackModal"
                @updateFeedbackFilters="updateFeedbackFilters"
                @removeRange="removeRange"
                @openedFilters="updateOpenFilters"
                @toggleFilter="toggleFilter"
                @clearFilters="clearFilters"
            />
            <ConnectorsTab
                v-if="isTab(DrawerTabs.CONNECTORS)"
                :pool-connectors="poolConnectors"
                :is-partner="isPartner"
                :opened-pool-connector="openedPoolConnector"
                :legacy-api-url="legacyApiUrl"
                :loading-stations="loadingStations"
                @findConnectorIdClicked="findConnectorIdClicked"
            />
        </div>

        <div
            v-if="isPartner"
            class="absolute bottom-0 w-full flex flex-col align-bottom justify-end h-full"
            :class="[
                openAdditionalFilters
                    ? 'pointer-events-auto'
                    : 'pointer-events-none'
            ]"
        >
            <ChargemapFeedbacksFilter
                :show="openAdditionalFilters"
                :filters="additionalFilters"
                :placeholder-text="$t('filters.search')"
                :text-apply="$t('filters.apply')"
                :text-cancel="$t('filters.cancel')"
                :in-drawer="true"
                @updateVisible="updateOpenFilters"
                @updateFilters="updateFilters"
            />
        </div>
    </div>
</template>

<script>
import {
  FilterUIType
} from "../../../interfaces/UI";
import { DrawerTabs, ReliabilityActions } from "../../../interfaces/drawer";
import {
  FilterFeedbackType
} from "../../../interfaces/feedback";
import {
  MoodColorsTailwind,
  MoodColorsTextTailwind,
  Moods
} from "../../../interfaces/mood";
import ChargemapFeedbacksFilter from "../feedback/ChargemapFeedbacksFilter.vue";
import ChargemapTabs from "../global/ChargemapTabs.vue";
import DrawerHeader from "./DrawerHeader.vue";
import ConnectorsTab from "./content/ConnectorsTab.vue";
import FeedbackTab from "./content/FeedbackTab.vue";
import InfoTab from "./content/InfoTab.vue";
export default {
  name: "DrawerContent",
  components: {
    DrawerHeader,
    ChargemapTabs,
    FeedbackTab,
    InfoTab,
    ConnectorsTab,
    ChargemapFeedbacksFilter
  },
  props: {
    pool: {
      type: Object,
      default: void 0
    },
    feedbacks: {
      type: Array,
      required: true
    },
    poolConnectors: {
      type: Array,
      required: true
    },
    pictures: {
      type: Array,
      required: true
    },
    s3Url: {
      type: String,
      required: true
    },
    chargemapUrl: {
      type: String,
      required: true
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    isFullScreen: {
      type: Boolean,
      default: false
    },
    isPartner: {
      type: Boolean,
      required: true
    },
    legacyApiUrl: {
      type: String,
      required: true
    },
    loadingInfo: {
      type: Boolean,
      default: false
    },
    loadingFeedbacks: {
      type: Boolean,
      default: false
    },
    loadingPictures: {
      type: Boolean,
      default: false
    },
    loadingStations: {
      type: Boolean,
      default: false
    },
    message: {
      type: Object,
      default: void 0
    },
    showPartnerFullMessage: {
      type: Boolean,
      default: false
    },
    maxFeedbacks: {
      type: Boolean,
      default: false
    },
    loadingMoreFeedbacks: {
      type: Boolean,
      default: false
    },
    cdnUrl: {
      type: String,
      default: ""
    }
  },
  emits: [
    "closeDrawer",
    "openPicture",
    "reliabilityClicked",
    "messageActionClick",
    "connectorClicked",
    "openFeedbackModal",
    "updateFeedbackFilters",
    "openTariff",
    "findConnectorIdClicked",
    "changedTab",
    "loadMoreFeedbacks"
  ],
  data() {
    return {
      selectedTab: DrawerTabs.INFO,
      DrawerTabs,
      openedPoolConnector: void 0,
      feedbackFilters: {
        keyword: "",
        moodFilter: [],
        feedbackTypeFilter: [],
        range: null
      },
      openAdditionalFilters: false,
      activeAdditionalFilters: [],
      listFeedbackType: [
        FilterFeedbackType.ARCHIVED,
        FilterFeedbackType.UNREAD,
        FilterFeedbackType.REPORT,
        FilterFeedbackType.RATED,
        FilterFeedbackType.COMMENT,
        FilterFeedbackType.CHECKIN
      ],
      range: null,
      listMoods: [
        Moods.POSITIVE,
        Moods.NEUTRAL,
        Moods.MIXED,
        Moods.NEGATIVE
      ],
      windowWidth: 0
    };
  },
  computed: {
    isDesktopDrawer() {
      return this.windowWidth > 768;
    },
    tabs() {
      return [
        {
          id: DrawerTabs.INFO,
          text: this.$t(`drawer.tabs.${DrawerTabs.INFO}`)
        },
        {
          id: DrawerTabs.CONNECTORS,
          text: this.$t(`drawer.tabs.${DrawerTabs.CONNECTORS}`)
        },
        {
          id: DrawerTabs.REVIEWS,
          text: this.$t(`drawer.tabs.${DrawerTabs.REVIEWS}`)
        }
      ];
    },
    additionalFilters() {
      return [
        {
          name: this.$t("filters.feedbacks.feedbackType"),
          values: this.getListFeedbackTypeFilterValues
        },
        {
          name: this.$t("filters.feedbacks.moods"),
          values: this.getListMoodFilterValues
        },
        {
          name: this.$t("filters.feedbacks.rating"),
          range: this.range
        }
      ];
    },
    getListFeedbackTypeFilterValues() {
      const feedbackTypeFilterValues = [];
      this.listFeedbackType.forEach((type) => {
        let filterInsideRouteQuery = false;
        this.activeAdditionalFilters.forEach((filter) => {
          if (filter === type) {
            filterInsideRouteQuery = true;
          }
        });
        feedbackTypeFilterValues.push({
          name: this.$t(`filters.feedbacks.${type}`),
          value: type,
          checked: filterInsideRouteQuery
        });
      });
      return feedbackTypeFilterValues;
    },
    getListMoodFilterValues() {
      const moodsFilterValues = [];
      this.listMoods.forEach((mood) => {
        let filterInsideRouteQuery = false;
        this.activeAdditionalFilters.forEach((filter) => {
          if (filter === mood) {
            filterInsideRouteQuery = true;
          }
        });
        moodsFilterValues.push({
          name: this.$t(`filters.feedbacks.${mood}`),
          value: mood,
          textColor: MoodColorsTextTailwind[mood],
          color: MoodColorsTailwind[mood],
          type: FilterUIType.BLOCK,
          checked: filterInsideRouteQuery
        });
      });
      return moodsFilterValues;
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    close() {
      this.$emit("closeDrawer", false);
    },
    reliabilityClicked(payload) {
      this.$emit("reliabilityClicked", payload);
      if (payload !== ReliabilityActions.RATING) {
        if (payload === ReliabilityActions.REPORT) {
          this.feedbackFilters = {
            keyword: "",
            moodFilter: [],
            feedbackTypeFilter: [
              FilterFeedbackType.REPORT
            ],
            range: null
          };
        } else {
          this.feedbackFilters = {
            keyword: "",
            moodFilter: [],
            feedbackTypeFilter: [
              FilterFeedbackType.CHECKIN
            ],
            range: null
          };
        }
      }
      switch (payload) {
        case ReliabilityActions.CHARGE:
        case ReliabilityActions.REPORT:
          this.selectedTab = DrawerTabs.REVIEWS;
          break;
        default:
          break;
      }
    },
    openPicture(index) {
      this.$emit("openPicture", index);
    },
    messageActionClick() {
      this.$emit("messageActionClick");
    },
    loadMoreFeedbacks() {
      this.$emit("loadMoreFeedbacks");
    },
    connectorClicked(connector) {
      this.selectedTab = DrawerTabs.CONNECTORS;
      this.$nextTick(() => {
        this.openedPoolConnector = connector;
        this.$nextTick(() => {
          this.openedPoolConnector = void 0;
        });
      });
      this.$emit("connectorClicked", connector);
    },
    setSelectedTab(tabId) {
      this.selectedTab = tabId;
      this.$emit("changedTab", tabId);
    },
    openFeedbackModal(feedback) {
      this.$emit("openFeedbackModal", feedback);
    },
    updateFeedbackFilters(filters) {
      this.$emit("updateFeedbackFilters", filters);
    },
    isTab(tabId) {
      return this.tabs && this.selectedTab === tabId;
    },
    openTariff() {
      this.$emit("openTariff");
    },
    findConnectorIdClicked() {
      this.$emit("findConnectorIdClicked");
    },
    updateOpenFilters(value) {
      this.openAdditionalFilters = value;
    },
    removeRange() {
      this.range = null;
    },
    updateFilters(filtersUpdated) {
      this.activeAdditionalFilters = [];
      filtersUpdated.forEach((filter) => {
        if (filter.range !== void 0) {
          this.range = filter.range;
        }
        if (filter.values) {
          filter.values.forEach((value) => {
            if (value.checked) {
              this.activeAdditionalFilters.push(value.value);
            }
          });
        }
      });
      this.openAdditionalFilters = false;
    },
    toggleFilter(filter) {
      if (this.activeAdditionalFilters.includes(filter)) {
        this.activeAdditionalFilters = this.activeAdditionalFilters.filter(
          (activeFilter) => activeFilter !== filter
        );
      } else {
        this.activeAdditionalFilters.push(filter);
      }
    },
    clearFilters() {
      this.activeAdditionalFilters = [];
      this.range = null;
    }
  }
};
</script>
../../../interfaces/drawer;d ../../../interfaces/drawer
