<template>
    <div
        class="flex flex-col gap-4 relative min-h-[300px]"
        data-testid="feedback-tab"
    >
        <div class="flex flex-row gap-2">
            <button
                :disabled="disabledChargesFilter"
                :class="[
                    isActiveFilter(FilterFeedbackType.CHECKIN)
                        ? 'border-2 border-sky-500 bg-sky-50'
                        : 'border border-zinc-300 m-[1px]'
                ]"
                class="flex flex-col flex-1 items-start p-4 gap-1 h-fit rounded-lg"
                @click="switchFilter(FilterFeedbackType.CHECKIN)"
            >
                <div class="flex flex-row gap-4 items-center">
                    <IconBolt
                        class="w-7 h-7"
                        :class="[disabledChargesFilter ? 'text-zinc-300' : '']"
                    />
                    <span
                        class="font-bold text-2xl"
                        :class="[
                            isActiveFilter(FilterFeedbackType.CHECKIN)
                                ? 'text-sky-800'
                                : '',
                            disabledChargesFilter
                                ? 'text-zinc-300'
                                : 'text-zinc-800'
                        ]"
                    >
                        {{ getChargesCount }}
                    </span>
                </div>
                <span
                    class="text-sm"
                    :class="[
                        isActiveFilter(FilterFeedbackType.CHECKIN)
                            ? 'text-sky-500'
                            : '',
                        disabledChargesFilter
                            ? 'text-zinc-300'
                            : 'text-zinc-500'
                    ]"
                >
                    {{ $t('drawer.tabs.charges') }}
                </span>
            </button>

            <button
                :disabled="disabledReportsFilter"
                :class="[
                    isActiveFilter(FilterFeedbackType.REPORT)
                        ? 'border-2 border-sky-500 bg-sky-50'
                        : 'border border-zinc-300 m-[1px]'
                ]"
                class="flex flex-col flex-1 items-start p-4 gap-1 h-fit rounded-lg"
                @click="switchFilter(FilterFeedbackType.REPORT)"
            >
                <div class="flex flex-row gap-4 items-center">
                    <IconSpeaker
                        class="w-7 h-7"
                        :class="[
                            disabledReportsFilter
                                ? 'text-zinc-300'
                                : 'text-red-500'
                        ]"
                    />
                    <span
                        class="font-bold text-2xl"
                        :class="[
                            isActiveFilter(FilterFeedbackType.REPORT)
                                ? 'text-sky-800'
                                : '',
                            disabledReportsFilter
                                ? 'text-zinc-300'
                                : 'text-zinc-800'
                        ]"
                    >
                        {{ getReportsCount }}
                    </span>
                </div>
                <span
                    class="text-sm"
                    :class="[
                        isActiveFilter(FilterFeedbackType.REPORT)
                            ? 'text-sky-500'
                            : '',
                        disabledReportsFilter
                            ? 'text-zinc-300'
                            : 'text-zinc-500'
                    ]"
                >
                    {{ $t('drawer.tabs.reports') }}
                </span>
            </button>
            <button
                :disabled="disabledCommentsFilter"
                class="flex flex-col flex-1 items-start p-4 gap-1 h-fit rounded-lg"
                :class="[
                    isActiveFilter(FilterFeedbackType.COMMENT)
                        ? 'border-2 border-sky-500 bg-sky-50'
                        : 'border border-zinc-300 m-[1px]'
                ]"
                @click="switchFilter(FilterFeedbackType.COMMENT)"
            >
                <div class="flex flex-row gap-4 items-center">
                    <IconChatAlt
                        class="w-7 h-7"
                        :class="[
                            disabledCommentsFilter
                                ? 'text-zinc-300'
                                : 'text-zinc-500'
                        ]"
                    />
                    <span
                        class="font-bold text-2xl"
                        :class="[
                            isActiveFilter(FilterFeedbackType.COMMENT)
                                ? 'text-sky-800'
                                : '',
                            disabledCommentsFilter
                                ? 'text-zinc-300'
                                : 'text-zinc-800'
                        ]"
                    >
                        {{ getCommentsCount }}
                    </span>
                </div>
                <span
                    class="text-sm"
                    :class="[
                        isActiveFilter(FilterFeedbackType.COMMENT)
                            ? 'text-sky-500'
                            : '',
                        disabledCommentsFilter
                            ? 'text-zinc-300'
                            : 'text-zinc-500'
                    ]"
                >
                    {{ $t('drawer.tabs.comments') }}
                </span>
            </button>
        </div>

        <div
            class="flex flex-row gap-4 items-center justify-between"
            v-if="isPartner"
        >
            <div class="flex items-center w-full">
                <ChargemapInput
                    id="search"
                    v-model="filters.keyword"
                    :disabled="loadingFeedback"
                    :is-search="true"
                    :placeholder="$t('filters.search')"
                />
            </div>

            <div class="flex text-gray-500 font-medium w-fit">
                <div class="flex gap-8 divide-x divide-gray-200">
                    <ChargemapFilterButton
                        :disabled="loadingFeedback"
                        :filters-count="activeFiltersCount"
                        @click="updateOpenFilters(true)"
                        @clear-filters="clearFilters"
                    />
                </div>
            </div>
        </div>

        <ChargemapSkeletonLoader
            v-if="loadingFeedback"
            class="w-full h-64 rounded-xl border border-zinc-300"
        />

        <div v-else class="flex flex-col gap-4">
            <ChargemapFeedbackContainer
                v-for="feedback in feedbacks"
                :key="feedback.id"
                data-testid="feedback"
                :feedback="feedback"
                :show-actions="isPartner"
                :is-partner="false"
                :is-drawer="true"
                :show-map-button="false"
                locale="fr"
                :answer-button-text="$t('feedbacks.answer')"
                :sentiment-name-key-start="'sentiment.'"
                class="!p-4"
                @answerButtonClick="openFeedbackModal(feedback)"
            />

            <div
                v-if="loadingMoreFeedbacks"
                ref="feedbackLoader"
                class="w-full h-28"
            >
                <ChargemapSkeletonLoader
                    class="w-full h-28 rounded-xl border border-zinc-300"
                />
            </div>
            <ChargemapButton
                v-else-if="!loadingMoreFeedbacks && !maxFeedbacks"
                class="text-primary-500"
                btn-alt="clear-full"
                @click="loadMoreFeedbacks"
            >
                <IconClock class="w-7 h-7" />
                {{ $t('see_more') }}
            </ChargemapButton>
        </div>

        <FeedbacksNotFound
            v-if="!loadingFeedback && !hasFeedbacks"
            :is-filter-not-found="activeAdditionalFilters.length > 0"
        />
    </div>
</template>

<script>
import {
  FilterFeedbackType
} from "../../../../interfaces/feedback";
import ClickOutside from "../../../../utils/ClickOutsideDirective";
import ChargemapButton from "../../../b2b/global/ChargemapButton.vue";
import ChargemapFilterButton from "../../../b2b/global/ChargemapFilterButton.vue";
import ChargemapInput from "../../../b2b/global/ChargemapInput.vue";
import IconBolt from "../../../icons/IconBolt.vue";
import IconChatAlt from "../../../icons/IconChatAlt.vue";
import IconClock from "../../../icons/IconClock.vue";
import IconSpeaker from "../../../icons/IconSpeaker.vue";
import FeedbacksNotFound from "../../feedback/FeedbacksNotFound.vue";
import ChargemapFeedbackContainer from "../../global/ChargemapFeedbackContainer.vue";
import ChargemapSkeletonLoader from "../../global/ChargemapSkeletonLoader.vue";
export default {
  name: "FeedbackTab",
  directives: {
    ClickOutside
  },
  components: {
    ChargemapFeedbackContainer,
    ChargemapInput,
    ChargemapButton,
    FeedbacksNotFound,
    IconBolt,
    IconSpeaker,
    IconChatAlt,
    ChargemapSkeletonLoader,
    IconClock,
    ChargemapFilterButton
  },
  props: {
    feedbacks: {
      type: Array,
      required: true
    },
    loadingFeedback: {
      type: Boolean,
      default: false
    },
    loadingMoreFeedbacks: {
      type: Boolean,
      default: false
    },
    pool: {
      type: Object,
      default: void 0
    },
    maxFeedbacks: {
      type: Boolean,
      default: false
    },
    isPartner: {
      type: Boolean,
      default: false
    },
    ratingRange: {
      type: Object,
      default: void 0
    },
    activeAdditionalFilters: {
      type: Array,
      default: () => []
    }
  },
  emits: [
    "openFeedbackModal",
    "updateFeedbackFilters",
    "loadMoreFeedbacks",
    "openedFilters",
    "toggleFilter",
    "removeRange",
    "clearFilters"
  ],
  data() {
    return {
      filters: {
        keyword: "",
        moodFilter: [],
        feedbackTypeFilter: [],
        range: this.ratingRange
      },
      FilterFeedbackType,
      requestTimeout: null,
      isDisabled: false
    };
  },
  computed: {
    showRatingFilter() {
      return !!this.filters.range;
    },
    activeFiltersCount() {
      let count = this.activeAdditionalFilters.length;
      if (this.ratingRange) {
        count += 1;
      }
      return count;
    },
    ratingFilterBadgeText() {
      return `${this.$t(`filters.feedbacks.rating`)} ${this.filters.range?.min} - ${this.filters.range?.max}`;
    },
    hasFeedbacks() {
      return !!this.feedbacks.length;
    },
    getChargesCount() {
      return this.pool?.statistic?.checkinsCount || 0;
    },
    getReportsCount() {
      return this.pool?.statistic?.reportsCount || 0;
    },
    getCommentsCount() {
      return this.pool?.statistic?.commentsCount || 0;
    },
    disabledChargesFilter() {
      return this.getChargesCount === 0 || this.loadingFeedback;
    },
    disabledReportsFilter() {
      return this.getReportsCount === 0 || this.loadingFeedback;
    },
    disabledCommentsFilter() {
      return this.getCommentsCount === 0 || this.loadingFeedback;
    }
  },
  watch: {
    activeAdditionalFilters: {
      handler(val) {
        this.filters = {
          ...this.filters,
          feedbackTypeFilter: val
        };
      },
      immediate: true
    },
    ratingRange: {
      handler(val) {
        this.filters = {
          ...this.filters,
          range: val
        };
        clearTimeout(this.requestTimeout);
        this.requestTimeout = setTimeout(() => {
          this.$emit("updateFeedbackFilters", this.filters);
        }, 10);
      },
      deep: true
    },
    "filters.keyword": function() {
      clearTimeout(this.requestTimeout);
      this.requestTimeout = setTimeout(() => {
        this.$emit("updateFeedbackFilters", this.filters);
      }, 300);
    },
    "filters.moodFilter": {
      handler() {
        clearTimeout(this.requestTimeout);
        this.requestTimeout = setTimeout(() => {
          this.$emit("updateFeedbackFilters", this.filters);
        }, 10);
      },
      deep: true
    },
    "filters.feedbackTypeFilter": {
      handler() {
        clearTimeout(this.requestTimeout);
        this.requestTimeout = setTimeout(() => {
          this.$emit("updateFeedbackFilters", this.filters);
        }, 10);
      },
      deep: true
    },
    loadingMoreFeedbacks(val) {
      if (val) {
        this.$nextTick(() => {
          ;
          this.$refs.feedbackLoader.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
          });
        });
      }
    }
  },
  mounted() {
    this.$emit("updateFeedbackFilters", this.filters);
  },
  methods: {
    loadMoreFeedbacks() {
      this.$emit("loadMoreFeedbacks");
    },
    openFeedbackModal(feedback) {
      if (feedback) {
        this.$emit("openFeedbackModal", feedback);
      }
    },
    removeRange() {
      this.$emit("removeRange");
    },
    removeActiveFilter(filterToRemove) {
      this.$emit("toggleFilter", filterToRemove);
    },
    isActiveFilter(filter) {
      return this.activeAdditionalFilters.includes(filter);
    },
    switchFilter(filter) {
      this.$emit("toggleFilter", filter);
    },
    updateOpenFilters(value) {
      this.$emit("openedFilters", value);
    },
    clearFilters() {
      this.$emit("clearFilters");
    }
  }
};
</script>
