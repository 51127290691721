<template>
    <svg
        v-if="isSolid"
        viewBox="0 0 20 20"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.9999 19.6C12.546 19.6 14.9878 18.5886 16.7881 16.7882C18.5885 14.9879 19.5999 12.5461 19.5999 9.99999C19.5999 7.45392 18.5885 5.01212 16.7881 3.21177C14.9878 1.41142 12.546 0.399994 9.9999 0.399994C7.45382 0.399994 5.01203 1.41142 3.21168 3.21177C1.41133 5.01212 0.399902 7.45392 0.399902 9.99999C0.399902 12.5461 1.41133 14.9879 3.21168 16.7882C5.01203 18.5886 7.45382 19.6 9.9999 19.6ZM14.4483 8.44839C14.6669 8.22207 14.7878 7.91895 14.7851 7.60431C14.7824 7.28968 14.6562 6.9887 14.4337 6.76621C14.2112 6.54372 13.9102 6.41752 13.5956 6.41478C13.2809 6.41205 12.9778 6.533 12.7515 6.75159L8.7999 10.7032L7.2483 9.15159C7.02198 8.933 6.71886 8.81205 6.40422 8.81479C6.08958 8.81752 5.78861 8.94372 5.56612 9.16621C5.34363 9.3887 5.21743 9.68968 5.21469 10.0043C5.21196 10.3189 5.33291 10.6221 5.5515 10.8484L7.9515 13.2484C8.17654 13.4734 8.48171 13.5997 8.7999 13.5997C9.1181 13.5997 9.42327 13.4734 9.6483 13.2484L14.4483 8.44839Z"
        />
    </svg>
    <svg
        v-else
        viewBox="0 0 20 20"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 11.0506 2.20693 12.0909 2.60896 13.0615C3.011 14.0321 3.60028 14.914 4.34315 15.6569C5.08601 16.3997 5.96793 16.989 6.93853 17.391C7.90914 17.7931 8.94942 18 10 18C11.0506 18 12.0909 17.7931 13.0615 17.391C14.0321 16.989 14.914 16.3997 15.6569 15.6569C16.3997 14.914 16.989 14.0321 17.391 13.0615C17.7931 12.0909 18 11.0506 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2ZM2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 11.3132 19.7413 12.6136 19.2388 13.8268C18.7362 15.0401 17.9997 16.1425 17.0711 17.0711C16.1425 17.9997 15.0401 18.7362 13.8268 19.2388C12.6136 19.7413 11.3132 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C2.00035 16.1425 1.26375 15.0401 0.761205 13.8268C0.258658 12.6136 0 11.3132 0 10C5.96046e-08 7.34784 1.05357 4.8043 2.92893 2.92893ZM13.7071 7.29289C14.0976 7.68342 14.0976 8.31658 13.7071 8.70711L9.70711 12.7071C9.31658 13.0976 8.68342 13.0976 8.29289 12.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L9 10.5858L12.2929 7.29289C12.6834 6.90237 13.3166 6.90237 13.7071 7.29289Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconCheckCircle",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
