<template>
    <div
        class="flex flex-col border-b border-slate-200 text-slate-800"
        data-testid="chargemap-accordion"
    >
        <div
            ref="accordionHeader"
            class="flex justify-between bg-white cursor-pointer p-6"
            @click="toggleAccordion"
        >
            <div class="flex-col flex">
                <span class="text-lg font-semibold">
                    {{ title }}
                </span>
                <span
                    v-if="subtitle"
                    class="text-slate-500 text-xs font-normal"
                >
                    {{ subtitle }}
                </span>
            </div>
            <IconChevronDown
                class="w-5 h-5 duration-200 transform-gpu my-auto"
                :class="open && '-rotate-180'"
            />
        </div>
        <div
            class="overflow-hidden transition-all duration-300 ease-in-out px-6"
            :style="{
                maxHeight: open ? `${childHeight}px` : 0
            }"
        >
            <slot />
        </div>
    </div>
</template>

<script>
import IconChevronDown from "../../icons/IconChevronDown.vue";
export default {
  name: "ChargemapAccordion",
  components: {
    IconChevronDown
  },
  props: {
    title: {
      type: String,
      required: true
    },
    triggerHeightCalculation: {
      type: Boolean,
      default: false
    },
    defaultOpenState: {
      type: Boolean,
      default: false
    },
    subtitle: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      open: this.defaultOpenState,
      childHeight: 0,
      header: this.$refs.accordionHeader
    };
  },
  watch: {
    triggerHeightCalculation(val) {
      if (val && this.open) {
        this.heightCalculation();
      }
    },
    open(val) {
      if (val) {
        setTimeout(() => {
          this.header?.nextElementSibling?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "start"
          });
        }, 300);
      }
    }
  },
  mounted() {
    this.header = this.$refs.accordionHeader;
    this.heightCalculation();
  },
  methods: {
    heightCalculation() {
      if (!this.header) {
        return;
      }
      const content = this.header.nextElementSibling;
      this.childHeight = content.scrollHeight;
    },
    toggleAccordion() {
      this.heightCalculation();
      this.open = !this.open;
    }
  }
};
</script>
