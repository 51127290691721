<template>
    <div
        class="flex gap-1 items-center text-xs"
        :class="[`text-${generalMood.textColor}`]"
    >
        <IconDot :class="[`text-${generalMood.dotColor}`]" />
        <span :class="[big && 'text-lg font-semibold']">
            {{ generalMood.name }}
        </span>
    </div>
</template>

<script>
import IconDot from "../../icons/IconDot.vue";
export default {
  name: "ChargemapGeneralMood",
  components: { IconDot },
  props: {
    generalMood: {
      type: Object,
      required: true
    },
    big: {
      type: Boolean,
      default: false
    }
  }
};
</script>
