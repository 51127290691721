<template>
    <div
        class="fixed h-full items-center p-6 justify-center inset-0 bg-black z-50 select-none"
        data-testid="chargemap-picture-preview"
        :class="isHiddenClass"
    >
        <div class="h-full w-full relative">
            <div
                v-for="(img, indexImg) in pictures"
                :key="img.id"
                class="w-full h-full absolute top-0 left-0 flex items-center justify-center"
            >
                <!-- we do this so we "preload" 2 images for the user -->
                <img
                    v-if="
                        indexImg === currentIndex - 1 ||
                        indexImg === currentIndex + 1 ||
                        indexImg === currentIndex
                    "
                    v-show="indexImg === currentIndex"
                    :src="getPictureURL(img, 'original')"
                    :alt="img.comment"
                    height="auto"
                    class="object-contain h-full"
                />
            </div>
        </div>

        <div
            class="absolute left-5 text-white cursor-pointer flex items-center justify-center border p-2 border-white rounded-lg"
            type="button"
            @click="changeSlide(TypeSlideCarousel.PREV)"
        >
            <IconChevronLeft class="h-8 w-8" />
        </div>
        <div
            class="absolute right-5 text-white flex items-center justify-center cursor-pointer border p-2 border-white rounded-lg"
            type="button"
            @click="changeSlide(TypeSlideCarousel.NEXT)"
        >
            <IconChevronRight class="h-8 w-8" />
        </div>

        <div
            class="absolute left-5 top-5 cursor-pointer h-6 w-6 text-white flex items-center justify-center"
            @click="closePreview"
        >
            <IconX />
        </div>

        <div
            class="absolute bottom-8 cursor-pointer flex items-center justify-center"
        >
            <ChargemapBadge type="neutral" class="font-medium !px-4 !py-1.5">
                {{ currentImg }} / {{ totalImgs }}
            </ChargemapBadge>
        </div>
    </div>
</template>

<script>
import { TypeSlideCarousel } from "../../../interfaces/UI";
import IconChevronLeft from "../../icons/IconChevronLeft.vue";
import IconChevronRight from "../../icons/IconChevronRight.vue";
import IconX from "../../icons/IconX.vue";
import ChargemapBadge from "./ChargemapBadge.vue";
export default {
  name: "ChargemapPicturePreview",
  components: { IconX, IconChevronLeft, IconChevronRight, ChargemapBadge },
  props: {
    showPreview: {
      type: Boolean,
      default: false
    },
    pictures: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      default: 0
    },
    s3Url: {
      type: String,
      required: true
    }
  },
  emits: ["updateShowPreview"],
  data() {
    return {
      isVisible: false,
      currentIndex: 0,
      TypeSlideCarousel
    };
  },
  computed: {
    isHiddenClass() {
      return this.isVisible ? "flex" : "hidden";
    },
    totalImgs() {
      return this.pictures?.length || 0;
    },
    currentImg() {
      return this.currentIndex + 1;
    }
  },
  watch: {
    showPreview(val) {
      this.isVisible = val;
    },
    index(val) {
      this.currentIndex = val;
    }
  },
  mounted() {
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.key === "Escape") {
        this.closePreview();
      }
      if (evt.key === "ArrowRight") {
        this.changeSlide(TypeSlideCarousel.NEXT);
      }
      if (evt.key === "ArrowLeft") {
        this.changeSlide(TypeSlideCarousel.PREV);
      }
    };
  },
  methods: {
    getPictureURL(picture, size) {
      return `${this.s3Url}/charging_pool_photos/url/${size}/${picture.id}/${picture.url}`;
    },
    closePreview() {
      this.isVisible = false;
      this.$emit("updateShowPreview", false);
    },
    changeSlide(direction) {
      if (direction === TypeSlideCarousel.PREV) {
        this.currentIndex = this.currentIndex === 0 ? this.pictures?.length - 1 : this.currentIndex - 1;
      } else {
        this.currentIndex = this.currentIndex === this.pictures?.length - 1 ? 0 : this.currentIndex + 1;
      }
    }
  }
};
</script>
