<template>
    <div
        class="flex flex-row flex-wrap gap-2 justify-between"
        data-testid="drawer-info-amenities"
    >
        <span
            class="text-secondary-neutral text-center font-medium tracking-wider w-full mx-auto mb-4 uppercase"
        >
            {{ $t('tab_info.amenities.title') }}
        </span>
        <div
            v-for="amenity in amenities"
            :key="amenity"
            class="flex-[1_0_48%] md:flex-[1_0_49%]"
        >
            <div
                class="flex flex-col px-2 py-3.5 bg-white rounded-lg border text-primary-neutral border-zinc-200 justify-center items-center gap-2"
            >
                <component
                    :is="getAmenityIcon(amenity)"
                    class="w-10 h-10"
                    is-solid
                />
                <span>
                    {{ $t('amenities.' + amenity) }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import IconBabyChanging from "../../../../icons/IconBabyChanging.vue";
import IconDrinks from "../../../../icons/IconDrinks.vue";
import IconLodging from "../../../../icons/IconLodging.vue";
import IconPark from "../../../../icons/IconPark.vue";
import IconRestaurant from "../../../../icons/IconRestaurant.vue";
import IconRestroom from "../../../../icons/IconRestroom.vue";
import IconShop from "../../../../icons/IconShop.vue";
import IconTouristAttraction from "../../../../icons/IconTouristAttraction.vue";
import IconWifi from "../../../../icons/IconWifi.vue";
export default {
  name: "DrawerInfoAmenities",
  components: {
    IconPark,
    IconRestaurant,
    IconWifi,
    IconLodging,
    IconDrinks,
    IconShop,
    IconRestroom,
    IconTouristAttraction,
    IconBabyChanging
  },
  props: {
    amenities: {
      type: Array,
      required: true
    }
  },
  methods: {
    getAmenityIcon(amenity) {
      switch (amenity.toLowerCase()) {
        case "park":
          return "IconPark";
        case "restoration":
          return "IconRestaurant";
        case "internet_access":
          return "IconWifi";
        case "lodging":
          return "IconLodging";
        case "drinks":
          return "IconDrinks";
        case "shop":
          return "IconShop";
        case "restroom":
          return "IconRestroom";
        case "tourist_attraction":
          return "IconTouristAttraction";
        case "baby_area":
          return "IconBabyChanging";
        default:
          return "";
      }
    }
  }
};
</script>
