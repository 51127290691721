<template>
    <div class="flex flex-row text-globalSecondary items-center gap-1">
        <slot name="icon" />
        <span class="text-xs">
            <slot />
        </span>
    </div>
</template>
<script>
export default {
  name: "ChargemapCaption"
};
</script>
