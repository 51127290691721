<template>
    <svg
        v-if="isSolid"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.75149 17.6484C8.52653 17.4234 8.40015 17.1182 8.40015 16.8C8.40015 16.4818 8.52653 16.1767 8.75149 15.9516L12.7031 12L8.75149 8.04842C8.5329 7.8221 8.41195 7.51898 8.41468 7.20434C8.41742 6.88971 8.54362 6.58873 8.76611 6.36624C8.9886 6.14375 9.28957 6.01755 9.60421 6.01482C9.91885 6.01208 10.222 6.13304 10.4483 6.35163L15.2483 11.1516C15.4733 11.3767 15.5996 11.6818 15.5996 12C15.5996 12.3182 15.4733 12.6234 15.2483 12.8484L10.4483 17.6484C10.2233 17.8734 9.91809 17.9998 9.59989 17.9998C9.28169 17.9998 8.97652 17.8734 8.75149 17.6484Z"
        />
    </svg>

    <svg
        v-else
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.29289 4.29289C8.68342 3.90237 9.31658 3.90237 9.70711 4.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L9.70711 19.7071C9.31658 20.0976 8.68342 20.0976 8.29289 19.7071C7.90237 19.3166 7.90237 18.6834 8.29289 18.2929L14.5858 12L8.29289 5.70711C7.90237 5.31658 7.90237 4.68342 8.29289 4.29289Z"
        />
    </svg>
</template>
<script>
export default {
  name: "IconChevronRight",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
