<template>
    <svg
        v-if="isSolid"
        viewBox="0 0 18 12"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.0484 0.351588C17.2734 0.576621 17.3997 0.881791 17.3997 1.19999C17.3997 1.51818 17.2734 1.82335 17.0484 2.04839L7.4484 11.6484C7.22337 11.8734 6.9182 11.9997 6.6 11.9997C6.2818 11.9997 5.97663 11.8734 5.7516 11.6484L0.9516 6.84839C0.733011 6.62206 0.612057 6.31894 0.614791 6.00431C0.617526 5.68967 0.743728 5.3887 0.966218 5.16621C1.18871 4.94372 1.48968 4.81751 1.80432 4.81478C2.11895 4.81205 2.42208 4.933 2.6484 5.15159L6.6 9.10319L15.3516 0.351588C15.5766 0.126623 15.8818 0.000244141 16.2 0.000244141C16.5182 0.000244141 16.8234 0.126623 17.0484 0.351588Z"
        />
    </svg>
    <svg
        v-else
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current"
        viewBox="0 0 32 32"
    >
        <path
            d="M27.609 7.057c0.521 0.521 0.521 1.365 0 1.886l-14.667 14.667c-0.521 0.521-1.365 0.521-1.886 0l-6.667-6.667c-0.521-0.521-0.521-1.365 0-1.886s1.365-0.521 1.886 0l5.724 5.724 13.724-13.724c0.521-0.521 1.365-0.521 1.886 0z"
        ></path>
    </svg>
</template>

<script>
export default {
  name: "IconCheck",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
