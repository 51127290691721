<template>
    <div
        id="moderation-line"
        class="cm-table-row"
        data-testid="moderation-table-line"
    >
        <div class="cm-table-cell">
            {{ moderation.id }}
        </div>
        <div class="cm-table-cell">
            {{ moderation.city }}
        </div>
        <div
            class="cm-table-cell text-blue-500 cm-table-cell--bordered-max-lg cursor-pointer"
            @click="redirectToURL"
        >
            {{ moderation.name }}
        </div>
        <div class="cm-table-cell">
            <div class="flex flex-col">
                <span class="text-globalDark">{{ date }}</span>
                <span class="text-xs text-globalSecondary">{{ hour }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
    props: {
        moderation: {
            type: Object,
            required: true
        }
    },
    computed: {
        date() {
            const date = DateTime.fromISO(this.moderation.first_feedback_date)
            return date.toFormat('yyyy-LL-dd')
        },
        hour() {
            const date = DateTime.fromISO(this.moderation.first_feedback_date)
            return date.toFormat('HH:mm:ss')
        }
    },
    methods: {
        redirectToURL() {
            this.$emit('redirectToURL', this.moderation.id)
        }
    }
}
</script>
