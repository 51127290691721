<template>
    <svg
        v-if="isSolid"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current"
        viewBox="0 0 24 24"
    >
        <path
            d="M9.63419 3.70161C9.63419 4.91753 8.64849 5.90323 7.43258 5.90323C6.21666 5.90323 5.23096 4.91753 5.23096 3.70161C5.23096 2.4857 6.21666 1.5 7.43258 1.5C8.64849 1.5 9.63419 2.4857 9.63419 3.70161Z"
        />
        <path
            d="M4.64479 7.77674C4.7889 7.0802 5.40239 6.58065 6.11368 6.58065H8.75148C9.46277 6.58065 10.0763 7.0802 10.2204 7.77674L11.6319 14.5993C11.8245 15.5302 11.1137 16.4032 10.163 16.4032H9.94272V21.3145C9.94272 21.9692 9.41196 22.5 8.75723 22.5C8.10251 22.5 7.57175 21.9692 7.57175 21.3145V16.4032H7.23304V21.3145C7.23304 21.9692 6.70228 22.5 6.04755 22.5C5.39283 22.5 4.86207 21.9692 4.86207 21.3145V16.4032H4.70211C3.75148 16.4032 3.04062 15.5302 3.23322 14.5993L4.64479 7.77674Z"
        />
        <path
            d="M18.7793 3.70161C18.7793 4.91753 17.7936 5.90323 16.5777 5.90323C15.3618 5.90323 14.3761 4.91753 14.3761 3.70161C14.3761 2.4857 15.3618 1.5 16.5777 1.5C17.7936 1.5 18.7793 2.4857 18.7793 3.70161Z"
        />
        <path
            d="M13.2058 7.94484C13.276 7.17222 13.9238 6.58065 14.6996 6.58065H18.3955C19.1713 6.58065 19.8191 7.17222 19.8893 7.94484L20.294 12.3965C20.3649 13.1767 19.8271 13.8615 19.0879 14.0048V21.3145C19.0879 21.9692 18.5571 22.5 17.9024 22.5C17.2477 22.5 16.7169 21.9692 16.7169 21.3145V14.0323H16.3782V21.3145C16.3782 21.9692 15.8474 22.5 15.1927 22.5C14.538 22.5 14.0072 21.9692 14.0072 21.3145V14.0048C13.268 13.8615 12.7302 13.1767 12.8011 12.3965L13.2058 7.94484Z"
        />
    </svg>
    <svg
        v-else
        fill="none"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.70172 14.6129H9.80907L8.50129 8.32258H6.40677L5.09899 14.6129H7.1428V21H7.70172V14.6129ZM14.3752 14.886V21.7581C14.3752 22.444 14.934 23 15.6232 23H18.4757C19.1649 23 19.7237 22.444 19.7237 21.7581V14.886C20.5019 14.7359 21.0681 14.0185 20.9934 13.201L20.5674 7.75174C20.4934 6.94233 19.8115 6.32258 18.9948 6.32258H15.1041C14.2874 6.32258 13.6055 6.94233 13.5315 7.75174L13.1055 13.201C13.0643 13.6529 13.2188 14.0741 13.4966 14.3859C13.6097 14.5128 13.7433 14.6216 13.8924 14.7069C14.0397 14.7912 14.2022 14.8526 14.3752 14.886ZM10.3888 7.57563C10.2371 6.84592 9.59123 6.32258 8.84245 6.32258H6.06561C5.31683 6.32258 4.671 6.84592 4.51929 7.57563L3.03332 14.7231C2.83056 15.6983 3.5789 16.6129 4.57964 16.6129H5.1428V21.7581C5.1428 22.444 5.70153 23 6.39077 23H8.45374C9.14298 23 9.70172 22.444 9.70172 21.7581V16.6129H10.3284C11.0474 16.6129 11.6361 16.1408 11.8354 15.5133C11.9135 15.2674 11.9318 14.9977 11.8747 14.7231L10.3888 7.57563ZM15.493 8.32258L15.1278 12.9943L16.3752 13.2349V21H17.7237V13.2349L18.9711 12.9943L18.6059 8.32258H15.493Z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17 4C17.2761 4 17.5 3.77614 17.5 3.5C17.5 3.22386 17.2761 3 17 3C16.7239 3 16.5 3.22386 16.5 3.5C16.5 3.77614 16.7239 4 17 4ZM17 6C18.3807 6 19.5 4.88071 19.5 3.5C19.5 2.11929 18.3807 1 17 1C15.6193 1 14.5 2.11929 14.5 3.5C14.5 4.88071 15.6193 6 17 6Z"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.5 4C7.77614 4 8 3.77614 8 3.5C8 3.22386 7.77614 3 7.5 3C7.22386 3 7 3.22386 7 3.5C7 3.77614 7.22386 4 7.5 4ZM7.5 6C8.88071 6 10 4.88071 10 3.5C10 2.11929 8.88071 1 7.5 1C6.11929 1 5 2.11929 5 3.5C5 4.88071 6.11929 6 7.5 6Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconRestroom",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
