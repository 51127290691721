<template>
    <svg
        v-if="isSolid"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6 12C6 11.6817 6.12643 11.3765 6.35147 11.1515C6.57652 10.9264 6.88174 10.8 7.2 10.8H16.8C17.1183 10.8 17.4235 10.9264 17.6485 11.1515C17.8736 11.3765 18 11.6817 18 12C18 12.3182 17.8736 12.6235 17.6485 12.8485C17.4235 13.0736 17.1183 13.2 16.8 13.2H7.2C6.88174 13.2 6.57652 13.0736 6.35147 12.8485C6.12643 12.6235 6 12.3182 6 12Z"
        />
    </svg>

    <svg
        v-else
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H6C5.44772 13 5 12.5523 5 12Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconMinus",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
