<template>
    <div class="moderation-table" data-testid="moderation-table">
        <div class="cm-table-container">
            <div class="cm-table table-fixed">
                <div class="cm-table-header-group">
                    <div scope="col" class="cm-table-header-cell w-1/6">
                        Id Zone
                    </div>
                    <div scope="col" class="cm-table-header-cell w-1/6">
                        Commune
                    </div>
                    <div scope="col" class="cm-table-header-cell w-2/5">
                        Nom de la zone
                    </div>
                    <div
                        scope="col"
                        class="cm-table-header-cell-flex w-auto"
                        @click="toggleOrder"
                    >
                        Date de contribution
                        <IconOrder :dir="order" />
                    </div>
                </div>
                <tr v-if="loading">
                    <td colspan="4">
                        <div
                            class="flex flex-col items-center p-4 justify-center"
                        >
                            <Loader
                                class="w-24 h-24"
                                color="slate"
                                loader-size="medium"
                                :no-shadow="true"
                            />
                        </div>
                    </td>
                </tr>
                <transition v-else name="fade">
                    <div class="cm-table-row-group">
                        <ModerationTableLine
                            v-for="moderation in items"
                            :key="moderation.id"
                            :moderation="moderation"
                            @redirectToURL="redirectToURL"
                        />
                    </div>
                </transition>
            </div>
            <Pagination
                v-model="filters.pages.index"
                :per-page="filters.pages.perPage"
                :total="filters.pages.totalItems"
                :pagination-texts="paginationTexts"
                :previous-text="previousText"
                :next-text="nextText"
            />
        </div>
    </div>
</template>

<script>
import ModerationTableLine from './ModerationTableLine.vue'
import Pagination from './Pagination.vue'
import IconOrder from '../../icons/IconOrder.vue'
import Loader from './Loader.vue'

export default {
    components: {
        ModerationTableLine,
        Pagination,
        IconOrder,
        Loader
    },
    props: {
        filters: {
            type: Object,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        urlToRedirect: {
            type: String,
            required: true
        },
        paginationTexts: {
            type: String,
            required: true
        },
        previousText: {
            type: String,
            required: true
        },
        nextText: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        order: {
            type: String,
            default: 'DESC'
        }
    },
    emits: ['toggleOrder'],
    methods: {
        toggleOrder() {
            this.$emit('toggleOrder')
        },
        redirectToURL(id) {
            window.location.href = `${this.urlToRedirect}/${id}.html`
        }
    }
}
</script>

<style>
.cm-table {
  height: 1px;
  @apply flex lg:table border-collapse w-full lg:divide-y divide-gray-200 text-globalDark;
}
.cm-table-container {
  border-color: #dee2e6;
  @apply relative pb-4 lg:p-0 lg:shadow rounded-lg divide-y divide-gray-200 bg-white;
}
.cm-table-header-group {
  @apply table-row hidden lg:table-header-group h-[3.75rem] leading-3 text-xs font-medium text-gray-600 uppercase text-left tracking-wider;
}
.cm-table-header-cell {
  color: #868e96;
  @apply table-cell text-thirteen-exploitation p-3 truncate font-normal;
}
.cm-table-header-cell-flex {
  color: #868e96;
  @apply flex items-center gap-4 text-thirteen-exploitation p-3 truncate font-normal cursor-pointer;
}
.cm-table-row {
  @apply flex flex-col text-sm mb-4 h-[3.75rem] lg:mb-0 rounded-lg lg:rounded-none lg:table-row bg-white border-gray-200 align-middle;
}
.cm-table-row:hover {
  @apply bg-blue-50;
}
.cm-table-row-group {
  @apply block w-full lg:table-row-group bg-transparent divide-y divide-gray-200;
}
.cm-table-cell {
  @apply flex items-center lg:table-cell px-4 lg:px-3 py-4 align-middle;
}
.cm-table-cell--title {
  @apply flex-col items-start justify-center pb-1 lg:pb-4;
}
.cm-table-cell--title span {
  @apply text-base font-bold lg:text-sm lg:font-normal;
}
.cm-table-cell--subtitle {
  @apply text-xs text-gray-400 pt-0 lg:pt-4 lg:text-sm lg:text-current;
}
.cm-table-cell--bordered-max-lg {
  @apply border-t border-gray-200 lg:border-none;
}
.cm-table-cell--hidden-max-lg {
  @apply hidden lg:table-cell;
}
.cm-table-cell--hidden-min-lg {
  @apply lg:hidden table-cell;
}
.cm-table-cell.colspan {
  max-width: 1px;
  overflow: visible;
  white-space: nowrap;
}
</style>
