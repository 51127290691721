export var FeedbackType = /* @__PURE__ */ ((FeedbackType2) => {
  FeedbackType2["CHECKIN"] = "checkin";
  FeedbackType2["COMMENT"] = "comment";
  FeedbackType2["REPORT"] = "report";
  return FeedbackType2;
})(FeedbackType || {});
export var FilterFeedbackType = /* @__PURE__ */ ((FilterFeedbackType2) => {
  FilterFeedbackType2["CHECKIN"] = "CHECKIN";
  FilterFeedbackType2["COMMENT"] = "COMMENT";
  FilterFeedbackType2["REPORT"] = "REPORT";
  FilterFeedbackType2["ARCHIVED"] = "ARCHIVED";
  FilterFeedbackType2["UNREAD"] = "UNREAD";
  FilterFeedbackType2["RATED"] = "RATED";
  FilterFeedbackType2["ANSWERED"] = "ANSWERED";
  return FilterFeedbackType2;
})(FilterFeedbackType || {});
export var ReasonType = /* @__PURE__ */ ((ReasonType2) => {
  ReasonType2["DELIVERED_POWER_TOO_WEAK"] = "DELIVERED_POWER_TOO_WEAK";
  ReasonType2["CONNEXION_OR_AUTHENTICATION_IMPOSSIBLE"] = "CONNEXION_OR_AUTHENTICATION_IMPOSSIBLE";
  ReasonType2["SESSION_NOT_STARTING"] = "SESSION_NOT_STARTING";
  ReasonType2["SESSION_INTERRUPTED"] = "SESSION_INTERRUPTED";
  ReasonType2["CONNECTOR_DAMAGED"] = "CONNECTOR_DAMAGED";
  ReasonType2["PLACE_OCCUPIED_BY_NON_CHARGING_VEHICLE"] = "PLACE_OCCUPIED_BY_NON_CHARGING_VEHICLE";
  ReasonType2["ZONE_OCCUPIED_BY_CHARGING_VEHICLE"] = "ZONE_OCCUPIED_BY_CHARGING_VEHICLE";
  ReasonType2["POOL_NOT_PUBLIC"] = "POOL_NOT_PUBLIC";
  ReasonType2["POOL_DEFINITIVELY_CLOSED"] = "POOL_DEFINITIVELY_CLOSED";
  ReasonType2["POOL_TEMPORARILY_INACCESSIBLE"] = "POOL_TEMPORARILY_INACCESSIBLE";
  ReasonType2["CHARGING_IMPOSSIBLE_OUTSIDE_OPENING_HOURS"] = "CHARGING_IMPOSSIBLE_OUTSIDE_OPENING_HOURS";
  ReasonType2["POOL_POWERED_OFF"] = "POOL_POWERED_OFF";
  return ReasonType2;
})(ReasonType || {});
export var PassState = /* @__PURE__ */ ((PassState2) => {
  PassState2["ORDERED"] = "ORDERED";
  PassState2["IN_STOCK"] = "IN_STOCK";
  PassState2["ASSIGNED"] = "ASSIGNED";
  PassState2["ACTIVATED"] = "ACTIVATED";
  PassState2["DELETED"] = "DELETED";
  PassState2["LOST"] = "LOST";
  PassState2["DEACTIVATED"] = "DEACTIVATED";
  return PassState2;
})(PassState || {});
