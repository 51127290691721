<template>
    <DrawerContent
        v-if="standalone"
        :show-close-button="drawCloseButton"
        :pool-connectors="poolConnectorsForDisplay"
        :pool
        :feedbacks
        :pictures
        :s3-url
        :chargemap-url
        :is-partner
        :legacy-api-url
        :loading-info
        :loading-feedbacks
        :loading-pictures
        :message
        :loading-stations
        :show-partner-full-message
        :max-feedbacks
        :loading-more-feedbacks
        :cdn-url
        @closeDrawer="closeDrawer"
        @loadMoreFeedbacks="loadMoreFeedbacks"
        @openPicture="openPicture"
        @reliabilityClicked="reliabilityClicked"
        @messageActionClick="messageActionClick"
        @connectorClicked="connectorClicked"
        @openFeedbackModal="openFeedbackModal"
        @updateFeedbackFilters="updateFeedbackFilters"
        @openTariff="openTariff"
        @findConnectorIdClicked="findConnectorIdClicked"
        @changedTab="changedTab"
    />
    <ChargemapDrawer
        v-else
        data-testid="chargemap-map-drawer"
        :close-trigger="closeDrawerSwitch"
        :can-close
        :visible
        :position
        @update-visible="updateVisible"
        @show-close-button="updateDrawCloseButton"
    >
        <DrawerContent
            :show-close-button="drawCloseButton"
            :pool-connectors="poolConnectorsForDisplay"
            :pool
            :feedbacks="feedbacks"
            :pictures="pictures"
            :s3-url="s3Url"
            :chargemap-url="chargemapUrl"
            :is-partner="isPartner"
            :legacy-api-url="legacyApiUrl"
            :loading-info="loadingInfo"
            :loading-feedbacks="loadingFeedbacks"
            :loading-pictures="loadingPictures"
            :message="message"
            :loading-stations="loadingStations"
            :show-partner-full-message="showPartnerFullMessage"
            :max-feedbacks="maxFeedbacks"
            :loading-more-feedbacks="loadingMoreFeedbacks"
            :cdn-url="cdnUrl"
            @closeDrawer="closeDrawer"
            @loadMoreFeedbacks="loadMoreFeedbacks"
            @openPicture="openPicture"
            @reliabilityClicked="reliabilityClicked"
            @messageActionClick="messageActionClick"
            @connectorClicked="connectorClicked"
            @openFeedbackModal="openFeedbackModal"
            @updateFeedbackFilters="updateFeedbackFilters"
            @openTariff="openTariff"
            @findConnectorIdClicked="findConnectorIdClicked"
            @changedTab="changedTab"
        />
    </ChargemapDrawer>
</template>

<script>
import DrawerContent from "../mapDrawer/DrawerContent.vue";
import ChargemapDrawer from "./ChargemapDrawer.vue";
import usePoolConnectorsForDisplay from "../../../composables/usePoolConnectorsForDisplay";
export default {
  name: "ChargemapMapDrawer",
  components: {
    DrawerContent,
    ChargemapDrawer
  },
  props: {
    standalone: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },
    pool: {
      type: Object,
      default: void 0
    },
    feedbacks: {
      type: Array,
      required: true
    },
    poolStations: {
      type: Array,
      required: true
    },
    pictures: {
      type: Array,
      required: true
    },
    position: {
      type: String,
      default: "left",
      validator: (value) => ["left", "right"].includes(value)
    },
    s3Url: {
      type: String,
      required: true
    },
    isPartner: {
      type: Boolean,
      required: true
    },
    hasCloseButton: {
      type: Boolean,
      default: true
    },
    canClose: {
      type: Boolean,
      default: true
    },
    legacyApiUrl: {
      type: String,
      required: true
    },
    chargemapUrl: {
      type: String,
      required: true
    },
    loadingInfo: {
      type: Boolean,
      default: false
    },
    loadingFeedbacks: {
      type: Boolean,
      default: false
    },
    loadingPictures: {
      type: Boolean,
      default: false
    },
    loadingStations: {
      type: Boolean,
      default: false
    },
    message: {
      type: Object,
      default: void 0
    },
    showPartnerFullMessage: {
      type: Boolean,
      default: false
    },
    maxFeedbacks: {
      type: Boolean,
      default: false
    },
    loadingMoreFeedbacks: {
      type: Boolean,
      default: false
    },
    cdnUrl: {
      type: String,
      default: ""
    }
  },
  emits: [
    "openPicture",
    "reliabilityClicked",
    "messageActionClick",
    "connectorClicked",
    "openFeedbackModal",
    "updateFeedbackFilters",
    "openTariff",
    "findConnectorIdClicked",
    "changedTab",
    "loadMoreFeedbacks",
    "updateVisible"
  ],
  data() {
    return {
      drawCloseButton: false,
      closeDrawerSwitch: false,
      lockScroll: false
    };
  },
  computed: {
    poolConnectorsForDisplay() {
      return usePoolConnectorsForDisplay(this.poolStations).value;
    }
  },
  methods: {
    updateDrawCloseButton(value) {
      this.drawCloseButton = value;
    },
    openPicture(index) {
      this.$emit("openPicture", index);
    },
    reliabilityClicked(payload) {
      this.$emit("reliabilityClicked", payload);
    },
    messageActionClick() {
      this.$emit("messageActionClick");
    },
    connectorClicked(connector) {
      this.$emit("connectorClicked", connector);
    },
    openFeedbackModal(feedback) {
      this.$emit("openFeedbackModal", feedback);
    },
    updateFeedbackFilters(filters) {
      this.$emit("updateFeedbackFilters", filters);
    },
    openTariff() {
      this.$emit("openTariff", this.pool?.id);
    },
    findConnectorIdClicked() {
      this.$emit("findConnectorIdClicked");
    },
    loadMoreFeedbacks() {
      this.$emit("loadMoreFeedbacks");
    },
    changedTab(tabId) {
      this.$emit("changedTab", tabId);
    },
    updateVisible(val) {
      this.$emit("updateVisible", val);
    },
    closeDrawer() {
      this.closeDrawerSwitch = true;
      this.$nextTick(() => {
        this.closeDrawerSwitch = false;
      });
    }
  }
};
</script>

<style scoped>
.mobile-drawer {
  @apply left-0 w-full z-50;
  box-shadow: 0px -4px 8px 0px rgba(19, 47, 68, 0.2), 0px 0px 1px 0px rgba(19, 47, 68, 0.1);
}
.mobile-drawer .drawer-container {
  @apply max-h-full overflow-y-auto;
}

.resize-handle {
  @apply absolute top-0 left-0 w-full h-12 cursor-ns-resize;
}

.resizing {
  user-select: none;
}
</style>
../../../interfaces/drawer;d ../../../interfaces/drawer
