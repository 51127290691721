<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path
            fill="#fbbf24"
            d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16c-8.837 0-16-7.163-16-16s7.163-16 16-16z"
        ></path>
        <path
            fill="#495057"
            d="M16 8c-4.418 0-8 3.582-8 8s3.582 8 8 8c4.418 0 8-3.582 8-8s-3.582-8-8-8zM6.222 16c0-5.4 4.378-9.778 9.778-9.778s9.778 4.378 9.778 9.778c0 5.4-4.378 9.778-9.778 9.778s-9.778-4.378-9.778-9.778z"
        ></path>
        <path
            fill="#495057"
            d="M15.111 19.555c0-0.491 0.398-0.889 0.889-0.889h0.009c0.491 0 0.889 0.398 0.889 0.889s-0.398 0.889-0.889 0.889h-0.009c-0.491 0-0.889-0.398-0.889-0.889z"
        ></path>
        <path
            fill="#495057"
            d="M16 11.556c0.491 0 0.889 0.398 0.889 0.889v3.556c0 0.491-0.398 0.889-0.889 0.889s-0.889-0.398-0.889-0.889v-3.556c0-0.491 0.398-0.889 0.889-0.889z"
        ></path>
    </svg>
</template>

<script>
export default {
  name: "IconPassNotActivated"
};
</script>
