<template>
    <svg
        v-if="isSolid"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 21.6C14.5461 21.6 16.9879 20.5886 18.7882 18.7882C20.5886 16.9879 21.6 14.5461 21.6 12C21.6 9.45392 20.5886 7.01212 18.7882 5.21177C16.9879 3.41142 14.5461 2.39999 12 2.39999C9.45395 2.39999 7.01215 3.41142 5.2118 5.21177C3.41145 7.01212 2.40002 9.45392 2.40002 12C2.40002 14.5461 3.41145 16.9879 5.2118 18.7882C7.01215 20.5886 9.45395 21.6 12 21.6ZM11.466 8.60159C11.2853 8.48102 11.0752 8.41177 10.8582 8.40123C10.6412 8.39069 10.4255 8.43925 10.2339 8.54175C10.0424 8.64424 9.88221 8.79681 9.77058 8.98318C9.65894 9.16956 9.59999 9.38274 9.60002 9.59999V14.4C9.59999 14.6172 9.65894 14.8304 9.77058 15.0168C9.88221 15.2032 10.0424 15.3558 10.2339 15.4582C10.4255 15.5607 10.6412 15.6093 10.8582 15.5988C11.0752 15.5882 11.2853 15.519 11.466 15.3984L15.066 12.9984C15.2304 12.8888 15.3651 12.7403 15.4583 12.5662C15.5515 12.392 15.6003 12.1975 15.6003 12C15.6003 11.8025 15.5515 11.608 15.4583 11.4338C15.3651 11.2596 15.2304 11.1112 15.066 11.0016L11.466 8.60159Z"
        />
    </svg>
    <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="fill-current"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM9 9.86852C9 8.27113 10.7803 7.31834 12.1094 8.20442L15.3066 10.3359C16.4941 11.1275 16.4941 12.8725 15.3066 13.6641L12.1094 15.7956C10.7803 16.6817 9 15.7289 9 14.1315V9.86852ZM14.1972 12L11 9.86852V14.1315L14.1972 12Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconPlay",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
